import React from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { http, ErrorHandle, isEmpty, truncateText, stripHtml } from '../../Utils'
import { Card as Cards, Col, Row, Container } from 'react-bootstrap'
import { Card } from 'primereact/card'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Paginator } from 'primereact/paginator'

const TextNowrap = {
    flexDirection: 'row',
    whiteSpace: 'pre-line',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '15px',
    height: '2.5rem',
    width: '100%',
    minWidth: '1px',
}

const TitleTextNowrap = {
    flexDirection: 'row',
    whiteSpace: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '16px',
    height: '35px',
    width: '100%',
    minWidth: '1px',
}

const CourseStudyReview = () => {

    const { register, handleSubmit, reset, watch } = useForm()

    const [isLoad, setIsLoad] = React.useState(false)
    const [Limit, setLimit] = React.useState(20)
    const [Offset, setOffset] = React.useState(1)
    const [TotalRecords, setTotalRecords] = React.useState(0)
    const [DataUserCourseStudyReview, setDataUserCourseStudyReview] = React.useState([])

    React.useEffect(() => {

        (async () => await GetData())()

    }, [])

    const GetData = async () => {
        try {
            setIsLoad(true)
            const _res = await http.post('/UserCourseStudy/UserCourseStudyNotification', { ActionStatus: 2, Search: '', Offset: 0, Limit: Limit }, { headers: { "Content-Type": "multipart/form-data" } })
            if (_res.status === 200 && _res.data.status) {
                setDataUserCourseStudyReview(_res.data.data)
                setTotalRecords(_res.data.total)
                setIsLoad(false)
            }

        } catch (err) {
            setIsLoad(false)
            ErrorHandle(err)
        }
    }

    const onSearch = async (data) => {

        try {

            setIsLoad(true)
            if (isEmpty(data.Search)) {
                await GetData()
                return
            }

            const _res = await http.post('/UserCourseStudy/UserCourseStudyNotification', { ActionStatus: 2, Search: data.Search, Offset: 0, Limit: Limit }, { headers: { "Content-Type": "multipart/form-data" } })
            if (_res.status === 200 && _res.data.status) {
                setDataUserCourseStudyReview(_res.data.data)
                setTotalRecords(_res.data.total)
                setIsLoad(false)
            }

        } catch (err) {
            reset()
            ErrorHandle(err)
        }
    }

    const LoadMore = async (offset = 20) => {

        try {
            setIsLoad(true)
            const _res = await http.post('/UserCourseStudy/UserCourseStudyNotification', { ActionStatus: 2, Search: watch('Search'), Offset: offset, Limit: Limit }, { headers: { "Content-Type": "multipart/form-data" } })
            if (_res.status === 200 && _res.data.status) {
                setDataUserCourseStudyReview(_res.data.data)
                setLimit(Limit)
                setTotalRecords(_res.data.total)
                setOffset(offset)
                setIsLoad(false)
            }

        } catch (err) {
            setIsLoad(false)
            ErrorHandle(err)
        }
    }

    return (
        <React.Fragment>
            {/* <Card className="mt-3 m-2 shadow-5 fadein" title={<h3>คอร์สที่เรียนสำเร็จ</h3>}>
                <form onSubmit={handleSubmit(onSearch)}>
                    <div className="flex flex-wrap justify-content-end gap-1">
                        <div className="p-inputgroup col-2 md:col-2">
                            <InputText
                                className="p-inputtext-sm p-d-block p-mr-2"
                                placeholder="ค้นหา"
                                defaultValue={watch('Search')}
                                {...register('Search', {
                                    onChange: (e) => {
                                        if (isEmpty(e.target.value)) {
                                            reset()
                                            GetData()
                                            return
                                        }
                                    }
                                })}
                            />
                            <Button
                                type="submit"
                                icon="pi pi-search"
                                className="p-button-success"
                                loading={isLoad}
                                title="ค้นหา"
                            />
                        </div>
                    </div>
                </form>
                <div className="flex md:justify-content-start justify-content-center flex-wrap">
                    {
                        DataUserCourseStudyReview.length > 0 ? DataUserCourseStudyReview.flatMap((_Item) => {

                            const Header = (
                                <div className="p-d-flex p-jc-between">
                                    <Link to={`/Learn/${_Item.courseStudyID}`} title={_Item.title}>
                                        <Cards.Img variant="top" src={_Item.checkFile && !isEmpty(_Item.fileUpload) ? _Item.fileUpload.fileurl : window.location.origin + '/showcase/SIS_Admin.jpg'} />
                                    </Link>
                                </div>
                            )

                            const Footer = (
                                <div className="flex flex-wrap justify-content-end gap-2">
                                    <span className="text-nowraps text-mb">คอร์สนี้เหมาะสำหรับ {!isEmpty(_Item.advice) ? truncateText(stripHtml(_Item.advice).replace(/\s/g, ' '), 25) : <br />}</span>
                                </div>
                            )

                            return (
                                <div className="flex align-items-center justify-content-center m-2" key={_Item.userCourseStudyID}>
                                    <div className="p-0">
                                        <Card
                                            // title={<h3 className="font-bold text-lg m-0 text-nowrap2">{truncateText(_Item.title, 60)}</h3>}
                                            title={<h3 className="font-bold m-0" style={TitleTextNowrap}>20230417_BNS test20230417_BNS test20230417_BNS test20230417_BNS test20230417_BNS test20230417_BNS test20230417_BNS test20230417_BNS test20230417_BNS test</h3>}
                                            header={Header}
                                            footer={Footer}
                                            className="md:w-18rem shadow-5 fadein mt-1"
                                        >
                                            <div className="flex flex-wrap justify-content-between gap-1 max-h-2rem">
                                                <p className="m-0 text-nowraps" style={TextNowrap} dangerouslySetInnerHTML={{ __html: _Item.detail }} />
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            )
                        })
                            :
                            <div className="flex align-items-center justify-content-center m-2">
                                <div className="p-0">
                                    <h5 className="m-0 text-center text-red-500">ไม่พบข้อมูล</h5>
                                </div>
                            </div>
                    }
                </div>
                <div className="flex align-items-center justify-content-center m-2">
                    <div className="p-0">
                        <Paginator first={Offset} rows={Limit} totalRecords={TotalRecords} onPageChange={(f) => {
                            setOffset(f.first)
                            setLimit(f.rows)
                            LoadMore(f.first)
                        }} template={{ layout: 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink' }} />
                    </div>
                </div>
            </Card> */}
            <Container>
                {/* <Row className="mt-2">
                    <form onSubmit={handleSubmit(onSearch)}>
                        <div className="d-flex justify-content-between">
                            <h3 className="mt-3">คอร์สที่เรียนสำเร็จ</h3>
                            <div className="p-inputgroup col-3 md:col-3 mt-2" style={{ height: '55px' }}>
                                <InputText
                                    className="p-inputtext-sm p-d-block p-mr-2 w-18rem"
                                    defaultValue={watch('Search')}
                                    {...register('Search', {
                                        onChange: (e) => {
                                            if (isEmpty(e.target.value)) {
                                                reset()
                                                GetData()
                                                return
                                            }
                                        }
                                    })}
                                />
                                <Button
                                    type="submit"
                                    icon="pi pi-search"
                                    className="p-button-success"
                                    loading={isLoad}
                                    title="ค้นหา"
                                />
                            </div>
                        </div>
                    </form>
                </Row> */}
                <Row className="mt-2">
                    <Col md={9} className="float-left">
                        <h3 className="mt-3">คอร์สที่เรียนสำเร็จ</h3>
                    </Col>
                    <Col md={3} className="float-right">
                        <form onSubmit={handleSubmit(onSearch)}>
                            <div className="p-inputgroup col-11 md:col-11 mt-2" style={{ height: '55px' }}>
                                <InputText
                                    className="p-inputtext-sm p-d-block p-mr-2 w-18rem"
                                    defaultValue={watch('Search')}
                                    {...register('Search', {
                                        onChange: (e) => {
                                            if (isEmpty(e.target.value)) {
                                                reset()
                                                GetData()
                                                return
                                            }
                                        }
                                    })}
                                />
                                <Button
                                    type="submit"
                                    icon="pi pi-search"
                                    className="p-button-success"
                                    loading={isLoad}
                                    title="ค้นหา"
                                />
                            </div>
                        </form>
                    </Col>
                </Row>
                <Row className="mt-2">
                    {
                        DataUserCourseStudyReview.length > 0 && DataUserCourseStudyReview.flatMap((item, index) => {

                            const _Title = !isEmpty(item.title) ? stripHtml(item.title) : item.title
                            const _Detail = !isEmpty(item.detail) ? stripHtml(item.detail).replace(/\s/g, '') : item.detail
                            const _Advice = !isEmpty(item.advice) ? stripHtml(item.advice).replace(/\s/g, '') : item.advice

                            return (
                                <Col md={3} key={item.courseStudyID} className="m-0 mb-3 p-1">
                                    <div className="card">
                                        <div className="card-head">
                                            <Link to={`/Learn/${item.courseStudyID}/2`} className="p-button-info">
                                                {/* <Cards.Img variant="top" src={item.checkFile && !isEmpty(item.fileUpload) ? item.fileUpload.fileurl : window.location.origin + '/showcase/600x400SF.jpg'} /> */}
                                                <Cards.Img variant="top" src={!isEmpty(item.fileUpload) ? item.fileUpload.fileurl : window.location.origin + '/showcase/600x400SF.jpg'} />
                                            </Link>
                                        </div>
                                        <div className="card-body" style={{ height: '10rem', width: '100%', float: 'left' }}>
                                            <Cards.Title as="h5" className="font-bold" style={TitleTextNowrap}>{!isEmpty(_Title) ? _Title : ''}</Cards.Title>
                                            <p className="mt-3" style={TextNowrap} dangerouslySetInnerHTML={{ __html: !isEmpty(item.detail) ? item.detail : 'ไม่พบเนื้อหา' }}></p>
                                        </div>
                                        <div className="p-1 m-0" style={{ height: '2rem', width: '100%', float: 'left' }}>
                                            <p className="text-nowraps ml-2" style={{ height: '2.5rem', fontSize: '14px' }}>คอร์สนี้เหมาะสำหรับ {!isEmpty(_Advice) ? truncateText(stripHtml(_Advice).replace(/\s/g, ' '), 25) : <br />}</p>
                                            {isEmpty(_Advice) && <small className="text-muted"><br /></small>}
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
                <div className="flex align-items-center justify-content-center m-2">
                    <div className="p-0">
                        <Paginator first={Offset} rows={Limit} totalRecords={TotalRecords} onPageChange={(f) => {
                            setOffset(f.first)
                            setLimit(f.rows)
                            LoadMore(f.first)
                        }} template={{ layout: 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink' }} />
                    </div>
                </div>
            </Container>
        </React.Fragment>
    )
}

export default React.memo(CourseStudyReview)