import React from 'react'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { TabView, TabPanel } from 'primereact/tabview'
import { Image } from 'primereact/image'
import { v4 as uuidv4 } from 'uuid'
import { Card } from 'primereact/card'
import { Badge } from 'primereact/badge'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Swal from 'sweetalert2'
import { http, isEmpty, DateThai, truncateText, ErrorHandle, stripHtml } from '../../Utils'
import { Container } from 'react-bootstrap'
import { useNavigate } from "react-router-dom"

// import AddQuizQuestion from '../QuizQuestion/AddQuizQuestion/_index'
import SearhFilter from '../../Components/DataTable/SearhFilter'
import DataTables from '../../Components/DataTable'
import Loading from '../../Components/Loading'
import Preview from '../../Components/Previews'

import '../../index.css'

const TextNowrap = {
    flexDirection: 'row',
    whiteSpace: 'pre-line',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '15px',
    height: '2.5rem',
    width: '100%',
    minWidth: '1px',
}

const UserCourseStudyHistory = (props) => {

    const RedirectTo = useNavigate()

    const toast = React.useRef(null)
    const _MenuID = 1

    const [globalFilterValue, setGlobalFilterValue] = React.useState('')
    const [Search, setSearch] = React.useState('')
    const [IsLoading, setIsLoading] = React.useState(false)
    const [Page, setPage] = React.useState(1)
    const [Per_Page, setPerPage] = React.useState(10)
    const [TotalRecords, setTotalRecords] = React.useState(0)
    const [Data, setData] = React.useState([])
    const [ItemList, setItemList] = React.useState([])
    const [IsLoadingPage, setIsLoadingPage] = React.useState(false)
    
    React.useEffect(() => {

        (async () => await getData())()

    }, [props.title])

    const getData = async (page = 1, search = '') => {
        try {

            setIsLoading(true)
            const _res = await http.post(`/UserCourseStudy?page=${page}&per_page=${Per_Page}${!isEmpty(search) ? `&search=${search}` : ''}`)
            if (_res.status === 200) {
                setTotalRecords(_res.data.total)
                setData(_res.data.data)
                setIsLoading(false)
            }

        } catch (err) { ErrorHandle(err) }
    }

    const _DataTable = React.useMemo(() => Data, [Data])

    const handlePageChange = async (page) => {
        setPage(page)
        await getData(page)
    }

    const handlePerRowsChange = async (newPage, page) => {
        try {

            setIsLoading(true)
            const _res = await http.post(`/UserCourseStudy?page=${page}&per_page=${newPage}${!isEmpty(Search) ? `&search=${Search}` : ''}`)
            if (_res.status === 200) {
                setTotalRecords(_res.data.total)
                setData(_res.data.data)
                setPerPage(newPage)
                setIsLoading(false)
            }

        } catch (err) { ErrorHandle(err) }
    }

    const handleSort = async ({ sortField }, sortDirection) => {
        try {

            setIsLoading(true)
            const _res = await http.post(`/UserCourseStudy?page=${Page}&per_page=${Per_Page}&sort=${sortField}&order=${sortDirection}${!isEmpty(Search) ? `&search=${Search}` : ''}`)
            if (_res.status === 200) {
                setTotalRecords(_res.data.total)
                setData(_res.data.data)
                setIsLoading(false)
            }

        } catch (err) { ErrorHandle(err) }
    }

    const handleSearch = async (search) => {
        await getData(1, search)
        setPage(1)
        setSearch(search)
    }

    const _columns = React.useMemo(() => [
        {
            name: '#',
            selector: (row) => row.rows,
            // cell: (row, index) => index + 1,
            sortable: false,
            width: '5%',
            // center: true,
        },
        {
            name: 'Certificate/ผลสอบ',
            cell: (row) => {
                return (
                    <React.Fragment>
                        <Button
                            type="button"
                            icon="pi pi-id-card"
                            className={`p-button-rounded p-button-success mr-1 p-button-sm w-2rem h-2rem`}
                            title={`ใบ Certificate`}
                            onClick={() => GetCertificate(row.userCourseStudyID)}
                            disabled={row.action === 2 ? false : true}
                        />
                        <Button
                            type="button"
                            icon="pi pi-chart-pie"
                            className={`p-button-rounded p-button-info mr-1 p-button-sm w-2rem h-2rem`}
                            title={`สรุปผลคะแนน`}
                            onClick={() => { RedirectTo(`/CourseProgress/${row.courseStudyID}/${row.action === 2 ? 2 : 1}`) }}
                        // disabled={row.action === 1 ? false : true}
                        />
                    </React.Fragment>
                )
            },
            sortable: false,
            center: true,
            // width: '10%',
        },
        {
            name: 'รูปภาพบทเรียน',
            selector: (row) => row.courseStudyDetail,
            cell: (row) => {
                return <Preview
                    Files={isEmpty(row.courseStudyFile) || isEmpty(row.fileUpload) || !row.checkFile ? window.location.origin + '/showcase/images/SF_Cinema_logo.jpg' : row.fileUpload}
                    FilesName={row.fileName}
                    FilesType={row.fileType}
                    Width={40}
                    Height={40}
                />
            },
            sortField: 'CourseStudyFile',
            sortable: false,
            center: true,
            // width: '10%',
        },
        {
            name: 'ชื่อบทเรียน',
            selector: (row) => row.title,
            sortField: 'Title',
            sortable: true,
            // center: true,
        },
        // {
        //     name: 'เนื้อหาบทเรียน',
        //     selector: (row) => row.detail,
        //     cell: (row) => !isEmpty(row.detail) ? <p style={TextNowrap} className="text-nowraps" dangerouslySetInnerHTML={{ __html: truncateText(row.detail, 70) }} /> : '-',
        //     sortField: 'Detail',
        //     sortable: true,
        // },
        {
            name: 'คะแนนบทเรียน',
            selector: (row) => row.pointScore,
            cell: (row) => `${row.userCourseStudySubScore}/${row.pointScore}`,
            sortField: 'PointScore',
            sortable: true,
            center: true,
        },
        {
            name: 'Point Reward',
            // selector: (row) => row.pointScore,
            selector: (row) => row.pointReward,
            sortField: 'PointReward',
            sortable: true,
            center: true,
        },
        {
            name: 'สถานะบทเรียน',
            selector: (row) => row.actionName,
            sortField: 'ActionStatus',
            sortable: true,
            center: true,
        },
        {
            name: 'วันที่สร้าง',
            selector: (row) => DateThai(row.createDate),
            sortField: 'CreateDate',
            sortable: true,
            // center: true,
        },
    ], [_DataTable])

    const onColReorder = () => toast.current.show({ severity: 'success', summary: 'Column Reordered', life: 3000 })

    const onRowReorder = (data) => {
        if (data.value.length === 0) return
        setItemList(data.value)
        //updateCourseStudySubSort(data.value)
    }

    const expandableRowComponent = ({ data }) => {

        const _DataIitem = ItemList.length > 0 ? ItemList : data.listCourseStudySub

        const DetailEditor = (rowData) => <p dangerouslySetInnerHTML={{ __html: rowData.detail }} style={TextNowrap}></p>

        const _columnsItem = [
            { field: 'row', header: '#', sortable: false },
            // { field: '', header: 'จัดลำดับ', sortable: false, rowReorder: true },
            { field: 'episode', header: 'บทที่', sortable: true },
            { field: 'title', header: 'ชื่อบทเรียนย่อย', sortable: true },
            { field: 'detail', header: 'เนื้อหา', sortable: true, body: DetailEditor },
            { field: 'pointScore', header: 'คะแนนบทเรียน', sortable: true },
            { field: 'createDate', header: 'วันที่สร้าง', sortable: true },
        ]

        return (
            <React.Fragment>
                <Card className="fadein py-2">
                    <div className="flex flex-row flex-wrap card-container">
                        <div className="flex-grow-1 align-items-center justify-content-center m-2">
                            <DataTable
                                responsiveLayout="stack"
                                value={
                                    _DataIitem.length > 0 ? _DataIitem.flatMap((item, i) => ({
                                        row: (i + 1),
                                        episode: item.episode,
                                        title: item.title,
                                        detail: item.detail,
                                        fileUpload: item.fileUpload,
                                        fileName: item.fileName,
                                        checkFile: item.checkFile,
                                        pointScore: item.pointScore,
                                        createDate: DateThai(item.createDate),
                                        courseStudySubId: item.courseStudySubId,
                                    })) : []
                                }
                                reorderableColumns
                                reorderableRows
                                onRowReorder={onRowReorder}
                                onColReorder={onColReorder}
                                header="รายการบทเรียนย่อย"
                                loading={IsLoading}
                                rowHover
                            >
                                {
                                    _columnsItem.map((col, i) => <Column key={uuidv4()} rowReorder={col.rowReorder} field={col.field} header={col.header} sortable={col.sortable} body={col.body} />)
                                }
                            </DataTable>

                        </div>
                    </div>
                </Card>
            </React.Fragment>
        )
    }

    const GetCertificate = async (UserCourseStudyID) => {

        if (isEmpty(UserCourseStudyID)) return

        try {

            setIsLoadingPage(true)
            const _res = await http.post('/Certificate/GetCertificateCourseStudy/' + UserCourseStudyID, undefined, {
                responseType: 'blob',
            })

            if (_res.status === 200) {
                setIsLoadingPage(false)
                const url = window.URL.createObjectURL(new Blob([_res.data], { type: 'application/pdf' }))
                window.open(url)
            }

        } catch (err) {
            setIsLoadingPage(false)
            ErrorHandle(err)
        }
    }

    return (
        <React.Fragment>
            <Loading isLoading={IsLoadingPage} />
            <Toast ref={toast} />
            <Card className="mt-3 m-2 shadow-5 fadein">
                <div className="col-md-12">
                    <DataTables
                        title={
                            <div className="grid">
                                <div className="col-12 md:col-6 lg:col-3 p-1">
                                    <h5 className="mr-2">ประวัติการเรียน</h5>
                                </div>
                            </div>
                        }
                        progressPending={IsLoading}
                        defaultSortFieldId={9}
                        columns={_columns}
                        data={_DataTable}
                        paginationTotalRows={TotalRecords}
                        paginationServer
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        // expandableRows
                        // expandableRowsComponent={expandableRowComponent}
                        sortServer
                        onSort={handleSort}
                        subHeader
                        subHeaderComponent={<SearhFilter onFilter={handleSearch} />}
                    />
                </div>
            </Card>
        </React.Fragment>
    )
}

export default React.memo(UserCourseStudyHistory)