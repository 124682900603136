import React from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'
import { Row } from 'react-bootstrap'
import { http, isEmpty, ErrorHandle, CheckImageType, CheckVideoType } from '../../Utils'
import Swal from 'sweetalert2'
import ReactPlayer from 'react-player/lazy'

const ViewLeanningVideo = (props) => {

    const { UserCourseStudySubID, setModal } = props

    const RedirectTo = useNavigate()

    const { register, handleSubmit, reset, setValue, watch, control, formState: { errors } } = useForm()

    // const [CurrentTime, setCurrentTime] = React.useState(0)
    const [NextPostTest, setNextPostTest] = React.useState(false)
    const [NextPreTest, setNextPreTest] = React.useState(false)
    const [NextLearning, setNextLearning] = React.useState(false)
    const [NextActionStatus, setNextActionStatus] = React.useState(false)
    const [Files, setFiles] = React.useState(null)
    const _RefVideo = React.useRef()

    React.useEffect(() => {

        (async () => {
            await getUserCourseStudySub()
        })()

    }, [UserCourseStudySubID])

    const getUserCourseStudySub = async () => {
        try {

            const _res = await http.post(`/UserCourseStudySub/FindBy/${UserCourseStudySubID}`)
            if (_res.status === 200 && _res.data.status) {
                for (const Key in Object.keys(_res.data.data)) {
                    const _Key = Object.keys(_res.data.data)[Key]
                    const _Value = Object.values(_res.data.data)[Key]
                    setValue(_Key.charAt(0).toUpperCase() + _Key.slice(1), _Value)
                }

                if (!isEmpty(_res.data.data.courseStudySubFileUpload)) await getFile(_res.data.data.courseStudySubFileUpload.fileid)

                if (_res.data.data.actionStatus !== 2 && _res.data.data.currentTime > 0)
                    handlePlay(_res.data.data.currentTime)
                else
                    _RefVideo.current.seekTo(0, 'seconds')
                // setTimeout(() => { _RefVideo.current.seekTo(_res.data.data.currentTime, 'seconds') }, 1000)
            }
        } catch (err) { ErrorHandle(err) }
    }

    const CourseStudySubPostStudyTest = () => {

        if (isEmpty(watch('CourseStudySubID'))) return
        Swal.fire({
            title: 'จะเข้าสู่บททดสอบหลังเรียนละน้า',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก'
        }).then(async (result) => {
            if (!result.isConfirmed) return
            setModal(false)
            // await UpdateUserCourseStudySubAction()
            RedirectTo(`/PostTestExamination/${watch('CourseStudySubID')}`)
        })
    }

    const CourseStudySubPretStudyTest = () => {

        if (isEmpty(watch('CourseStudySubID'))) return
        Swal.fire({
            title: 'จะเข้าสู่บททดสอบหลังเรียนละน้า',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก'
        }).then(async (result) => {
            if (!result.isConfirmed) return
            setModal(false)
            // await UpdateUserCourseStudySubAction()
            RedirectTo(`/PreTestExamination/${watch('CourseStudySubID')}`)
        })
    }

    const UpdateUserCourseStudySubAction = async () => {
        if (+watch('ActionStatus') > 0) return
        try {
            const res = await http.post(`UserCourseStudySub/UpdateActionStatus/${UserCourseStudySubID}`, { ActionStatus: 2 }, { headers: { 'Content-Type': 'multipart/form-data' } })
            //if (res.status === 200 && res.data.status) await GetCourseStudySub(res.data.courseStudyID)
        } catch (err) { ErrorHandle(err) }
    }

    const onProgress = async (state) => {
        if (!isEmpty(watch('ActionStatus')) && +watch('ActionStatus') < 2) await UpdateCurrentTimeDuration(state.playedSeconds)
        if (state.playedSeconds === state.loadedSeconds) return
        //await UpdateUserCourseStudySubAction()
    }

    const UpdateCurrentTimeDuration = async (duration) => {
        try {
            const _res = await http.post(`UserCourseStudySub/UpdateCurrentTimeDuration/${UserCourseStudySubID}`, { CurrentTimeDuration: duration }, { headers: { 'Content-Type': 'multipart/form-data' } })
            if (_res.status === 200 && _res.data.status) {
                if (_res.data.data.actionStatus === 2 && !isEmpty(_res.data.nextUserCourseStudySub)) {
                    setNextActionStatus(true)
                    CheckCourseStudySub(_res.data.nextUserCourseStudySub)
                }
            }
        } catch (err) { ErrorHandle(err) }
    }

    const CheckCourseStudySub = (data) => {
        // ไม่มีสอบ
        if (+data.preTest === 0 && +data.postTest === 0) {
            return setNextLearning(true)
        }
        // สอบก่อนเรียน
        if (+data.preTest === 1 && +data.actionStatus === 0) {
            if (+data.preTestStatus <= 1)
                return setNextPreTest(true)
            else
                return setNextLearning(true)
        }
        // สอบหลังเรียน
        if (+data.postTest === 1 && +data.preTestStatus === 0) {
            if (+data.postTestStatus === 1 && +data.actionStatus === 1)
                return setNextPostTest(true)
            else
                return setNextLearning(true)
        }
    }

    const clearData = () => {
        reset()
        setModal(false)
    }

    const handlePlay = React.useCallback((CurrentTime) => {
        // const timeToStart = (7 * 60) + 12.6
        Swal.fire({
            icon: 'info',
            title: 'ต้องการเล่นวิดีโอต่อจากจุดที่หยุดไว้หรือไม่',
            showCancelButton: true,
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
        }).then((result) => {
            if (!result.isConfirmed) return _RefVideo.current.seekTo(0, 'seconds')
            _RefVideo.current.seekTo(CurrentTime, 'seconds')
        })
    }, [UserCourseStudySubID])

    const nextCourseStudySub = async () => {

        if (watch('Sort') <= 0 || isEmpty(watch('CourseStudyID'))) return

        try {
            // setIsLoading(true)
            const _res = await http.post(`UserCourseStudySub/NextCourseStudySub/${watch('CourseStudyID')}/${+watch('Sort')}`)
            if (_res.status === 200 && _res.data.status) {
                if (!isEmpty(_res.data.data)) {
                    if (_res.data.data.preTest === 0 && _res.data.data.postTest <= 1 && _res.data.data.actionStatus === 0) {
                        clearData()
                        // RedirectTo(`/Learn/${_res.data.data.courseStudyID}`)
                        window.location.href = `/Learn/${_res.data.data.courseStudyID}`
                        return
                    } else {
                        if (_res.data.data.preTest === 1 && _res.data.data.postTest <= 1 && _res.data.data.actionStatus === 0) {
                            clearData()
                            RedirectTo(`/PreTestExamination/${_res.data.data.courseStudySubID}`)
                            return 
                        }
                        if (_res.data.data.postTest === 1 && _res.data.data.preTest <= 1 && _res.data.data.actionStatus >= 1) {
                            clearData()
                            RedirectTo(`/PostTestExamination/${_res.data.data.courseStudySubID}`)
                            return 
                        }
                    }
                }
            }
        } catch (err) {
            ErrorHandle(err)
            // setIsLoading(false)
        }
    }

    const getFile = async (FileID) => {

        if (isEmpty(FileID)) return

        try {
            const _res = await http.get(`/File/View/${sessionStorage.getItem('sessionToken')}/${FileID}`, { responseType: 'blob' })
            if (_res.status === 200) setFiles(_res.data)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <React.Fragment>
            <div className="flex flex-column w-full">
                {
                    Files && Files !== null && Files !== undefined &&
                    <Row>
                        <div className="flex align-items-center justify-content-center m-2 pdf-container">
                            <ReactPlayer
                                url={URL.createObjectURL(Files)}
                                ref={_RefVideo}
                                // url={watch('CourseStudySubFileUpload.fileurl')}
                                // progressInterval={1000}
                                onProgress={onProgress}
                                controls={true}
                                muted={false}
                                playing={false}
                                width="100%"
                                height="100%"
                                config={{
                                    file: {
                                        attributes: {
                                            controlsList: 'nodownload',
                                            disablePictureInPicture: true,
                                            disableRemotePlayback: true,
                                        },
                                        hlsOptions: {
                                            enableWorker: true,
                                            enableSoftwareAES: true,
                                            debug: false,
                                            xhrSetup: (xhr, url) => {
                                                xhr.withCredentials = true
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>
                    </Row>
                }
                {/* {
                    !isEmpty(watch('CourseStudySubFileUpload')) &&
                    <Row>
                        <div className="flex align-items-center justify-content-center m-2 pdf-container">
                            <ReactPlayer
                                ref={_RefVideo}
                                url={watch('CourseStudySubFileUpload.fileurl')}
                                // progressInterval={1000}
                                onProgress={onProgress}
                                controls={true}
                                muted={false}
                                playing={false}
                                width="100%"
                                height="100%"
                                // onReady={onReady}
                                onSeek={(t) => {
                                    t === 0 && UpdateCurrentTimeDuration(t)
                                }}
                                onPlay={() => console.log('onPlay')}
                                onStart={() => console.log('onStart')}
                                config={{
                                    file: {
                                        attributes: {
                                            controlsList: 'nodownload',
                                            disablePictureInPicture: true,
                                            disableRemotePlayback: true,
                                        },
                                        hlsOptions: {
                                            enableWorker: true,
                                            enableSoftwareAES: true,
                                            debug: false,
                                            xhrSetup: (xhr, url) => {
                                                xhr.withCredentials = true
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>
                    </Row>
                } */}
                <Row>
                    <div className="flex align-items-center justify-content-center m-2">
                        {/* {
                            NextPostTest && !NextActionStatus ?
                                <Button
                                    label="สอบหลังเรียน"
                                    className="p-button-raised p-button-success p-button-sm mt-3 mr-2"
                                    onClick={CourseStudySubPostStudyTest}
                                />
                                :
                                +watch('ActionStatus') === 2 &&
                                <Button
                                    label="เรียนบทเรียนถัดไป"
                                    icon="pi pi-chevron-right"
                                    className="p-button-raised p-button-warning p-button-sm mt-3 mr-2"
                                    onClick={() => {
                                        Swal.fire({
                                            title: 'ต้องการเรียนบทเรียนถัดไปหรือไม่',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: 'ตกลง',
                                            cancelButtonText: 'ยกเลิก'
                                        }).then(async (result) => {
                                            if (!result.isConfirmed) return
                                            await nextCourseStudySub()
                                            //clearData()
                                        })
                                    }}
                                />
                        } */}
                        {
                            NextActionStatus && NextPreTest &&
                            <Button
                                type="button"
                                icon="pi pi-pencil"
                                label="ทดสอบก่อนเรียนบทเรียนถัดไป"
                                title="ทดสอบก่อนเรียนบทเรียนถัดไป"
                                className="p-button-raised p-button-success p-button-sm mt-3 mr-2"
                                onClick={CourseStudySubPretStudyTest}
                            />
                        }
                        {
                            NextActionStatus && NextLearning && !NextPreTest && !NextPostTest &&
                            <Button
                                type="button"
                                icon="pi pi-chevron-right"
                                label="เรียนบทเรียนถัดไป"
                                title="เรียนบทเรียนถัดไป"
                                className="p-button-raised p-button-warning p-button-sm mt-3 mr-2"
                                onClick={() => {
                                    Swal.fire({
                                        title: 'ต้องการเรียนบทเรียนถัดไปหรือไม่',
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'ตกลง',
                                        cancelButtonText: 'ยกเลิก'
                                    }).then(async (result) => {
                                        if (!result.isConfirmed) return
                                        await nextCourseStudySub()
                                        //clearData()
                                    })
                                }}
                            />
                        }
                        {
                            NextActionStatus && NextPostTest &&
                            <Button
                                type="button"
                                icon="pi pi-pencil"
                                label="สอบหลังเรียนบทเรียนถัดไป"
                                title="สอบหลังเรียนบทเรียนถัดไป"
                                className="p-button-raised p-button-success p-button-sm mt-3 mr-2"
                                onClick={CourseStudySubPostStudyTest}
                            />
                        }
                    </div>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default React.memo(ViewLeanningVideo)