import React from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { http, isEmpty, ErrorHandle } from '../../Utils'
import Swal from 'sweetalert2'

const ResetPassword = (props) => {

    const { setModal } = props

    const { register, handleSubmit, formState: { errors }, watch, reset } = useForm()

    const onSubmit = async (data) => {

        if (isEmpty(data)) Swal.fire({ icon: 'warning', title: 'กรุณากรอกข้อมูลให้ครบถ้วน' })

        Swal.fire({
            title: 'ยืนยันการเปลี่ยนรหัสผ่าน',
            text: "คุณต้องการเปลี่ยนรหัสผ่านใช่หรือไม่",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
            cancelButtonText: "ยกเลิก",
            confirmButtonText: "ตกลง"
        }).then(async (result) => {

            if (!result.isConfirmed) return

            try {

                const _res = await http.post('/ResetPassword', { UserName: data.UserName }, { headers: { 'Content-Type': 'multipart/form-data' } })
                if (_res.status === 200 && _res.data.status) {
                    Swal.fire({ icon: 'success', title: 'เปลี่ยนรหัสผ่านสำเร็จ' })
                    clearData()
                }

            } catch (err) { ErrorHandle(err) }
        })
    }

    const clearData = () => {
        reset()
        setModal(false)
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6 mt-2">
                        <div className="grid">
                            <div className="col-12">
                                <label>กรอกรหัสพนักงาน  <span className="text-red-500">*</span></label>
                                <InputText
                                    {...register('UserName', { required: true })}
                                    className="mt-1 p-inputtext-sm"
                                />
                                {errors.UserName && errors.UserName.type === 'required' && <small className="p-error">กรุณากรอกข้อมูล</small>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-content-center flex-wrap card-container">
                    <div className="flex align-items-center justify-content-center m-2">
                        <Button label="เปลี่ยนรหัสผ่าน" className="p-button-raised p-button-success p-button-sm mt-3 mr-2 w-12rem" />
                        <Button type="button" label="ยกเลิก" className="p-button-raised p-button-danger p-button-sm mt-3 mr-2 w-12rem" onClick={clearData} />
                    </div>
                </div>
            </form>
        </React.Fragment>
    )
}

export default React.memo(ResetPassword)