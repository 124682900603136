import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Row } from 'react-bootstrap'
import { Document, Page, pdfjs } from 'react-pdf'
import Swal from 'sweetalert2'
import { useForm } from 'react-hook-form'
import { Button } from 'primereact/button'
import { http, isEmpty, ErrorHandle, CheckImageType, CheckVideoType } from '../../Utils'

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: '../../../public/fonts/SMARNFILM-Black.otf',
}

const StylePDF = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const ViewLeanningPDF = (props) => {

    const { UserCourseStudySubID, setModal } = props

    const RedirectTo = useNavigate()

    const { register, handleSubmit, reset, setValue, watch, control, formState: { errors } } = useForm()

    const [NextPostTest, setNextPostTest] = React.useState(false)
    const [NextPreTest, setNextPreTest] = React.useState(false)
    const [NextLearning, setNextLearning] = React.useState(false)
    const [NextActionStatus, setNextActionStatus] = React.useState(false)
    const [numPages, setNumPages] = React.useState(1)
    const [pageNumber, setPageNumber] = React.useState(1)
    const [Files, setFiles] = React.useState(null)

    React.useEffect(() => {

        (async () => {
            await getUserCourseStudySub()
        })()

    }, [UserCourseStudySubID])

    const getUserCourseStudySub = async () => {
        try {
            const _res = await http.post(`/UserCourseStudySub/FindBy/${UserCourseStudySubID}`)
            if (_res.status === 200 && _res.data.status) {
                for (const Key in Object.keys(_res.data.data)) {
                    const _Key = Object.keys(_res.data.data)[Key]
                    const _Value = Object.values(_res.data.data)[Key]
                    setValue(_Key.charAt(0).toUpperCase() + _Key.slice(1), _Value)
                }

                if (!isEmpty(_res.data.data.courseStudySubFileUpload)) await getFile(_res.data.data.courseStudySubFileUpload.fileurl)
            }
        } catch (err) { ErrorHandle(err) }
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages)
    }

    const handleNextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1)
        }
    }

    const handlePrevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1)
        }
    }

    const CourseStudySubPostStudyTest = () => {

        if (isEmpty(watch('CourseStudySubID'))) return

        Swal.fire({
            title: 'จะเข้าสู่บททดสอบหลังเรียนละน้า',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก'
        }).then(async (result) => {
            if (!result.isConfirmed) return
            const _res = await http.post(`UserCourseStudySub/UpdateActionStatus/${watch('UserCourseStudySubID')}`, { ActionStatus: 2 }, { headers: { 'Content-Type': 'multipart/form-data' } })
            if (_res.status === 200 && _res.data.status) {
                setModal(false)
                RedirectTo(`/PostTestExamination/${watch('CourseStudySubID')}`)
            }
        })
    }

    const UpdateUserCourseStudySubAction = async () => {

        if (+watch('ActionStatus') > 0) return

        try {

            const _res = await http.post(`UserCourseStudySub/UpdateActionStatus/${UserCourseStudySubID}`, { ActionStatus: 2 }, { headers: { 'Content-Type': 'multipart/form-data' } })
            if (_res.status === 200 && _res.data.status) {
                if (!isEmpty(_res.data.nextUserCourseStudySub)) {
                    // setNextActionStatus(true)
                    // CheckCourseStudySub(_res.data.nextUserCourseStudySub)
                    if (_res.data.nextUserCourseStudySub.preTest === 0 && _res.data.nextUserCourseStudySub.postTest === 0) {
                        return window.location.href = `/Learn/${_res.data.courseStudyID}`
                    }
                    if (+_res.data.nextUserCourseStudySub.preTest === 1 && +_res.data.nextUserCourseStudySub.actionStatus === 0) {
                        if (_res.data.nextUserCourseStudySub.preTestStatus <= 1) {
                            RedirectTo(`/PreTestExamination/${_res.data.nextUserCourseStudySub.courseStudySubId}`)
                            return
                        }
                    }
                    if (+_res.data.nextUserCourseStudySub.postTest === 1 && +_res.data.nextUserCourseStudySub.preTest === 0) {
                        if (_res.data.nextUserCourseStudySub.postTestStatus <= 1) {
                            RedirectTo(`/PostTestExamination/${_res.data.nextUserCourseStudySub.courseStudySubId}`)
                            return
                        }
                    }
                } else {
                    return RedirectTo(`/Learn/${_res.data.courseStudyID}`)
                }
                setValue('NextCourseStudyID', _res.data.nextUserCourseStudySub.courseStudyId)
                setValue('NextCourseStudySubID', _res.data.nextUserCourseStudySub.courseStudySubId)
                setValue('NextUserCourseStudyID', _res.data.nextUserCourseStudySub.userCourseStudyId)
                setValue('NextUserCourseStudySubID', _res.data.nextUserCourseStudySub.userCourseStudySubId)
                setValue('NextSort', _res.data.nextUserCourseStudySub.sort)
                //PostTest
                setValue('NextPostTest', _res.data.nextUserCourseStudySub.postTest)
                setValue('NextPostTestStatus', _res.data.nextUserCourseStudySub.postTestStatus)
                setValue('NextPostTestCorrectScore', _res.data.nextUserCourseStudySub.postTestCorrectScore)
                setValue('NextPostTestWrongScore', _res.data.nextUserCourseStudySub.postTestWrongScore)
                //PreTest
                setValue('NextPreTest', _res.data.nextUserCourseStudySub.preTest)
                setValue('NextPreTestStatus', _res.data.nextUserCourseStudySub.preTestStatus)
                setValue('NextPreTestCorrectScore', _res.data.nextUserCourseStudySub.preTestCorrectScore)
                setValue('NextPreTestWrongScore', _res.data.nextUserCourseStudySub.preTestWrongScore)
                // return window.location.href = `/Learn/${_res.data.courseStudyID}`tValue('NextActionStatus', _res.data.nextUserCourseStudySub.actionStatus)

                // return RedirectTo(`/Learn/${_res.data.courseStudyID}`)
            }
        } catch (err) { ErrorHandle(err) }
    }

    const clearData = () => {
        reset()
        setModal(false)
    }

    const CheckCourseStudySub = (data) => {
        // ไม่มีสอบ
        if (+data.preTest === 0 && +data.postTest === 0) {
            return setNextLearning(true)
        }
        // สอบก่อนเรียน
        if (+data.preTest === 1 && +data.actionStatus === 0) {
            if (+data.preTestStatus <= 1)
                return setNextPreTest(true)
            else
                return setNextLearning(true)
        }
        // สอบหลังเรียน
        if (+data.postTest === 1 && +data.preTestStatus === 0) {
            if (+data.postTestStatus === 1 && +data.actionStatus === 1)
                return setNextPostTest(true)
            else
                return setNextLearning(true)
        }
    }

    //File Upload
    const getFile = async (FileURL) => {

        if (isEmpty(FileURL)) return

        try {
            const _res = await http.get(FileURL, { responseType: 'blob' })
            if (_res.status === 200) setFiles(_res.data)
        } catch (err) {
            console.log(err)
        }
    }

    // console.log(watch())

    return (
        <React.Fragment>
            <div className="flex flex-column w-full">
                {
                    !isEmpty(watch('CourseStudySubFileUpload')) &&
                    <Row>
                        <div className="flex align-items-center justify-content-center m-2 pdf-container">
                            <Document
                                // file={watch('CourseStudySubFileUpload.fileurl')}
                                file={Files}
                                onLoadSuccess={onDocumentLoadSuccess}
                                onContextMenu={(e) => e.preventDefault()}
                                options={options}
                                StylePDF={StylePDF}
                            >
                                <div className="mt-2">
                                    <Page pageNumber={pageNumber} />
                                </div>
                            </Document>
                        </div>
                    </Row>
                }
                <Row>
                    <div className="flex align-items-center justify-content-center m-2">
                        <p>หน้า {pageNumber} จาก {numPages}</p>
                    </div>
                </Row>
                <Row>
                    <div className="flex align-items-center justify-content-center m-2">
                        <Button
                            label="ก่อนหน้า"
                            className="p-button-raised p-button-danger p-button-sm mt-3 mr-2"
                            onClick={handlePrevPage}
                            disabled={pageNumber === 1}
                        />
                        {(pageNumber < numPages)}
                        <Button
                            label="ถัดไป"
                            className="p-button-raised p-button-warning p-button-sm mt-3 mr-2"
                            onClick={handleNextPage}
                            disabled={pageNumber >= numPages}
                        />
                        {
                            (pageNumber >= numPages && watch('PostTest') === 1 && watch('postTestStatus') <= 1 ?
                                <>
                                    <Button
                                        label="สอบหลังเรียน"
                                        className="p-button-raised p-button-success p-button-sm mt-3 mr-2"
                                        onClick={CourseStudySubPostStudyTest}
                                    />
                                </>
                                : "")
                        }
                        {(pageNumber >= numPages && watch('PostTest') === 0 ?
                            // {(pageNumber >= numPages && watch('PostTest') === 0 && +watch('ActionStatus') === 0 ?
                            <>
                                <Button
                                    label="ยืนยันการเรียนสำเร็จ"
                                    className="p-button-raised p-button-success p-button-sm mt-3 mr-2"
                                    onClick={() => {
                                        Swal.fire({
                                            title: 'ยืนยันการเรียนสำเร็จ',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#22C55E',
                                            cancelButtonColor: '#EF4444',
                                            confirmButtonText: 'ตกลง',
                                            cancelButtonText: 'ยกเลิก'
                                        }).then(async (result) => {
                                            if (!result.isConfirmed) return
                                            Swal.fire('เรียบร้อย', 'ยืนยันการเรียนสำเร็จ', 'success').then(async (result) => {
                                                await UpdateUserCourseStudySubAction()
                                                clearData()
                                            })
                                        })
                                    }}
                                />
                            </>
                            : "")}
                    </div>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default (ViewLeanningPDF)