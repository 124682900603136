import React, { useState, useEffect } from 'react'
import { http, ErrorHandle, isEmpty } from '../../Utils'
import { format } from 'date-fns'
import { useForm } from 'react-hook-form'
import { Button } from 'primereact/button'

import DialogModal from '../../Components/Dialog'
import ResetPassword from '../../Components/ResetPassword'

const ProfileUser = (props) => {

    const { setModalProfile, Start } = props

    const { register, handleSubmit, reset, setValue, watch, control, formState: { errors } } = useForm()

    const [ImageFile, setImageFile] = React.useState(null)
    const [ImageFileName, setImageFileName] = React.useState('')
    const [ChekcFile, setChekcFile] = React.useState(false)
    const [DataUserProfile, setDataUserProfile] = useState({})

    const [ModalResetPassword, setModalResetPassword] = useState(false)

    useEffect(() => {

        (async () => await getProfile())()

    }, [])

    const getProfile = async () => {

        try {

            const _res = await http.post('/UserProfile/GetProfile')
            if (_res.status === 200 && _res.data.status) {
                setValue('UserProfileID', _res.data.data.userProfileID)
                setValue('NameTH', _res.data.data.nameTH)
                setDataUserProfile(_res.data.data)
                if (!_res.data.data.checkFile && !_res.data.data.defaultImage) return
                setChekcFile(true)
                setImageFile(_res.data.data.fileImage)
                setValue('image', _res.data.data.image)
            }
        } catch (err) { ErrorHandle(err) }
    }

    return (
        <React.Fragment>
            <DialogModal
                Header="รีเซ็ตรหัสผ่าน"
                Open={ModalResetPassword}
                Footer={""}
                OnHide={() => setModalResetPassword(!ModalResetPassword)}
                Width={50}
                // Width={window.innerWidth <= 1366 ? 90 : 60}
                // Height={window.innerWidth <= 1366 ? 100 : 70}
                // Maximized={window.innerWidth <= 1366 ? true : false}
                // Maximizable={window.innerWidth <= 1366 ? true : false}
                Content={<ResetPassword setModal={setModalResetPassword} />}
            />
            <div className="container-fluid">
                <div className="row p-0">
                    <div className="col-md-4 mb-3">
                        <div className="card-body">
                            <div className="d-flex flex-column align-items-center text-center">
                                <img src={!isEmpty(DataUserProfile.image) ? DataUserProfile.image : window.location.origin + '/showcase/SIS_Admin.jpg'} alt="Admin" className="rounded-circle" width={150} />
                                <div className="mt-4">
                                    <h4>{!isEmpty(DataUserProfile.nameTH) ? DataUserProfile.nameTH : 'ไม่พบข้อมูล'}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="card mb-1">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <h6 className="mb-0">ชื่อ – นามสกุล</h6>
                                    </div>
                                    <div className="col-md-9 text-secondary">
                                        {!isEmpty(DataUserProfile) ? DataUserProfile.nameTH : "ไม่พบข้อมูล"}
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-3">
                                        <h6 className="mb-0">สาขา / แผนก</h6>
                                    </div>
                                    <div className="col-md-9 text-secondary">
                                        {!isEmpty(DataUserProfile) ? DataUserProfile.employeeBranch : ''}
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-3">
                                        <h6 className="mb-0">ตำแหน่ง</h6>
                                    </div>
                                    <div className="col-md-9 text-secondary">
                                        {!isEmpty(DataUserProfile) ? DataUserProfile.position : "ไม่พบข้อมูล"}
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-3">
                                        <h6 className="mb-0">รหัสพนักงาน</h6>
                                    </div>
                                    <div className="col-md-9 text-secondary">
                                        {!isEmpty(DataUserProfile.no) ? DataUserProfile.no : 'ไม่พบข้อมูล'}
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-3">
                                        <h6 className="mb-0">วันที่เริ่มงาน</h6>
                                    </div>
                                    <div className="col-md-9 text-secondary">
                                        {!isEmpty(DataUserProfile) ? format(new Date(DataUserProfile.workingStartDate), 'dd/MM/yyyy') : "ไม่พบข้อมูล"}
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-3">
                                        <h6 className="mb-0">อายุงาน </h6>
                                    </div>
                                    <div className="col-md-9 text-secondary">
                                        {!isEmpty(DataUserProfile) ? DataUserProfile.experience : "ไม่มีข้อมูล"}
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-3">
                                        <h6 className="mb-0">ผู้บังคับบัญชา</h6>
                                    </div>
                                    <div className="col-md-9 text-secondary">
                                        {!isEmpty(DataUserProfile) ? DataUserProfile.reportToName : "ไม่พบข้อมูล"}
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-3">
                                        <h6 className="mb-0">คะแนนสะสม</h6>
                                    </div>
                                    <div className="col-md-9 text-secondary">
                                        {!isEmpty(DataUserProfile) ? DataUserProfile.point : 0}
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-12">
                                        <Button
                                            type="button"
                                            label="รีเซ็ตรหัสผ่าน"
                                            icon="pi pi-unlock"
                                            className="p-button-warning mr-1 p-button-sm w-12rem"
                                            title="รีเซ็ตรหัสผ่าน"
                                            onClick={() => setModalResetPassword(!ModalResetPassword)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(ProfileUser)