import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'primereact/card';
import { Container } from 'react-bootstrap';
import { Image } from 'primereact/image';
import { Col, Row } from 'react-bootstrap';
import { Rating } from 'primereact/rating';
import { http, isEmpty } from "../../Utils";

const Accomplishments = () => {

    const [DataUserProfile, setDataUserProfile] = useState([]);

    // useEffect(() => {
    //     (async () => {
    //         await GetDataUserProfile();
    //     })();
    // }, []);

    // const GetDataUserProfile = async () => {
    //     try {
    //         const _ResFindBy = await http.post("/User/FindBy/" + "d28e1169-33ff-45a0-a7f6-b3c9431ce05d")
    //         if (_ResFindBy.status === 200 && _ResFindBy.data.status) {
    //             setDataUserProfile(_ResFindBy.data.data)
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // };

    return (
        <React.Fragment>
            <Container>
                <div className="col-md-12 mb-3 mt-2">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex flex-column align-items-center text-center">
                                <img src={DataUserProfile.image !== null && DataUserProfile.image !== '' && DataUserProfile.image !== undefined ? DataUserProfile.image : "/showcase/images/SF_Cinema_logo.jpg"} className="rounded-circle" width={150} />
                                <div className="mt-3">
                                    <h4>{DataUserProfile.nameTh}</h4>
                                    <p className="text-secondary mb-1">{DataUserProfile.department}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-2">
                    <Card title="Accomplishments" style={{ width: '100%', marginBottom: '2em' }} >
                        <Row>
                            <Col md={2}>
                                <div>
                                    <Image src="https://cf.shopee.co.th/file/sg-11134201-22110-7ens218nmfkve8" alt="Image" width="150" />
                                </div>
                            </Col>
                            <Col md={6}>
                                <p className="m-0"  >Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt
                                    quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!</p>
                                <li>ดูใบประกาศ</li>
                                <Image src="https://cf.shopee.co.th/file/sg-11134201-22110-7ens218nmfkve8" alt="Image" width="50" preview />
                                <h5>ระดับ</h5>
                                <Rating value={5} readOnly stars={5} cancel={false} />
                            </Col>
                        </Row>
                    </Card>
                </div>
            </Container>
        </React.Fragment>
    )
}

export default React.memo(Accomplishments)