import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card as Cards } from 'react-bootstrap'
import { http, truncateText, stripHtml, isEmpty, ErrorHandle } from "../../Utils"
import { Link, useNavigate } from "react-router-dom"
import Carousel from "react-multi-carousel"
import { Nav } from "react-bootstrap"
import { v4 as uuidv4 } from 'uuid'

import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { Image } from "semantic-ui-react"
import "./styles.css"
import "react-multi-carousel/lib/styles.css"
import "./Carousel.css"

const TextNowrap = {
    flexDirection: 'row',
    whiteSpace: 'pre-line',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '15px',
    height: '2.5rem',
    width: '100%',
    minWidth: '1px',
}

const TitleTextNowrap = {
    flexDirection: 'row',
    whiteSpace: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '45px',
    width: '100%',
    minWidth: '1px',
}

const ContentNewsCourse = () => {

    const [DataCourseStudy, setDataCourseStudy] = useState([])
    const [DataCourseRecommend, setCourseRecommend] = useState([])
    const [DataCourseStudyContinue, setCourseStudyContinue] = useState([])
    const [isLoad, setIsLoad] = useState(false)

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            paritialVisibilityGutter: 110
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            paritialVisibilityGutter: 50
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            paritialVisibilityGutter: 30
        }
    }

    useEffect(() => {

        (async () => {
            await GetCourseStudy()
        })()

    }, [])

    const CheckScrollPass = async () => {
        // if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return
        // await GetCourseStudyRecommend()
        try {

            const _res = await http.post("/UserCourseStudy/UserCheckActionSuccess/C5E6003F-9367-49D8-BFA0-51ED700CA35D")
            if (_res.status === 200 && _res.data.status) {
                // console.log(_res.data.data)
            }

        } catch (err) { ErrorHandle(err) }
    }

    const GetCourseStudy = async () => {
        try {

            setIsLoad(true)
            const _res = await http.post("/CourseStudy/FindListCourseStudy")
            if (_res.status === 200 && _res.data.status) {
                if (_res.data.data.length <= 0) return
                // setDataCourseStudy(_res.data.data)
                setDataCourseStudy(_res.data.data.filter(x => +x.rules === 0))
                setCourseRecommend(_res.data.data.filter(x => +x.rules === 1))
                setIsLoad(false)
                await GetCourseStudyContinue(+_res.data.limit)
            }
        } catch (err) { ErrorHandle(err) }
    }

    const GetCourseStudyContinue = async (Limit) => {
        try {

            const _res = await http.post('UserCourseStudy/UserCourseStudyContinueDashBoard', { ActionStatus: 1, Search: '', Offset: 0, Limit: Limit }, { headers: { "Content-Type": "multipart/form-data" } })
            if (_res.status === 200 && _res.data.status) setCourseStudyContinue(_res.data.data)

        } catch (err) { ErrorHandle(err) }
    }

    const GetCourseStudyRecommend = async () => {
        const _resCourseRecommend = await http.post("/CourseStudy/FindListCourseStudyRecommend")
    }

    const productTemplate = (data) => {

        const _Detail = !isEmpty(data.detail) ? stripHtml(data.detail) : data.detail
        const _Title = !isEmpty(data.title) ? stripHtml(data.title) : data.title
        const _Advice = !isEmpty(data.advice) ? stripHtml(data.advice).replace(/\s/g, '') : data.advice

        return (
            <Container>
                <div className="card">
                    <div className="card-head">
                        <Link to={`/Learn/${data.courseStudyID}/1`} className="p-button-info">
                            {/* <Cards.Img variant="top" src={data.fileUpload} /> */}
                            <Cards.Img src={!isEmpty(data.fileUpload) ? data.fileUpload : window.location.origin + '/showcase/600x400SF.jpg'} width="100%" />
                        </Link>
                    </div>
                    <div className="card-body" style={{ height: '10rem', width: '100%', float: 'left' }}>
                        {/* <Cards.Title className="font-weight-bold">{!isEmpty(_Title) ? truncateText(_Title, 40) : 'ไม่พบเนื้อหา'}</Cards.Title> */}
                        <Cards.Title as="h5" className="font-bold" style={TitleTextNowrap}>{!isEmpty(_Title) ? _Title : ''}</Cards.Title>
                        <p className="mt-3" style={TextNowrap} dangerouslySetInnerHTML={{ __html: !isEmpty(data.detail) ? data.detail : 'ไม่พบเนื้อหา' }}></p>
                    </div>
                    <div className="p-1 m-0" style={{ height: '2rem', width: '100%', float: 'left' }}>
                        <p className="text-nowraps ml-2" style={{ height: '2.5rem', fontSize: '14px' }}>คอร์สนี้เหมาะสำหรับ {!isEmpty(_Advice) ? truncateText(stripHtml(_Advice).replace(/\s/g, ' '), 30) : ''}</p>
                        {isEmpty(_Advice) && <small className="text-muted"><br /></small>}
                    </div>
                </div>
            </Container>
        )
    }

    const productTemplateRule = (data) => {

        const _Detail = !isEmpty(data.detail) ? stripHtml(data.detail) : data.detail
        const _Title = !isEmpty(data.title) ? stripHtml(data.title) : data.title
        const _Advice = !isEmpty(data.advice) ? stripHtml(data.advice).replace(/\s/g, '') : data.advice

        return (
            <Container>
                <div className="card">
                    <div className="card-head">
                        <Link to={`/Learn/${data.courseStudyID}/1`} className="p-button-info">
                            {/* <Cards.Img variant="top" src={data.fileUpload} /> */}
                            <Cards.Img src={!isEmpty(data.fileUpload) ? data.fileUpload : window.location.origin + '/showcase/600x400SF.jpg'} width="100%" />
                        </Link>
                    </div>
                    <div className="card-body" style={{ height: '10rem', width: '100%', float: 'left' }}>
                        {/* <Cards.Title className="font-weight-bold">{!isEmpty(_Title) ? truncateText(_Title, 40) : 'ไม่พบเนื้อหา'}</Cards.Title> */}
                        <Cards.Title as="h5" className="font-bold" style={TitleTextNowrap}>{!isEmpty(_Title) ? _Title : ''}</Cards.Title>
                        <p className="mt-3" style={TextNowrap} dangerouslySetInnerHTML={{ __html: !isEmpty(data.detail) ? data.detail : '' }}></p>
                    </div>
                    <div className="p-1 m-0" style={{ height: '2rem', width: '100%', float: 'left' }}>
                        <p className="text-nowraps ml-2" style={{ height: '2.5rem', fontSize: '14px' }}>คอร์สนี้เหมาะสำหรับ {!isEmpty(_Advice) ? truncateText(stripHtml(_Advice).replace(/\s/g, ' '), 30) : ''}</p>
                        {isEmpty(_Advice) && <small className="text-muted"><br /></small>}
                    </div>
                </div>
            </Container>
        )
    }

    const productTemplateCourseStudyContinue = (data) => {

        const _Detail = !isEmpty(data.detail) ? stripHtml(data.detail) : data.detail
        const _Title = !isEmpty(data.title) ? stripHtml(data.title) : data.title
        const _Advice = !isEmpty(data.advice) ? stripHtml(data.advice).replace(/\s/g, '') : data.advice

        return (
            <Container>
                <div className="card">
                    <div className="card-head">
                        <Link to={`/Learn/${data.courseStudyID}/1`} className="p-button-info">
                            {/* <Cards.Img variant="top" src={!isEmpty(data.fileUpload) ? data.fileUpload.fileurl : window.location.origin + '/showcase/SIS_Admin.jpg'} /> */}
                            <Cards.Img src={!isEmpty(data.fileUpload) ? data.fileUpload.fileurl : window.location.origin + '/showcase/600x400SF.jpg'} width="100%" />
                        </Link>
                    </div>
                    <div className="card-body" style={{ height: '10rem', width: '100%', float: 'left' }}>
                        <Cards.Title as="h5" className="font-bold" style={TitleTextNowrap}>{!isEmpty(_Title) ? _Title : ''}</Cards.Title>
                        <p className="mt-3" style={TextNowrap} dangerouslySetInnerHTML={{ __html: !isEmpty(data.detail) ? data.detail : 'ไม่พบเนื้อหา' }}></p>
                    </div>
                    <div className="p-1 m-0" style={{ height: '2rem', width: '100%', float: 'left' }}>
                        <p className="text-nowraps ml-2" style={{ height: '2.5rem', fontSize: '14px' }}>คอร์สนี้เหมาะสำหรับ {!isEmpty(_Advice) ? truncateText(stripHtml(_Advice).replace(/\s/g, ' '), 30) : ''}</p>
                        {isEmpty(_Advice) && <small className="text-muted"><br /></small>}
                    </div>
                </div>
            </Container>
        )
    }

    const _responsiveRecommend = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: DataCourseRecommend.length < 4 ? 4 : 3,
            // partialVisibilityGutter: 110
            partialVisibilityGutter: window.innerWidth <= 1366 ? 95 : 110
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 30
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 50
        }
    }

    const _responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            // items: DataCourseStudy.length === 3 ? 4 : 3,
            items: DataCourseStudy.length < 4 ? 4 : 3,
            // partialVisibilityGutter: 95
            partialVisibilityGutter: window.innerWidth <= 1366 ? 95 : 110
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 30
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 50
        }
    }

    const _responsiveCourseStudyContinue = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: DataCourseStudyContinue.length < 4 ? 4 : 3,
            partialVisibilityGutter: window.innerWidth <= 1366 ? 95 : 110
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 30
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 50
        }
    }

    return (
        <Container className="mt-3">
            <Row className="mb-2">
                {
                    DataCourseStudyContinue.length > 0 &&
                    <div className="p-1">
                        <Col md={12} className="mt-2 ml-4">
                            <div className="d-flex justify-content-between">
                                <h5 className="text-left">คอร์สที่กำลังเรียน</h5>
                            </div>
                        </Col>
                        <Col md={12} className="mt-2">
                            {
                                DataCourseStudyContinue.length > 0 &&
                                <Carousel
                                    containerClass="container"
                                    partialVisbile
                                    responsive={_responsiveCourseStudyContinue}
                                    ssr
                                    keyBoardControl={true}
                                    itemClass="carousel-item-padding-40-px"
                                    transitionDuration={500}
                                    rewind={false}
                                    rewindWithAnimation={false}
                                    rtl={false}
                                    showDots={false}
                                    swipeable
                                    focusOnSelect={false}
                                    minimumTouchDrag={80}
                                    pauseOnHover
                                    renderArrowsWhenDisabled={false}
                                    renderButtonGroupOutside={false}
                                    renderDotsOutside={false}
                                    additionalTransfrom={0}
                                    arrows
                                    autoPlaySpeed={2500}
                                    draggable={false}
                                    slidesToSlide={2}
                                >
                                    {DataCourseStudyContinue.flatMap((Item) => (
                                        <div key={Item.userCourseStudyID}>
                                            {productTemplateCourseStudyContinue(Item)}
                                        </div>
                                    ))}
                                </Carousel>
                            }
                        </Col>
                    </div>
                }
            </Row>
            <Row className="mb-2">
                {
                    DataCourseRecommend.length > 0 &&
                    <div className="p-1">
                        <Col md={12} className="mt-2 ml-4">
                            <div className="d-flex justify-content-between">
                                <h5 className="text-left">คอร์สของฉัน</h5>
                                <h6 className="text-right mr-5">
                                    <Nav.Link as={Link} to="/Content">ดูทั้งหมด{" >"}</Nav.Link>
                                </h6>
                            </div>
                        </Col>
                        <Col md={12} className="mt-2">
                            <Carousel
                                // removeArrowOnDeviceType={["tablet", "mobile"]}
                                // dotListClass="custom-dot-list-style"
                                containerClass="container"
                                partialVisbile
                                responsive={_responsiveRecommend}
                                ssr
                                keyBoardControl={true}
                                itemClass="carousel-item-padding-40-px"
                                transitionDuration={500}
                                rewind={false}
                                rewindWithAnimation={false}
                                rtl={false}
                                showDots={false}
                                swipeable
                                focusOnSelect={false}
                                // infinite
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                additionalTransfrom={0}
                                arrows
                                autoPlaySpeed={2500}
                                draggable={false}
                                slidesToSlide={2}
                            // shouldResetAutoplay
                            // autoPlay
                            >
                                {DataCourseRecommend.flatMap((Item) => (
                                    <div key={uuidv4()}>
                                        {productTemplate(Item)}
                                    </div>
                                ))}
                            </Carousel>
                        </Col>
                    </div>
                }
            </Row>
            <Row className="mb-2">
                {
                    DataCourseStudy.length > 0 &&
                    <div className="p-1">
                        <Col md={12} className="mt-2 ml-4">
                            <div className="d-flex justify-content-between">
                                <h5 className="text-left">คอร์สแนะนำ</h5>
                            </div>
                        </Col>
                        <Col md={12} className="mt-2">
                            <Carousel
                                // dotListClass="custom-dot-list-style"
                                // removeArrowOnDeviceType={["tablet", "mobile"]}
                                containerClass="container"
                                partialVisbile
                                responsive={_responsive}
                                ssr
                                keyBoardControl={true}
                                itemClass="carousel-item-padding-40-px"
                                transitionDuration={500}
                                rewind={false}
                                rewindWithAnimation={false}
                                rtl={false}
                                showDots={false}
                                swipeable
                                focusOnSelect={false}
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                additionalTransfrom={0}
                                arrows
                                draggable={false}
                            // autoPlaySpeed={2500}
                            // infinite
                            // slidesToSlide={2}
                            // shouldResetAutoplay
                            // autoPlay
                            >
                                {DataCourseStudy.flatMap((Item) => (
                                    <div key={uuidv4()}>
                                        {productTemplateRule(Item)}
                                    </div>
                                ))}
                            </Carousel>
                        </Col>
                    </div>
                }
            </Row>
        </Container>
    )
}

const Loading = () => {
    return (
        <div className="col-md-12 text-center">
            <h5 className="text-red-600">กรุณารอสักครู่.....</h5>
        </div>
    )
}

export default React.memo(ContentNewsCourse)