import React, { useState, useEffect } from 'react';
import { Image } from 'primereact/image'
import { http, isEmpty, truncateText, stripHtml, DateThai } from '../../Utils'
import DataTable from '../../Components/DataTable';
import SearhFilter from '../../Components/DataTable/SearhFilter'
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import './style.css'
const News = (props) => {

    const [Data, setData] = React.useState([])
    React.useEffect(() => {

        (async () => {
            await getData()
        })()
    }, [props.title])
    const getData = async () => {
        try {

            const _res = await http.post("/News/FindList")
            if (_res.status === 200 && _res.status === 200) {
                setData(_res.data.data)

            }
        } catch (err) {
            console.log(err.message)
        }
    }
    return (
        <React.Fragment>

            <Container className="Carousel-custom_">
                <div className="product-item-content_">
                    <Row>
                        {
                            Data.length > 0 ? Data.map((item, index) => {
                                return (
                                    <Col md={3} className='ColCrad_'>
                                        <div className="container mt-3 ">
                                            <div>

                                                <Card sx={{ maxWidth: 345 }}>

                                                    <CardMedia
                                                        sx={{ height: 230 }}
                                                        image={`${item.fileUpload}`} onError={(e) => e.target.src = '/showcase/images/SF_Cinema_logo.jpg'}
                                                        title="green iguana"
                                                    />
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h5" component="div" >
                                                            {truncateText(DateThai(item.createDate))}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            {truncateText(item.newsTitle, 100)}
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions>
                                                        <a>อ่านเพิ่มเติม</a>
                                                        <Nav.Link as={Link} to={"/NewsDetail/" + item.newsID} className="p-button-info ">
                                                            <a size="small">Learn More</a>
                                                        </Nav.Link>
                                                    </CardActions>
                                                </Card>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            }) : null

                        }
                    </Row>
                </div>
            </Container>

        </React.Fragment>
    )
}
export default React.memo(News);