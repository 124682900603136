import React, { useState, useEffect } from 'react'
import { useForm, Controller, useFieldArray, FormProvider } from 'react-hook-form'
import { Container, Row, Col } from 'react-bootstrap'
import { Ripple } from 'primereact/ripple'
import { Badge } from 'primereact/badge'
import { ScrollPanel } from 'primereact/scrollpanel'
import { RadioButton } from 'primereact/radiobutton'
import { Checkbox } from 'primereact/checkbox'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Image } from 'primereact/image'
import { v4 as uuidv4 } from 'uuid'
import { http, isEmpty, ErrorHandle, ScrollTop, NewLineText, ReplaceNewLine } from '../../Utils'
import { Button } from 'primereact/button'
import Swal from 'sweetalert2'

import DialogModal from '../../Components/Dialog'
import ViewLearningPDF from '../../Components/ViewLearningPDF'
import ViewLearningVideo from '../../Components/ViewLearningVideo'
import ViewLearning from '../../Components/ViewLearning'
import Preview from '../../Components/Previews'
import Loading from '../../Components/Loading'
import PDFView from '../../Components/ViewLearningPDF/PDFView'
import '../Content/Carousel.css'
import './styles.css'

const PreTestExamination = () => {

    const { courseStudySubID } = useParams()

    const RedirectTo = useNavigate()

    const methods = useForm()
    const { register, handleSubmit, formState: { errors }, setValue, getValues, watch, control, reset } = methods
    const { fields } = useFieldArray({ control, name: "QuizQuestion" })

    const [BlockSubmit, setBlockSubmit] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [DataQuizQuestionList, setDataQuizQuestionList] = React.useState([])
    const [DataQuestionAnswerList, setDatQuestionAnswerList] = React.useState([])
    const [StudyTypeTest, setStudyTypeTest] = React.useState({ preStudyTest: 0, postStudyTest: 0 })
    const [UserCourseStudySubID, setUserCourseStudySubID] = React.useState('')

    const [DataCourseStudySub, setDataCourseStudySub] = React.useState({
        detail: '',
        title: '',
        preStudyTest: 0,
        postStudyTest: 0,
        userCourseStudySubCompleteDate: '',
        userCourseStudySubActionStatus: 0,
        preTestStatus: 0,
        postTestStatus: 0,
        actionStatus: 0,
    })
    const [DataUserQuizQuestion, setDataUserQuizQuestion] = React.useState({
        postTestCorrectScore: 0,
        postTestStatus: 0,
        postTestWrongScore: 0,
        preTestCorrectScore: 0,
        preTestStatus: 0,
        preTestWrongScore: 0,
        totalScore: 0,
    })

    const [Files, setFiles] = useState(null)

    const [ModalPDF, setModalPDF] = useState(false)
    const [ModalViewPDF, setModalViewPDF] = useState(false)
    const [ModalVideo, setModalVideo] = useState(false)
    const [ModalLearning, setModalLearning] = useState(false)

    React.useEffect(() => {

        (async () => {
            await GetDataQuizQuestion()
        })()

    }, [courseStudySubID])

    const CheckPrevUserCourseStudySubSuccess = async (UserCourseStudySubID) => {
        const _res = await http.post(`UserCourseStudySub/CheckPrevUserCourseStudySubSuccess/${UserCourseStudySubID}`)
        if (_res.status === 200) {
            return _res.data.status;
        }
    }

    const GetDataQuizQuestion = async () => {
        try {
            setIsLoading(true)
            const _res = await http.post(`QuizQuestion/FindListBy/${courseStudySubID}`, { PreTest: 1 }, { headers: { 'Content-Type': 'multipart/form-data' } })
            // console.log(_res.data.data)
            if (_res.status === 200 && _res.data.status) {
                // http://localhost:3006/PreTestExamination/9a147c86-5aab-4d77-b417-b04f9d36c541

                if (_res.data.courseStudySub.preStudyTest === 0) {
                    Swal.fire('แจ้งเตือน', 'ไม่การทดสอบก่อนเรียน', 'warning').then((ok) => RedirectTo(`/Learn/${_res.data.courseStudySub.courseStudyID}`))
                    return
                }

                if (!isEmpty(_res.data.courseStudySub)) {
                    const _data = _res.data.courseStudySub
                    for (const Key in Object.keys(_data)) {
                        const _Key = Object.keys(_data)[Key]
                        const _Value = Object.values(_data)[Key]
                        setValue(_Key.charAt(0).toUpperCase() + _Key.slice(1), _Value)
                    }
                }

                setDataCourseStudySub(_res.data.courseStudySub)
                setDataQuizQuestionList(_res.data.data)
                setUserCourseStudySubID(_res.data.courseStudySub.userCourseStudySubID)
                setStudyTypeTest({
                    preStudyTest: _res.data.courseStudySub.preStudyTest,
                    postStudyTest: _res.data.courseStudySub.postStudyTest,
                })
                const _CheckPrevUserCourseStudySubSuccess = await CheckPrevUserCourseStudySubSuccess(_res.data.courseStudySub.userCourseStudySubID)
                if (_CheckPrevUserCourseStudySubSuccess === false) {
                    Swal.fire('แจ้งเตือน', 'โปรดตรวจสอบบทเรียนให้ถูกต้องตามลำดับ', 'warning').then((ok) => {
                        if (ok.isConfirmed) {
                            RedirectTo(`/`)
                        }
                    })
                }

                if (_res.data.courseStudySub.randomQuizQuestion === 1) _res.data.data.sort(() => Math.random() - 0.5)
                const _ListQuizQuestion = _res.data.data.map((item, idx) => {

                    if (_res.data.courseStudySub.randomAnswer === 1) item.listAnswer.sort(() => Math.random() - 0.5)
                    const _ListAnswer = item.listAnswer.map((_item, _idx) => {
                        return {
                            UserAnswerID: _item.userAnswerID,
                            AnswerID: _item.answerID,
                            AnswerName: _item.answerName,
                            AnswerCorrect: _item.answerCorrect,
                            Correct: _item.correct,
                            ActionStatus: _item.actionStatus,
                            _Correct: _item._Correct,
                        }
                    })
                    setValue(`Answer[${idx}].QuizQuestionAnswer`, _ListAnswer)

                    return {
                        UserQuizQuestionID: item.userQuizQuestionID,
                        QuizQuestionID: item.quizQuestionID,
                        QuizQuestionTitle: item.quizQuestionTitle,
                        QuizQuestionType: item.quizQuestionType,
                        PostTestStatus: item.postTestStatus,
                        PreTestStatus: item.preTestStatus,
                        Fileupload: item.fileupload,
                        FileType: item.fileType,
                        CheckFile: item.checkFile,
                        QuizQuestionScore: item.quizQuestionScore,
                        UserQuizQuestionActionStatus: item.userQuizQuestionActionStatus,
                        Correct: item.correct,
                    }
                })
                setValue("QuizQuestion", _ListQuizQuestion)

                if (!isEmpty(_res.data.courseStudySub.userCourseStudySubID)) await GetQuizQuestionScore(_res.data.courseStudySub.userCourseStudySubID)
                setIsLoading(false)
                ScrollTop()
            }
        } catch (err) {
            ErrorHandle(err)
            setIsLoading(false)
        }
    }

    const GetQuizQuestionScore = async (UserCourseStudySubID) => {

        if (isEmpty(UserCourseStudySubID) && DataCourseStudySub.preTestStatus > 1) return

        try {

            setIsLoading(true)
            const _res = await http.post(`UserQuizQuestion/FindQuizQuestionScore/${UserCourseStudySubID}`, { PreTest: 1 }, { headers: { 'Content-Type': 'multipart/form-data' } })
            if (_res.status === 200 && _res.data.status) {
                setDataUserQuizQuestion(_res.data.data)
                setValue('UserCourseStudyActionStatus', _res.data.data.userCourseStudyActionStatus)
                setValue('UserCourseStudyCompleteDate', _res.data.data.userCourseStudyCompleteDate)
                setIsLoading(false)
            }

        } catch (err) {
            ErrorHandle(err)
            setIsLoading(false)
        }
    }

    const onSubmit = async (data) => {
        // console.log(data)
        if (data.QuizQuestion.length === 0) return Swal.fire('แจ้งเตือน', 'ไม่พบข้อมูลคำถาม', 'warning')
        if (data.Answer.length === 0) return Swal.fire('แจ้งเตือน', 'กรุณาเลือกคำตอบ', 'warning')

        let _rowAnswer = 0
        for await (const _ItemQuizQuestion of data.QuizQuestion) {
            _rowAnswer++
            const _ItemAnswer = data.Answer[_rowAnswer - 1].QuizQuestionAnswer.filter((item) => item.AnswerCorrect)
            if (_ItemAnswer.length <= 0) return Swal.fire('แจ้งเตือน', `กรุณาเลือกคำตอบ ข้อที่ ${_rowAnswer}`, 'warning')
        }

        const _ListQuizQuestion = data.QuizQuestion.flatMap((item, idx) => {

            const _ListAnswer = data.Answer[idx].QuizQuestionAnswer.map((_item, _idx) => {
                return {
                    UserAnswerID: _item.UserAnswerID,
                    AnswerID: _item.AnswerID,
                    AnswerName: _item.AnswerName,
                    AnswerCorrect: _item.AnswerCorrect ? 1 : 0,
                }
            })

            return {
                UserQuizQuestionID: item.UserQuizQuestionID,
                QuizQuestionID: item.QuizQuestionID,
                QuizQuestionTitle: item.QuizQuestionTitle,
                QuizQuestionType: item.QuizQuestionType,
                ListAnswer: _ListAnswer
            }
        })

        const _data = {
            CourseStudySubID: courseStudySubID,
            PostTest: 0,
            PreTest: 1,
            ListQuizQuestion: _ListQuizQuestion
        }

        Swal.fire({
            icon: 'question',
            title: `ยืนยันการบันทึก`,
            text: `คุณต้องการบันทึกข้อมูลใช่หรือไม่`,
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
        }).then(async (result) => {

            if (!result.isConfirmed) return
            setBlockSubmit(true)
            try {
                setIsLoading(true)
                const _res = await http.post(`UserQuizQuestion/Save`, _data)
                if (_res.status === 200 && _res.data.status) return Swal.fire('สำเร็จ', _res.data.msg, 'success').then(() => window.location.reload())
                // if (_res.status === 200 && _res.data.status) return Swal.fire('สำเร็จ', _res.data.msg, 'success').then(async () => await GetDataQuizQuestion())

            } catch (err) {
                ErrorHandle(err)
                setIsLoading(false)
            }
        })
    }

    const submitQuizQuestion = async () => {

        if (watch('QuizQuestion').length === 0) return Swal.fire('แจ้งเตือน', 'ไม่พบข้อมูลคำถาม', 'warning')
        if (watch('Answer').length === 0) return Swal.fire('แจ้งเตือน', 'กรุณาเลือกคำตอบ', 'warning')

        let _rowAnswer = 0
        for await (const _ItemQuizQuestion of watch('QuizQuestion')) {
            _rowAnswer++
            const _ItemAnswer = watch('Answer')[_rowAnswer - 1].QuizQuestionAnswer.filter((item) => item.AnswerCorrect)
            if (_ItemAnswer.length <= 0) return Swal.fire('แจ้งเตือน', `กรุณาเลือกคำตอบ ข้อที่ ${_rowAnswer}`, 'warning')
        }

        const _ListQuizQuestion = watch('QuizQuestion').flatMap((item, idx) => {

            const _ListAnswer = watch('Answer')[idx].QuizQuestionAnswer.flatMap((_item, _idx) => {
                return {
                    UserAnswerID: _item.UserAnswerID,
                    AnswerID: _item.AnswerID,
                    AnswerName: _item.AnswerName,
                    AnswerCorrect: _item.AnswerCorrect ? 1 : 0,
                }
            })

            return {
                UserQuizQuestionID: item.UserQuizQuestionID,
                QuizQuestionID: item.QuizQuestionID,
                QuizQuestionTitle: item.QuizQuestionTitle,
                QuizQuestionType: item.QuizQuestionType,
                ListAnswer: _ListAnswer
            }
        })

        const _data = {
            CourseStudySubID: courseStudySubID,
            PostTest: 0,
            PreTest: 1,
            ListQuizQuestion: _ListQuizQuestion
        }

        Swal.fire({
            icon: 'question',
            title: `ยืนยันการส่งคำตอบ`,
            text: `คุณต้องการส่งคำตอบใช่หรือไม่`,
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
        }).then(async (result) => {

            if (!result.isConfirmed) return
            setBlockSubmit(true)
            try {

                setIsLoading(true)
                const _res = await http.post('UserQuizQuestion/SubmitQuizQuestion', _data)
                if (_res.status === 200 && _res.data.status) return Swal.fire('สำเร็จ', _res.data.msg, 'success').then(() => window.location.reload())
                // if (_res.status === 200 && _res.data.status) return Swal.fire('สำเร็จ', _res.data.msg, 'success').then(async () => await GetDataQuizQuestion())

            } catch (err) {
                ErrorHandle(err)
                setIsLoading(false)
            }
        })
    }

    const UpdateUserCourseStudySubAction = async () => {
        if (DataCourseStudySub.actionStatus > 0) return
        try {
            const res = await http.post(`UserCourseStudySub/UpdateActionStatus/${UserCourseStudySubID}`, { ActionStatus: 2 }, { headers: { 'Content-Type': 'multipart/form-data' } })
            //if (res.status === 200 && res.data.status) await GetCourseStudySub(res.data.courseStudyID)
        } catch (err) { ErrorHandle(err) }
    }

    const CheckCourseStudySub = async () => {
        Swal.fire({
            icon: 'question',
            title: `ยืนยันการเข้าเรียน`,
            text: `คุณต้องการเข้าเรียนใช่หรือไม่`,
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
        }).then(async (result) => {
            if (!result.isConfirmed) return
            const _res = await http.post(`CourseStudy/GetCourseStudySub/${courseStudySubID}`)
            if (_res.status === 200 && _res.data.status) {
                setModalLearning(!ModalLearning)
            }
        })
    }

    const CourseStudySubPostStudyTest = () => {

        if (isEmpty(courseStudySubID)) return

        Swal.fire({
            title: 'จะเข้าสู่บททดสอบหลังเรียนละน้า',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก'
        }).then(async (result) => {
            if (!result.isConfirmed) return
            window.location = '/PostTestExamination/' + courseStudySubID
        })
    }

    const PDFViews = async (File = null) => {

        if (isEmpty(File)) return

        setFiles(File)
        setModalViewPDF(true)
    }

    // console.log(watch())
    // console.log(watch('UserCourseStudyCompleteDate'))
    // console.log(isEmpty(watch('UserCourseStudyCompleteDate')))
    // console.log(DataCourseStudySub)
    // console.log(!isEmpty(DataCourseStudySub))
    // console.log(DataCourseStudySub.userCourseStudySubActionStatus)

    return (
        <React.Fragment>
            <Loading isLoading={isLoading} />
            <DialogModal
                Header={""}
                style={{ lineHeight: '1.0' }}
                Open={ModalPDF}
                OnHide={() => {
                    setModalPDF(!ModalPDF)
                }}
                Maximizable={true}
                Width={100}
                Height={100}
                Maximized={800}
                Content={
                    <React.Fragment>
                        <ViewLearningPDF
                            UserCourseStudySubID={UserCourseStudySubID}
                            setModal={setModalPDF}
                        />
                    </React.Fragment>
                }
            />
            <DialogModal
                Header={""}
                style={{ lineHeight: '1.0' }}
                Open={ModalViewPDF}
                OnHide={() => {
                    setModalViewPDF(!ModalViewPDF)
                    setFiles(null)
                }}
                Maximizable={true}
                Width={100}
                Height={100}
                Maximized={800}
                Content={
                    <React.Fragment>
                        <PDFView
                            File={Files}
                            setModal={setModalViewPDF}
                        />
                    </React.Fragment>
                }
            />
            <DialogModal
                Header={""}
                style={{ lineHeight: '1.0' }}
                Open={ModalVideo}
                OnHide={() => setModalVideo(!ModalVideo)}
                Maximizable={true}
                Width={100}
                Height={100}
                Maximized={800}
                Content={
                    <React.Fragment>
                        <ViewLearningVideo
                            UserCourseStudySubID={UserCourseStudySubID}
                            setModal={setModalVideo}
                        />
                    </React.Fragment>
                }
            />
            <DialogModal
                Header={""}
                style={{ lineHeight: '1.0' }}
                Open={ModalLearning}
                OnHide={() => {
                    setModalLearning(!ModalLearning)
                    GetDataQuizQuestion()
                }}
                Maximizable={true}
                Width={100}
                Height={100}
                Maximized={800}
                Content={
                    <React.Fragment>
                        <ViewLearning
                            UserCourseStudySubID={UserCourseStudySubID}
                            setModal={setModalLearning}
                        />
                    </React.Fragment>
                }
            />
            <div data-aos="fade-right">
                <Container>
                    <h5 className="mt-3 font-bold">คำชี้แจง (แบบทดสอบก่อนเรียน)</h5>
                    <div className="mt-2" >
                        <div className="flex flex-wrap align-items-start justify-content-start mt-1">
                            <div className="field col-6 md:col-6 justify-content-start">
                                <label className="m-0 text-lg block mb-2 text-left"><p className="text-break" dangerouslySetInnerHTML={{ __html: !isEmpty(DataCourseStudySub) ? DataCourseStudySub.detail : null }} /></label>
                            </div>
                            <div className="field flex-column col-6 md:col-6 justify-content-end">
                                <div className="flex justify-content-end align-items-end">
                                    <label className="m-0 font-semibold text-lg block mb-2 text-right">จำนวนข้อที่ถูก <Badge value={DataUserQuizQuestion.preTestCorrectScore} severity="success"></Badge></label>
                                </div>
                                <div className="flex justify-content-end align-items-end">
                                    <label className="m-0 font-semibold text-lg block mb-2 text-right">จำนวนข้อที่ผิด <Badge value={DataUserQuizQuestion.preTestWrongScore} severity="danger"></Badge></label>
                                </div>
                                <div className="flex justify-content-end align-items-end">
                                    <label className="m-0 font-semibold text-lg block mb-2 text-right">รวมคะแนน <Badge value={DataUserQuizQuestion.totalScore}></Badge></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        DataUserQuizQuestion.preTestStatus > 0 &&
                        <div className="flex flex-wrap align-items-start justify-content-start mt-1">
                            <div className="field col-12 md:col-12">
                                <label className="m-0 font-semibold text-lg block mb-2">สถานะการทำแบบทดสอบ : <span className="text-green-500">{DataUserQuizQuestion.preTestStatus === 1 ? 'อยู่ระหว่างทำแบบทดสอบ' : 'ส่งคำตอบสำเร็จ'}</span></label>
                            </div>
                        </div>
                    }
                    <hr />
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {
                                fields.length > 0 ? fields.map((item, index) => {
                                    const _row = index + 1
                                    return (
                                        <div className="flex flex-column card-container m-2" key={item.id}>
                                            <Row>
                                                <Col md={10} className="text-left">
                                                    <h6 dangerouslySetInnerHTML={{ __html: `คำถามข้อที่ ${_row} : ${ReplaceNewLine(item.QuizQuestionTitle)}` }}></h6>
                                                </Col>
                                                <Col md={2} className="text-right">
                                                    <span>{item.QuizQuestionScore} คะแนน </span>
                                                </Col>
                                                <Col md={12} className="mt-1">
                                                    {
                                                        isEmpty(item.Fileupload) ? '' :
                                                            item.FileType !== 'application/pdf' ?
                                                                <Preview FilesType={item.FileType} Files={item.Fileupload} Width={600} Preview={true} />
                                                                :
                                                                <Button
                                                                    type="button"
                                                                    icon="pi pi-file-pdf"
                                                                    className="p-button-raised p-button-sm mt-3 mr-2 w-12rem"
                                                                    label="ดูไฟล์แนบPDF"
                                                                    title="ดูไฟล์แนบPDF"
                                                                    onClick={() => PDFViews(item.Fileupload)}
                                                                />
                                                    }
                                                </Col>
                                                <Col md={12} className="mt-1">
                                                    <QuizQuestionAnswer
                                                        control={control}
                                                        watch={watch}
                                                        register={register}
                                                        setValue={setValue}
                                                        errors={errors}
                                                        idx={index}
                                                        getValues={getValues}
                                                        DataQuestionAnswerList={DataQuestionAnswerList}
                                                    />
                                                    <hr />
                                                    {/* {
                                                            item.QuizQuestionType === 1 ?
                                                                <QuizQuestionAnswer
                                                                    control={control}
                                                                    watch={watch}
                                                                    register={register}
                                                                    setValue={setValue}
                                                                    errors={errors}
                                                                    idx={index}
                                                                    getValues={getValues}
                                                                    DataQuestionAnswerList={DataQuestionAnswerList}
                                                                />
                                                                :
                                                                <QuizQuestionAnswers
                                                                    control={control}
                                                                    watch={watch}
                                                                    register={register}
                                                                    setValue={setValue}
                                                                    errors={errors}
                                                                    idx={index}
                                                                    getValues={getValues}
                                                                    DataQuestionAnswerList={DataQuestionAnswerList}
                                                                />
                                                        } */}
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                })
                                    : !isLoading &&
                                    <div className="flex flex-column card-container m-2">
                                        <Row>
                                            <Col md={12} className="text-center">
                                                <h5 className="text-danger">ไม่พบข้อมูลคำถาม</h5>
                                            </Col>
                                        </Row>
                                    </div>
                            }
                            <div className="flex flex-column justify-content-center card-container">
                                <div className="flex align-items-center justify-content-center m-1">
                                    <div className="text-center">
                                        <Button
                                            type="button"
                                            icon="pi pi-angle-left"
                                            className="p-button-raised p-button-sm mt-3 mr-2 p-button-danger w-12rem"
                                            label="ย้อนกลับ"
                                            // onClick={() => RedirectTo(-1)}
                                            onClick={() => (watch(`UserCourseStudySubActionStatus`) === 1 && watch(`UserCourseStudyActionStatus`) === 2) ? RedirectTo(`/Learn/${watch('CourseStudyID')}/2`) : RedirectTo(`/Learn/${watch('CourseStudyID')}/1`)}
                                        />
                                        <Button
                                            type="button"
                                            icon="pi pi-home"
                                            className="p-button-raised p-button-sm mt-3 mr-2 w-12rem"
                                            label="หน้าหลัก"
                                            onClick={() => RedirectTo('/')}
                                        />
                                        {
                                            !isEmpty(DataCourseStudySub) && DataCourseStudySub.preTestStatus < 2 && watch('UserCourseStudyActionStatus') < 2 && isEmpty(watch('UserCourseStudyCompleteDate')) &&
                                            <>
                                                <Button type="submit" icon="pi pi-save" label="บันทึกคำตอบ" title="บันทึกคำตอบ" disabled={BlockSubmit} className="p-button-raised p-button-info p-button-sm mt-3 mr-2 w-12rem" />
                                                <Button type="button" icon="pi pi-check-circle" label="ส่งคำตอบ" title="ส่งคำตอบ" disabled={BlockSubmit} onClick={submitQuizQuestion} className="p-button-raised p-button-success p-button-sm mt-3 mr-2 w-12rem" />
                                            </>
                                        }
                                        {
                                            !isEmpty(DataCourseStudySub) && DataCourseStudySub.preTestStatus > 1 &&
                                            <>
                                                {
                                                    DataCourseStudySub.userCourseStudySubActionStatus < 2 && <Button type="button" icon="pi pi-pencil" label="เข้าสู่บทเรียนถัดไป" title="เข้าสู่บทเรียนถัดไป" onClick={CheckCourseStudySub} className="p-button-raised p-button-success p-button-sm mt-3 mr-2 w-12rem" />
                                                }
                                                {
                                                    DataCourseStudySub.userCourseStudySubActionStatus === 2 && DataCourseStudySub.postStudyTest === 1 &&
                                                    <Button
                                                        type="button"
                                                        label="สอบหลังเรียน"
                                                        title="สอบหลังเรียน"
                                                        className="p-button-raised p-button-success p-button-sm mt-3 mr-2 w-12rem"
                                                        onClick={CourseStudySubPostStudyTest}
                                                    />
                                                }
                                                {
                                                    DataCourseStudySub.actionStatus === 2 && <Button type="button" icon="pi pi-chevron-right" label="ไปบทเรียนถัดไป" className="p-button-raised p-button-warning p-button-sm mt-3 mr-2 w-12rem" />
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                </Container>
            </div>
        </React.Fragment>
    )
}

const QuizQuestionAnswer = ({ control, watch, register, setValue, errors, DataQuestionAnswerList, idx }) => {

    const { fields } = useFieldArray({ control, name: `Answer.${idx}.QuizQuestionAnswer` })

    const [selectedKey, setSelectedKey] = useState(null)
    const handleChangeAnswerCorrect = (value, row) => {

        const _ListQuizQuestion = watch('Answer')
        if (_ListQuizQuestion.length === 0) return

        const _ListQuizQuestionAnswer = _ListQuizQuestion[row].QuizQuestionAnswer
        for (const _Item of _ListQuizQuestionAnswer) {
            if (_Item.AnswerName === value)
                _Item.AnswerCorrect = true
            else
                _Item.AnswerCorrect = false
        }

        setValue(`Answer.${idx}.QuizQuestionAnswer`, _ListQuizQuestionAnswer)
    }

    return (
        <React.Fragment>
            {
                fields.flatMap((_Item, _idx) => {
                    const _row = _idx + 1
                    return (
                        <React.Fragment key={_Item.id}>
                            <div className="col-12 md:col-12" style={{ display: 'flex' }}>
                                <Controller
                                    control={control}
                                    name={`Answer.${idx}.QuizQuestionAnswer.${_idx}.AnswerCorrect`}
                                    render={({ field }) => (
                                        <React.Fragment>
                                            <RadioButton
                                                // {...field}
                                                inputId={'AnswerCorrect-' + _Item.id}
                                                value={_Item.AnswerName}
                                                checked={_Item.AnswerCorrect ? _Item.AnswerCorrect : _Item.AnswerName === selectedKey}
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                    setSelectedKey(e.target.value)
                                                    handleChangeAnswerCorrect(e.target.value, idx)
                                                }}
                                                //disabled={watch(`QuizQuestion.${idx}.ActionStatus`) > 1 ? true : false}
                                                disabled={_Item.ActionStatus > 0 ? true : false}
                                            />
                                        </React.Fragment>
                                    )}
                                />
                                {/* {
                                    //_Item.Correct === 0 && watch(`QuizQuestion.${idx}.ActionStatus`) === 0 ?
                                    _Item.Correct === 0 && _Item.ActionStatus === 0 ?
                                        <label htmlFor={uuidv4()} className={`ml-2`}>{_Item.AnswerName}</label>
                                        :
                                        <label htmlFor={uuidv4()} className={`ml-2 ${_Item.AnswerCorrect && _Item.Correct === 1 && _Item._Correct ? 'text-green-600' : _Item.Correct === 2 && !_Item._Correct || !_Item.AnswerCorrect ? 'text-red-600' : ''}`}>{_Item.AnswerName} {_Item.AnswerCorrect && _Item.Correct === 1 && _Item._Correct ? <i className="pi pi-check text-green-600" /> : _Item.Correct === 2 && !_Item._Correct || !_Item.AnswerCorrect ? <i className="pi pi-times text-red-600" /> : ''}</label>
                                } */}
                                <p className="ml-2 font-light" dangerouslySetInnerHTML={{ __html: ReplaceNewLine(_Item.AnswerName) }}></p>
                            </div>
                        </React.Fragment>
                    )
                })
            }
        </React.Fragment>
    )
}

const QuizQuestionAnswers = ({ control, watch, register, setValue, errors, DataQuestionAnswerList, idx }) => {

    const { fields } = useFieldArray({ control, name: `Answer.${idx}.QuizQuestionAnswer` })
    const [selectedKey, setSelectedKey] = useState(null)
    const handleChangeAnswerCorrect = (value, row) => {

        const _ListQuizQuestion = watch('Answer')
        if (_ListQuizQuestion.length === 0) return

        const _ListQuizQuestionAnswer = _ListQuizQuestion[row].QuizQuestionAnswer
        for (const _Item of _ListQuizQuestionAnswer) {
            if (_Item.AnswerCorrect)
                _Item.AnswerCorrect = true
            else
                _Item.AnswerCorrect = false
        }

        setValue(`Answer.${idx}.QuizQuestionAnswer`, _ListQuizQuestionAnswer)
    }

    return (
        <React.Fragment>
            {
                fields.flatMap((_Item, _idx) => {
                    const _row = _idx + 1
                    return (
                        <div className="col-12 md:col-12 mt-2" key={_Item.id}>
                            <Controller
                                control={control}
                                name={`Answer.${idx}.QuizQuestionAnswer.${_idx}.AnswerCorrect`}
                                render={({ field }) => (
                                    <Checkbox
                                        title={_Item.AnswerName}
                                        inputId={'AnswerCorrect-' + _Item.id}
                                        value={true}
                                        // checked={_Item.AnswerCorrect ? _Item.AnswerCorrect : watch(`Answer.${idx}.QuizQuestionAnswer.${_idx}.AnswerCorrect`)}
                                        checked={watch(`Answer.${idx}.QuizQuestionAnswer.${_idx}.AnswerCorrect`)}
                                        onChange={(d) => {
                                            field.onChange(d)
                                            handleChangeAnswerCorrect(d.target.checked, idx)
                                            setValue(`Answer.${idx}.QuizQuestionAnswer.${_idx}.AnswerCorrect`, d.target.checked ? true : false)
                                        }}
                                    />
                                )}
                            />
                            <label htmlFor={uuidv4()} className="ml-2">{_Item.AnswerName}</label>
                        </div>
                    )
                })
            }
        </React.Fragment>
    )
}

export default React.memo(PreTestExamination)