import React, { useRef } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation, Autoplay } from "swiper"
import { http, isEmpty, ErrorHandle } from "../../Utils"
import { v4 as uuidv4 } from 'uuid'

import './style.css'
import Preview from "../../Components/Previews"

const Banner = ({ isLogin }) => {

    const [DataBanner, setDatabanner] = React.useState([])
    const [BannerHeight, setBannerHeight] = React.useState(0)
    const [BannerWidth, setBannerWidth] = React.useState(0)
    const [BannerLimit, setBannerLimit] = React.useState(0)
    const [BannerShow, setBannerShow] = React.useState(0)
    const [isLoading, setIsLoading] = React.useState(false)

    React.useEffect(() => {
        (async () => {
            await GetDataBanner()
            await GetConfigBanner()
        })()
    }, [isLogin])

    const GetDataBanner = async () => {
        try {
            const res = await http.post("/Banner/FindList?Limit=10")
            if (res.status === 200 && res.data.status) {
                setDatabanner(res.data.data)
                setBannerLimit(res.data.limit)
            }
        } catch (err) {
            ErrorHandle(err)
        }
    }

    const GetConfigBanner = async () => {
        try {
            const res = await http.get("/Config/GetConfigBanner")
            if (res.status === 200 && res.data.status) {
                setBannerHeight(res.data.data.bannerHeight)
                setBannerWidth(res.data.data.bannerWidth)
                setBannerShow(res.data.data.bannerShow)
            }
        } catch (err) {
            ErrorHandle(err)
        }
    }

    return (
        <React.Fragment>
            <div data-aos="fade-right">
                <Swiper
                    // slidesPerView={3}
                    slidesPerView={DataBanner.length > 0 ? BannerShow : 1}
                    spaceBetween={10}
                    slidesPerGroup={1}
                    autoplay={{
                        delay: 10000,
                        disableOnInteraction: true,
                    }}
                    loopFillGroupWithBlank={true}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation, Autoplay]}
                    className="mySwiper mt-2"
                    loop={true}
                >
                    {
                        DataBanner.length > 0 ? DataBanner.map((item, index) => {
                            return (
                                <SwiperSlide key={item.bannerID}>
                                    <img
                                        src={!isEmpty(item.fileUpload) ? item.fileUpload : "/showcase/images/SF_Cinema_logo.jpg"}
                                        width={BannerWidth + "%"}
                                        height={BannerHeight + "%"}
                                        onContextMenu={(e) => e.preventDefault()}
                                    />
                                </SwiperSlide>
                            )
                        }) :
                            <SwiperSlide>
                                <img src="\showcase\pop e-learning-04.png" width={500} height={150}></img>
                            </SwiperSlide>
                    }
                </Swiper>
            </div>
        </React.Fragment>
    )
}

export default React.memo(Banner)