import React from "react"
import { useForm } from 'react-hook-form'
import { Container, Row, Col, Card as Cards, Form } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { http, stripHtml, isEmpty, truncateText, ErrorHandle } from "../../Utils"
import { Paginator } from 'primereact/paginator'
import { Dropdown } from 'primereact/dropdown'

import 'primeflex/primeflex.css'
import './style.css'

const TextNowrap = {
    whiteSpace: 'pre-line',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '15px',
    height: '2.5rem',
    width: '100%',
    minWidth: '1px',
}

const TitleTextNowrap = {
    whiteSpace: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '45px',
    width: '100%',
    minWidth: '1px',
}

const ContentUser = () => {

    const { register, handleSubmit, watch, setValue, reset } = useForm()

    const [DataAllssons, setDataAllssons] = React.useState([])
    const [isLoad, setIsLoad] = React.useState(false)
    const [Limit, setLimit] = React.useState(20)
    const [Offset, setOffset] = React.useState(0)
    const [TotalRecords, setTotalRecords] = React.useState(0)

    React.useEffect(() => {

        (async () => await GetDataAllssons())()

    }, [])

    const GetDataAllssons = async () => {
        try {

            setIsLoad(true)
            const res = await http.post("/CourseStudy/FindListCourseStudyShowAll", { Search: '', Type: 99, Offset: Offset, Limit: Limit }, { headers: { "Content-Type": "multipart/form-data" } })
            if (res.status === 200 && res.data.status) {
                setDataAllssons(res.data.data)
                setIsLoad(false)
                setTotalRecords(res.data.total)
            }

        } catch (err) {
            reset()
            setIsLoad(false)
            ErrorHandle(err)
        }
    }

    const LoadMore = async (offset = 1) => {

        try {
            setIsLoad(true)
            const _res = await http.post('/CourseStudy/FindListCourseStudyShowAll', { Search: '', Type: !isEmpty(watch('type')) ? watch('type') : 99, Offset: offset, Limit: Limit }, { headers: { "Content-Type": "multipart/form-data" } })
            if (_res.status === 200 && _res.data.status) {
                setDataAllssons(_res.data.data)
                setTotalRecords(_res.data.total)
                setIsLoad(false)
                setOffset(offset)
                setLimit(Limit)
            }

        } catch (err) {
            reset()
            setIsLoad(false)
            ErrorHandle(err)
        }
    }

    const _options = [
        { name: 'ทั้งหมด', code: 99 },
        { name: 'คอร์สที่ยังไม่ได้เรียน', code: 0 },
        { name: 'คอร์สที่กำลังเรียน', code: 1 },
        { name: 'คอร์สที่เรียนสำเร็จ', code: 2 }
    ]

    const onFilter = async (data) => {

        if (isEmpty(data)) return
        setValue('type', +data)

        try {
            setIsLoad(true)
            const _res = await http.post('/CourseStudy/FindListCourseStudyShowAll', { Search: '', Type: +data, Offset: Offset, Limit: Limit }, { headers: { "Content-Type": "multipart/form-data" } })
            if (_res.status === 200 && _res.data.status) {
                setDataAllssons(_res.data.data)
                setTotalRecords(_res.data.total)
                setIsLoad(false)
            }
        } catch (err) {
            reset()
            setIsLoad(false)
            ErrorHandle(err)
        }
    }

    return (
        <React.Fragment>
            {/* <Dropdown
                value={!isEmpty(watch('type')) ? watch('type') : _options[0]}
                onChange={(e) => onFilter(e.value)}
                options={_options}
                optionLabel="name"
                // placeholder="เลือกรายการ"
                className="md:w-14rem ml-2"
            /> */}
            <Container>
                {/* <Row className="mt-2">
                    <div className="d-flex justify-content-between">
                        <h3 className="mt-3">
                            {
                                !isEmpty(watch('type')) && watch('type') === 99 ? 'คอร์สเรียนทั้งหมด' :
                                    watch('type') === 0 ? 'คอร์สที่ยังไม่ได้เรียน' :
                                        watch('type') === 1 ? 'คอร์สที่กำลังเรียน' :
                                            watch('type') === 2 ? 'คอร์สที่เรียนสำเร็จ' : 'คอร์สเรียนทั้งหมด'
                            }
                            {" " + `(${!isLoad ? TotalRecords : 0})`}
                        </h3>
                        <Form.Group className="md:w-14rem mt-3">
                            <Form.Select onChange={(e) => onFilter(e.target.value)}>
                                {
                                    _options.map((item, index) => (<option key={index + item.code} value={item.code}>{item.name}</option>))
                                }
                            </Form.Select>
                        </Form.Group>

                    </div>
                </Row> */}
                <Row className="mt-2">
                    <Col md={9} className="float-left">
                        <h3 className="mt-3">
                            {
                                !isEmpty(watch('type')) && watch('type') === 99 ? 'คอร์สเรียนทั้งหมด' :
                                    watch('type') === 0 ? 'คอร์สที่ยังไม่ได้เรียน' :
                                        watch('type') === 1 ? 'คอร์สที่กำลังเรียน' :
                                            watch('type') === 2 ? 'คอร์สที่เรียนสำเร็จ' : 'คอร์สเรียนทั้งหมด'
                            }
                            {" " + `(${!isLoad ? TotalRecords : 0})`}
                        </h3>
                    </Col>
                    <Col md={3} className="float-right">
                        <Form.Group className="mt-3">
                            <Form.Select onChange={(e) => onFilter(e.target.value)}>
                                {_options.map((item, index) => (<option key={index + item.code} value={item.code}>{item.name}</option>))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                {
                    isLoad ?
                        <Loading />
                        :
                        <Row className="mt-2">
                            {
                                DataAllssons.length > 0 && DataAllssons.map((item, index) => {
                                    const _Title = !isEmpty(item.title) ? stripHtml(item.title) : item.title
                                    const _Detail = !isEmpty(item.detail) ? stripHtml(item.detail).replace(/\s/g, '') : item.detail
                                    const _Advice = !isEmpty(item.advice) ? stripHtml(item.advice).replace(/\s/g, '') : item.advice
                                    return (
                                        <Col md={3} key={item.courseStudyID} className="m-0 mb-3 p-1">
                                            <div className="card">
                                                <div className="card-head">
                                                    <Link to={`/Learn/${item.courseStudyID}/${watch('type') === 2 ? 2 : 1}`} className="p-button-info">
                                                        <Cards.Img variant="top" src={!isEmpty(item.fileUpload) ? item.fileUpload : window.location.origin + '/showcase/600x400SF.jpg'} />
                                                    </Link>
                                                </div>
                                                <div className="card-body" style={{ height: '10rem', width: '100%', float: 'left' }}>
                                                    {/* <Cards.Title className="font-weight-bold">{!isEmpty(_Title) ? truncateText(_Title, 40) : 'ไม่พบเนื้อหา'}</Cards.Title> */}
                                                    <Cards.Title as="h5" className="font-bold" style={TitleTextNowrap}>{!isEmpty(_Title) ? _Title : ''}</Cards.Title>
                                                    <p className="mt-3" style={TextNowrap} dangerouslySetInnerHTML={{ __html: !isEmpty(item.detail) ? item.detail : 'ไม่พบเนื้อหา' }}></p>
                                                </div>
                                                <div className="p-1 m-0" style={{ height: '2rem', width: '100%', float: 'left' }}>
                                                    <span className="text-nowraps ml-2" style={{ height: '2.5rem', fontSize: '14px' }}>คอร์สนี้เหมาะสำหรับ {!isEmpty(_Advice) ? truncateText(stripHtml(_Advice).replace(/\s/g, ' '), 25) : <br />}</span>
                                                    {isEmpty(_Advice) && <small className="text-muted"><br /></small>}
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                }
                <div className="flex align-items-center justify-content-center m-2">
                    <div className="p-0">
                        <Paginator first={Offset} rows={Limit} totalRecords={TotalRecords} onPageChange={(f) => {
                            setOffset(f.first)
                            setLimit(f.rows)
                            LoadMore(f.first)
                        }} template={{ layout: 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink' }} />
                    </div>
                </div>
            </Container>
        </React.Fragment>
    )
}

const Loading = () => {
    return (
        <div className="text-center">
            <h5 className="text-red-600">กรุณารอสักครู่.....</h5>
        </div>
    )
}

export default React.memo(ContentUser)