import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { http, isEmpty, ErrorHandle } from '../../Utils'
import { TabView, TabPanel } from 'primereact/tabview'
import { Button } from 'primereact/button'
import { useParams } from 'react-router-dom'
import { Chart } from 'primereact/chart'
import { Card } from 'primereact/card'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import './style.css'

const CourseProgress = () => {

    const { courseStudyID, Type } = useParams()

    const RedirectTo = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [isLoad, setisLoad] = React.useState(false)

    const [DataProfileTitle, setDataProfileTitle] = React.useState({})
    const [DataCourseStudySub, setDataCourseStudySub] = React.useState([])
    const [DataCourseStudy, setDataCourseStudy] = React.useState()

    const methods = useForm()

    const { register, handleSubmit, formState: { errors }, setValue, getValues, watch, control, reset } = methods

    const [activeIndex, setActiveIndex] = useState(0)

    const [chartDataOverView, setChartDataOverView] = useState({})
    const [chartOptions, setChartOptions] = useState({})
    const [chartOptionsOverView, setChartOptionsOverView] = useState({})
    const [PersentScore, setPersentScore] = useState(0)

    React.useEffect(() => {

        // if (isEmpty(courseStudyID) || isEmpty(Type) || (!isEmpty(Type) && +Type !== 1 && +Type !== 2)) {
        //     Swal.fire('แจ้งเตือน', 'ไม่พบข้อมูลที่ต้องการ', 'warning').then((res) => RedirectTo('/History'))
        //     return
        // }

        (async () => await GetCourseStudy())()

        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: ['ตอบถูก', 'ตอบผิด', 'ไม่ได้ตอบ'],
            datasets: [
                {
                    data: [540, 325, 702],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--green-500'),
                        documentStyle.getPropertyValue('--red-500'),
                        documentStyle.getPropertyValue('--yellow-500')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--green-400'),
                        documentStyle.getPropertyValue('--red-400'),
                        documentStyle.getPropertyValue('--yellow-400')
                    ]
                }
            ]
        }
        const options = {
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true
                    }
                }
            }
        };

        setChartOptions(options)

    }, [courseStudyID])

    const GetdataChartOverView = async (UserCourseStudyID) => {

        let _Title = []
        let _FullScore = []
        let _ScoreCorect = []
        let _ScoreInCorect = []

        const _res = await http.post(`UserCourseStudy/ReportUserCourseStudyCharBar/${UserCourseStudyID}`, { headers: { 'Content-Type': 'multipart/form-data' } })
        if (_res.status === 200 && _res.data.status) {
            if (isEmpty(_res.data.data)) return
            _Title = _res.data.data.listTitle;
            _FullScore = _res.data.data.listUserCourseStudyScore
            _ScoreCorect = _res.data.data.listQuizQuestionCorrect
            _ScoreInCorect = _res.data.data.listQuizQuestionInCorrect
        }

        const documentStyle = getComputedStyle(document.documentElement)
        const textColor = documentStyle.getPropertyValue('--text-color')
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary')
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border')

        const data_OverView = {
            labels: _Title,
            datasets: [
                {
                    label: 'คะแนนรวม',
                    backgroundColor: '#a6a6a3',
                    borderColor: '#a6a6a3',
                    data: _FullScore
                },
                {
                    label: 'คะแนนรวมข้อถูก',
                    backgroundColor: '#4067a8',
                    borderColor: '#4067a8',
                    data: _ScoreCorect
                },
                {
                    label: 'คะแนนรวมข้อผิด',
                    backgroundColor: '#eca637',
                    borderColor: '#eca637',
                    data: _ScoreInCorect
                }
            ]
        }

        const options_OverView = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        fontColor: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500
                        }
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };

        setChartDataOverView(data_OverView)
        setChartOptionsOverView(options_OverView)
    }

    const GetCourseStudy = async () => {
        try {
            setIsLoading(true)
            const _res = await http.post(`CourseStudy/FindByHistoryCourseStudySub/${courseStudyID}`)
            // console.log(_res.data.data)
            if (_res.status === 200 && _res.data.status) {
                setDataCourseStudy(_res.data.data)
                await GetCourseStudySub(courseStudyID)
                await GetdataChartOverView(_res.data.data.userCourseStudtID);
            }
        } catch (err) {
            ErrorHandle(err)
            setIsLoading(false)
        }
    }

    const GetCourseStudySub = async (CourseStudyID) => {
        try {
            setisLoad(true)
            const res = await http.post(`UserCourseStudySub/FindByList/${CourseStudyID}`)
            if (res.status === 200 && res.data.status) {
                setDataCourseStudySub(res.data.data)
                let _userCourseStudySubID = [];

                if (res.data.data.length > 0) {
                    for await (const _Item of res.data.data) {
                        _userCourseStudySubID.push(_Item.userCourseStudySubID)
                        GetDataChart(_Item.userCourseStudySubID)
                    }
                }
                setisLoad(false)
            }
        } catch (err) {
            setisLoad(false)
            ErrorHandle(err)
        }
    }

    const GetDataChart = async (userCourseStudySubID) => {
        // console.log(`userCourseStudySubID`, userCourseStudySubID)
        const _res = await http.post(`UserCourseStudySub/CourseProgress/${userCourseStudySubID}`, { headers: { 'Content-Type': 'multipart/form-data' } })
        if (_res.status === 200 && _res.data.status) {
            // console.log(`_res`, _res.data.data)
            if (isEmpty(_res.data.data)) return
            setValue(`DataChart[${userCourseStudySubID}]`, [_res.data.data.postTestCorrectScore, _res.data.data.postTestWrongScore, 0])
            setValue(`TotalScore[${userCourseStudySubID}]`, _res.data.data.totalScore)
            setValue(`FullScore[${userCourseStudySubID}]`, _res.data.data.fullScore)
            setValue(`PersentScore[${userCourseStudySubID}]`, _res.data.data.persentScore)
            setValue(`_PersentScore[${userCourseStudySubID}]`, _res.data.data._PersentScore)
        }
    }

    const ReportUserCourseStudyCharBar = async (UserCourseStudySubID) => {

        if (isEmpty(UserCourseStudySubID)) return

        try {

            const _res = await http.post(`UserCourseStudy/ReportUserCourseStudyCharBar/${UserCourseStudySubID}`)
            if (_res.status === 200 && _res.data.status) {
                // console.log(_res.data.data)
            }

        } catch (err) { ErrorHandle(err) }
    }

    return (
        <React.Fragment>
            <Card className="mt-3 m-2 shadow-5 fadein" title={!isEmpty(DataCourseStudy) ? `ชื่อบทเรียน ${DataCourseStudy.title}` : ''}>
                <div className="grid">
                    <div className="col-8 md:col-8 lg:col-8 p-2">
                        <TabView
                            scrollable={DataCourseStudySub.length > 10 ? true : false}
                            activeIndex={activeIndex}
                            onTabChange={(e) => setActiveIndex(e.index)}
                        >
                            {
                                isLoad ? <Loading /> : DataCourseStudySub.length > 0 ? DataCourseStudySub.flatMap((item, index) => {

                                    const documentStyle = getComputedStyle(document.documentElement)
                                    const _Data = watch(`DataChart[${item.userCourseStudySubID}]`)
                                    const _TotalScore = watch(`TotalScore[${item.userCourseStudySubID}]`)
                                    const _FullScore = watch(`FullScore[${item.userCourseStudySubID}]`)
                                    const _PersentScore = watch(`_PersentScore[${item.userCourseStudySubID}]`)

                                    const data = {
                                        labels: ['ตอบถูก', 'ตอบผิด'],
                                        datasets: [
                                            {
                                                data: _Data,
                                                backgroundColor: [
                                                    '#4067a8',
                                                    '#eca637',
                                                    // documentStyle.getPropertyValue('--green-500'),
                                                    // documentStyle.getPropertyValue('--red-400'),
                                                    // documentStyle.getPropertyValue('--yellow-500')
                                                ],
                                                hoverBackgroundColor: [
                                                    '#4067a8',
                                                    '#eca637',
                                                    // documentStyle.getPropertyValue('--green-400'),
                                                    // documentStyle.getPropertyValue('--red-400'),
                                                    // documentStyle.getPropertyValue('--yellow-400')
                                                ]
                                            }
                                        ]
                                    }

                                    if (activeIndex === index && item.postTestStatus === 2 && item.postStudyTest === 1) {
                                        // console.log(`item`, item)
                                        // GetDataChart(item.userCourseStudySubID)
                                        if (item.postTestStatus !== 2 && item.postStudyTest !== 1) {
                                            // console.log(`ActiveIndex`, index + 1)
                                            // setActiveIndex(index + 1);
                                        }
                                    }

                                    let _CheckSuccess = false
                                    switch (+item.actionStatus) {
                                        case 0:
                                        case 1:
                                            break
                                        case 2:
                                            if (item.preStudyTest === 0 && item.postStudyTest === 0) {
                                                _CheckSuccess = true
                                            } else {
                                                if (item.postStudyTest === 1 && item.postTestStatus === 2) {
                                                    _CheckSuccess = true
                                                }
                                            }
                                            break
                                    }

                                    return (
                                        !isEmpty(_Data) ?
                                            <TabPanel
                                                key={item.userCourseStudySubID}
                                                header={`บทที่ ${item.episode}`}
                                                disabled={item.postTestStatus === 2 && item.postStudyTest === 1 ? false : true}
                                            >
                                                <div className="flex flex-column card-container gap-1">
                                                    <div className="flex align-items-center justify-content-center m-2">
                                                        <h1 className="text-center">{`ชื่อบทเรียน ${item.title}`}</h1>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center m-2">
                                                        <h3 className="text-center">ผลคะแนนของคุณ</h3>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center m-2">
                                                        <h1 className="text-center">{_PersentScore} %</h1>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center m-2">
                                                        <h5 className="text-center">ทำได้ {+_TotalScore} / {+_FullScore} คะแนน</h5>
                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap justify-content-center gap-8 w-full">
                                                    <div className="flex align-items-center justify-content-center shadow">
                                                        <Card title="วิเคราะห์ผลคะแนน">
                                                            <p className="text-green">ตอบถูก : {_Data[0]} ข้อ</p>
                                                            <p className="text-red">ตอบผิด : {_Data[1]} ข้อ</p>
                                                        </Card>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center">
                                                        {
                                                            activeIndex === index && <Chart type="pie" style={{ width: '100%' }} data={data} options={chartOptions} className="w-13rem" />
                                                        }
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            :
                                            <TabPanel
                                                header={`บทที่ ${item.episode}`}
                                                key={item.userCourseStudySubID}
                                            >
                                                <h1 className="text-center">{`ชื่อบทเรียน ${item.title}`}</h1>
                                                <h3 className="text-center">ไม่มีข้อสอบ</h3>
                                            </TabPanel>
                                    )
                                }) : <div className="col-12 text-center">ไม่พบข้อมูล</div>
                            }
                        </TabView>
                    </div>
                    <div className="col-4 md:col-4 lg:col-4 p-2">
                        <div className="row">
                            <div className="table-responsive mt-3">
                                <table className="table table-hover">
                                    <thead style={{ backgroundColor: '#7B7B7B' }}>
                                        <tr>
                                            <th colSpan={20}>
                                                <h5 className="text-light mt-2">บทเรียนเรื่อง {DataProfileTitle.title}</h5>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="overflow-scroll">
                                        {
                                            DataCourseStudySub.length > 0 ? DataCourseStudySub.flatMap((item, index) => {
                                                // item.checkExpireDate = false
                                                let _CheckSuccess = false
                                                switch (+item.actionStatus) {
                                                    case 0:
                                                    case 1:
                                                        break
                                                    case 2:
                                                        if (item.preStudyTest === 0 && item.postStudyTest === 0) {
                                                            _CheckSuccess = true
                                                        } else {
                                                            if (item.postStudyTest === 1 && item.postTestStatus === 2) {
                                                                _CheckSuccess = true
                                                            }
                                                        }
                                                        break
                                                }

                                                return (
                                                    <React.Fragment key={item.userCourseStudySubID}>
                                                        <tr onClick={() => {
                                                            if (!_CheckSuccess) return
                                                            setActiveIndex(index)
                                                        }}>
                                                            <th colSpan={100} className={`m-0 p-3 cursor-pointer ${activeIndex === index ? `active` : ''}`}>
                                                                <div className="row">
                                                                    <div className="col-md-8 text-left">
                                                                        {_CheckSuccess ? <img src="\showcase\accept.png" width={20} height={20} title="ผ่าน" /> : <img src="\showcase\icon\open-book.png" alt="Card" width={20} height={20} />}
                                                                        {`  บทที่ ${item.episode} ชื่อบทเรียน ${item.title}`}
                                                                    </div>
                                                                    <div className="col-md-4 text-right">
                                                                        {
                                                                            // item.actionStatus === 2 && (item.preStudyTest === 0 || item.postStudyTest === 0) || (item.preStudyTest === 1 && item.postStudyTest === 1 && item.postTestStatus === 2) || (item.postStudyTest === 1 && item.postStudyTestStatus === 2) ?
                                                                            // <React.Fragment>
                                                                            //     {
                                                                            //         // !isEmpty(item.courseStudySubFileUpload) && item.courseStudySubFileUpload.filetype === 'application/pdf' ?
                                                                            //         !isEmpty(item.courseStudySubFileUpload) && item.courseStudySubFileUpload.filetype === 'application/pdf' && item.typeFile === 1 ?
                                                                            //             <img src="\showcase\icon\upload.png" title="บทเรียนแบบ pdf" className="cursor-pointer" alt="Card" width={20} height={20} onClick={() => CheckCourseStudy(item, index)} />
                                                                            //             :
                                                                            //             // !isEmpty(item.courseStudySubFileUpload) && !CheckImageType(item.courseStudySubFileUpload.filetype) && item.typeFile === 99 ?
                                                                            //             !isEmpty(item.courseStudySubFile) && item.typeFile === 99 ?
                                                                            //                 <React.Fragment>
                                                                            //                     <img src="\showcase\icon\play-button.png" title="บทเรียนแบบ video" className="cursor-pointer" alt="Card" width={20} height={20} onClick={() => CheckCourseStudy(item, index)} />
                                                                            //                     {" "}{+item.duration > 0 && !isEmpty(item.courseStudySubFile) && item.typeFile === 99 ? <span className="ml-2">{SecondToHHMMSS(+item.duration)}</span> : null}
                                                                            //                 </React.Fragment> : null
                                                                            //     }
                                                                            // </React.Fragment>
                                                                            // :
                                                                            // <React.Fragment>
                                                                            //     {
                                                                            //         // item.checkExpireDate && !isEmpty(item.courseStudySubFileUpload) && item.courseStudySubFileUpload.filetype === 'application/pdf' ?
                                                                            //         item.checkExpireDate && !isEmpty(item.courseStudySubFileUpload) && item.courseStudySubFileUpload.filetype === 'application/pdf' && item.typeFile === 1 ?
                                                                            //             <img src="\showcase\icon\upload.png" title="บทเรียนแบบ pdf" className="cursor-pointer" alt="Card" width={20} height={20} onClick={() => CheckCourseStudy(item, index)} />
                                                                            //             :
                                                                            //             // item.checkExpireDate && !isEmpty(item.courseStudySubFileUpload) && !CheckImageType(item.courseStudySubFileUpload.filetype) && item.typeFile === 99 ?
                                                                            //             item.checkExpireDate && !isEmpty(item.courseStudySubFile) && item.typeFile === 99 ?
                                                                            //                 <React.Fragment>
                                                                            //                     <img src="\showcase\icon\play-button.png" title="บทเรียนแบบ video" className="cursor-pointer" alt="Card" width={20} height={20} onClick={() => CheckCourseStudy(item, index)} />
                                                                            //                     {" "}{+item.duration > 0 && !isEmpty(item.courseStudySubFile) && item.typeFile === 99 ? <span className="ml-2">{SecondToHHMMSS(+item.duration)}</span> : null}
                                                                            //                     {/* {" "}{+item.duration > 0 && !isEmpty(item.courseStudySubFileUpload) && CheckVideoType(item.courseStudySubFileUpload.filetype) ? <span className="ml-2">{SecondToHHMMSS(+item.duration)}</span> : null} */}
                                                                            //                 </React.Fragment>
                                                                            //                 :
                                                                            //                 <h6 className="text-danger">หมดเวลาการเรียนรู้</h6>
                                                                            //     }
                                                                            // </React.Fragment>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            }) : <tr><td colSpan={100} className="text-center text-danger">ไม่พบข้อมูล</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <Button
                                label="กลับไปเรียนต่อ"
                                icon="pi pi-arrow-right"
                                className="p-button-warning p-button-sm mt-1 mr-2 w-12rem"
                                onClick={() => RedirectTo(`/Learn/${courseStudyID}/${Type}`)}
                            />
                            <Button
                                label="คอร์สเรียนทั้งหมด"
                                icon="pi pi-book"
                                className="p-button-secondary p-button-sm mt-1 mr-2 w-12rem"
                                onClick={() => RedirectTo(`/Content`)}
                            />
                        </div>
                        <div className="row">
                            <div className="card mt-3">
                                <Chart type="bar" data={chartDataOverView} options={chartOptionsOverView} />
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </React.Fragment>
    )
}

const Loading = () => {
    return (
        <div className="text-center">
            <h5 className="text-red-600">กรุณารอสักครู่.....</h5>
        </div>
    )
}

export default React.memo(CourseProgress)