import React, { useState } from 'react';
import { useForm, Controller, useFieldArray, FormProvider } from "react-hook-form"
import { Container, Row, Col } from 'react-bootstrap'
import { ScrollPanel } from 'primereact/scrollpanel'
import { RadioButton } from 'primereact/radiobutton'
import { Checkbox } from 'primereact/checkbox'
import { useParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom"
import { Image } from 'primereact/image'
import { Badge } from 'primereact/badge'
import { v4 as uuidv4 } from 'uuid';
import { http, isEmpty, ErrorHandle, ScrollTop, NewLineText, ReplaceNewLine } from "../../Utils"
import { Button } from 'primereact/button'
import Swal from 'sweetalert2'

import DialogModal from '../../Components/Dialog'
import ViewLearningPDF from '../../Components/ViewLearningPDF'
import ViewLearningVideo from '../../Components/ViewLearningVideo'
import ViewLearning from '../../Components/ViewLearning'
import PDFView from '../../Components/ViewLearningPDF/PDFView'

import Preview from '../../Components/Previews'
import Loading from '../../Components/Loading'
import '../Content/Carousel.css'
import './styles.css'

const PostTestExamination = () => {

    const { courseStudySubID } = useParams()

    const RedirectTo = useNavigate()
    const methods = useForm()
    const { register, handleSubmit, formState: { errors }, setValue, getValues, watch, control, reset } = methods
    const { fields } = useFieldArray({ control, name: "QuizQuestion" })

    const [isLoading, setIsLoading] = useState(false)
    const [BlockSubmit, setBlockSubmit] = useState(false)

    const [Files, setFiles] = useState(null)

    const [ModalViewPDF, setModalViewPDF] = useState(false)
    const [ModalPDF, setModalPDF] = useState(false)
    const [ModalVideo, setModalVideo] = useState(false)
    const [ModalLearning, setModalLearning] = useState(false)

    const [DataQuizQuestionList, setDataQuizQuestionList] = React.useState([])
    const [DataQuestionAnswerList, setDatQuestionAnswerList] = React.useState([])
    const [StudyTypeTest, setStudyTypeTest] = React.useState({ preStudyTest: 0, postStudyTest: 0 })
    const [UserCourseStudySubID, setUserCourseStudySubID] = React.useState('')
    const [DataCourseStudySub, setDataCourseStudySub] = React.useState({
        detail: '',
        title: '',
        preStudyTest: 0,
        postStudyTest: 0,
        userCourseStudySubCompleteDate: '',
        userCourseStudySubActionStatus: 0,
        preTestStatus: 0,
        postTestStatus: 0,
        sort: 0,
        courseStudyID: '',
    })
    const [DataUserQuizQuestion, setDataUserQuizQuestion] = React.useState({
        postTestCorrectScore: 0,
        postTestStatus: 0,
        postTestWrongScore: 0,
        preTestCorrectScore: 0,
        preTestStatus: 0,
        preTestWrongScore: 0,
        totalScore: 0,
        pass: false,
    })

    React.useEffect(() => {

        (async () => {
            await GetDataQuizQuestion()
        })()

    }, [courseStudySubID])

    const GetDataQuizQuestion = async () => {

        try {

            setIsLoading(true)
            const _res = await http.post(`QuizQuestion/FindListBy/${courseStudySubID}`, { PostTest: 1 }, { headers: { 'Content-Type': 'multipart/form-data' } })
            if (_res.status === 200 && _res.data.status) {

                if (_res.data.courseStudySub.postStudyTest === 0) {
                    Swal.fire('แจ้งเตือน', 'ไม่การทดสอบหลังเรียน', 'warning').then((ok) => RedirectTo(`/Learn/${_res.data.courseStudySub.courseStudyID}`))
                    return
                }

                if (!isEmpty(_res.data.courseStudySub)) {
                    for (const Key in Object.keys(_res.data.courseStudySub)) {
                        const _Key = Object.keys(_res.data.courseStudySub)[Key]
                        const _Value = Object.values(_res.data.courseStudySub)[Key]
                        setValue(_Key.charAt(0).toUpperCase() + _Key.slice(1), _Value)
                    }
                }

                setUserCourseStudySubID(_res.data.courseStudySub.userCourseStudySubID)
                setDataQuizQuestionList(_res.data.data)
                setDataCourseStudySub(_res.data.courseStudySub)
                setStudyTypeTest({
                    preStudyTest: _res.data.courseStudySub.preStudyTest,
                    postStudyTest: _res.data.courseStudySub.postStudyTest,
                })
                if (_res.data.courseStudySub.randomQuizQuestion === 1) _res.data.data.sort(() => Math.random() - 0.5)
                const _ListQuizQuestion = Promise.all(_res.data.data.flatMap(async (item, idx) => {

                    if (_res.data.courseStudySub.randomAnswer === 1) item.listAnswer.sort(() => Math.random() - 0.5)
                    const _ListAnswer = item.listAnswer.flatMap((_item, _idx) => {
                        return {
                            UserAnswerID: _item.userAnswerID,
                            AnswerID: _item.answerID,
                            AnswerName: _item.answerName,
                            AnswerCorrect: _item.answerCorrect,
                            Correct: _item.correct,
                            ActionStatus: _item.actionStatus,
                            _Correct: _item._Correct,
                        }
                    })
                    setValue(`Answer[${idx}].QuizQuestionAnswer`, _ListAnswer)
                    // getFile(item.fileupload, idx)
                    return {
                        UserQuizQuestionID: item.userQuizQuestionID,
                        QuizQuestionID: item.quizQuestionID,
                        QuizQuestionTitle: item.quizQuestionTitle,
                        QuizQuestionType: item.quizQuestionType,
                        PostTestStatus: item.postTestStatus,
                        PreTestStatus: item.preTestStatus,
                        Fileupload: item.fileupload,
                        FileType: item.fileType,
                        CheckFile: item.checkFile,
                        QuizQuestionScore: item.quizQuestionScore,
                        UserQuizQuestionActionStatus: item.userQuizQuestionActionStatus,
                        Correct: item.correct,
                    }
                }))
                setValue("QuizQuestion", await _ListQuizQuestion)

                if (!isEmpty(_res.data.courseStudySub.userCourseStudySubID)) await GetQuizQuestionScore(_res.data.courseStudySub.userCourseStudySubID)
                setIsLoading(false)
                ScrollTop()
            }

        } catch (err) {
            ErrorHandle(err)
            setIsLoading(false)
        }
    }

    const GetQuizQuestionScore = async (UserCourseStudySubID) => {

        if (isEmpty(UserCourseStudySubID) && DataCourseStudySub.preTestStatus > 1) return

        try {

            setIsLoading(true)
            const _res = await http.post(`UserQuizQuestion/FindQuizQuestionScore/${UserCourseStudySubID}`, { PostTest: 1 }, { headers: { 'Content-Type': 'multipart/form-data' } })
            if (_res.status === 200 && _res.data.status) {
                setDataUserQuizQuestion(_res.data.data)
                setValue('PostTestCorrectScore', _res.data.data.postTestCorrectScore)
                setValue('PostTestWrongScore', _res.data.data.postTestWrongScore)
                setValue('UserCourseStudyActionStatus', _res.data.data.userCourseStudyActionStatus)
                setValue('UserCourseStudyCompleteDate', _res.data.data.userCourseStudyCompleteDate)
                // setValue('PostTestStatus', _res.data.data.postTestStatus)
                // setValue('PostTestWrongScore', 1)
                setIsLoading(false)
            }

        } catch (err) {
            ErrorHandle(err)
            setIsLoading(false)
        }
    }

    const onSubmit = async (data) => {

        if (data.QuizQuestion.length === 0) return Swal.fire('แจ้งเตือน', 'ไม่พบข้อมูลคำถาม', 'warning')
        if (data.Answer.length === 0) return Swal.fire('แจ้งเตือน', 'กรุณาเลือกคำตอบ', 'warning')

        let _rowAnswer = 0
        for await (const _ItemQuizQuestion of data.QuizQuestion) {
            _rowAnswer++
            const _ItemAnswer = data.Answer[_rowAnswer - 1].QuizQuestionAnswer.filter((item) => item.AnswerCorrect)
            if (_ItemAnswer.length <= 0) return Swal.fire('แจ้งเตือน', `กรุณาเลือกคำตอบ ข้อที่ ${_rowAnswer}`, 'warning')
        }

        const _ListQuizQuestion = data.QuizQuestion.map((item, idx) => {

            const _ListAnswer = data.Answer[idx].QuizQuestionAnswer.map((_item, _idx) => {
                return {
                    UserAnswerID: _item.UserAnswerID,
                    AnswerID: _item.AnswerID,
                    AnswerName: _item.AnswerName,
                    AnswerCorrect: _item.AnswerCorrect ? 1 : 0,
                }
            })

            return {
                UserQuizQuestionID: item.UserQuizQuestionID,
                QuizQuestionID: item.QuizQuestionID,
                QuizQuestionTitle: item.QuizQuestionTitle,
                QuizQuestionType: item.QuizQuestionType,
                ListAnswer: _ListAnswer
            }
        })

        const _data = {
            CourseStudySubID: courseStudySubID,
            PostTest: 1,
            PreTest: 0,
            ListQuizQuestion: _ListQuizQuestion
        }

        Swal.fire({
            icon: 'question',
            title: `ยืนยันการบันทึก`,
            text: `คุณต้องการบันทึกข้อมูลใช่หรือไม่`,
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
        }).then(async (result) => {

            if (!result.isConfirmed) return
            setBlockSubmit(true)
            try {

                setIsLoading(true)
                const _res = await http.post(`UserQuizQuestion/Save`, _data)
                if (_res.status === 200 && _res.data.status) return Swal.fire('สำเร็จ', _res.data.msg, 'success').then(async () => window.location.reload())
                // if (_res.status === 200 && _res.data.status) return Swal.fire('สำเร็จ', _res.data.msg, 'success').then(async () => await GetDataQuizQuestion())

            } catch (err) {
                ErrorHandle(err)
                setIsLoading(false)
            }
        })
    }

    const submitQuizQuestion = async () => {

        if (watch('QuizQuestion').length === 0) return Swal.fire('แจ้งเตือน', 'ไม่พบข้อมูลคำถาม', 'warning')
        if (watch('Answer').length === 0) return Swal.fire('แจ้งเตือน', 'กรุณาเลือกคำตอบ', 'warning')

        let _rowAnswer = 0
        for await (const _ItemQuizQuestion of watch('QuizQuestion')) {
            _rowAnswer++
            const _ItemAnswer = watch('Answer')[_rowAnswer - 1].QuizQuestionAnswer.filter((item) => item.AnswerCorrect)
            if (_ItemAnswer.length <= 0) return Swal.fire('แจ้งเตือน', `กรุณาเลือกคำตอบ ข้อที่ ${_rowAnswer}`, 'warning')
        }

        const _ListQuizQuestion = watch('QuizQuestion').flatMap((item, idx) => {

            const _ListAnswer = watch('Answer')[idx].QuizQuestionAnswer.flatMap((_item, _idx) => {
                return {
                    UserAnswerID: _item.UserAnswerID,
                    AnswerID: _item.AnswerID,
                    AnswerName: _item.AnswerName,
                    AnswerCorrect: _item.AnswerCorrect ? 1 : 0,
                }
            })

            return {
                UserQuizQuestionID: item.UserQuizQuestionID,
                QuizQuestionID: item.QuizQuestionID,
                QuizQuestionTitle: item.QuizQuestionTitle,
                QuizQuestionType: item.QuizQuestionType,
                ListAnswer: _ListAnswer
            }
        })

        const _data = {
            CourseStudySubID: courseStudySubID,
            PostTest: 1,
            PreTest: 0,
            ListQuizQuestion: _ListQuizQuestion
        }

        Swal.fire({
            icon: 'question',
            title: `ยืนยันการส่งคำตอบ`,
            text: `คุณต้องการส่งคำตอบใช่หรือไม่`,
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
        }).then(async (result) => {

            if (!result.isConfirmed) return
            setBlockSubmit(true)
            try {

                setIsLoading(true)
                const _res = await http.post('UserQuizQuestion/SubmitQuizQuestion', _data)
                if (_res.status === 200 && _res.data.status) return Swal.fire('สำเร็จ', _res.data.msg, 'success').then(() => window.location.reload())
                // if (_res.status === 200 && _res.data.status) return Swal.fire('สำเร็จ', _res.data.msg, 'success').then(async () => await GetDataQuizQuestion())

            } catch (err) {
                ErrorHandle(err)
                setIsLoading(false)
            }
        })
    }

    const nextCourseStudySub = async () => {

        if (DataCourseStudySub.sort <= 0 || isEmpty(DataCourseStudySub.courseStudyID)) return

        Swal.fire({
            title: 'ต้องการเรียนบทเรียนถัดไปหรือไม่',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
        }).then(async (result) => {

            if (!result.isConfirmed) return
            
            try {
                setIsLoading(true)
                const _res = await http.post(`UserCourseStudySub/NextCourseStudySub/${DataCourseStudySub.courseStudyID}/${DataCourseStudySub.sort}`)

                if (_res.status === 200 && _res.data.status) {
                    setIsLoading(false)
                    setTimeout(() => window.location.href = `/Learn/${watch('CourseStudyID')}/${_res.data.nextUserCourseStudySub === 0 ? 2 : 1}`, 800)
                }
            } catch (err) {
                ErrorHandle(err)
                setIsLoading(false)
            }
        })
    }

    const CheckCourseStudySub = async () => {
        Swal.fire({
            icon: 'question',
            title: `ยืนยันการทบทวน`,
            text: `คุณต้องการทบทวนใช่หรือไม่`,
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
        }).then(async (result) => {
            if (!result.isConfirmed) return

            await RePostTest(2)
            const _res = await http.post(`CourseStudy/GetCourseStudySub/${courseStudySubID}`)
            if (_res.status === 200 && _res.data.status) {
                setModalLearning(!ModalLearning)
                return
                if (_res.data.data.filetype === 'application/pdf')
                    setModalPDF(!ModalPDF)
                else
                    setModalVideo(!ModalVideo)
            }
        })
    }

    const RePostTest = async (Type = 1) => {
        if (Type === 1) {
            Swal.fire({
                icon: 'question',
                title: `ยืนยันการทำแบบทดสอบอีกครั้ง`,
                text: `คุณต้องการทำแบบทดสอบอีกครั้งใช่หรือไม่`,
                showCancelButton: true,
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                confirmButtonColor: '#22C55E',
                cancelButtonColor: '#EF4444',
            }).then(async (result) => {

                if (!result.isConfirmed) return

                try {

                    setIsLoading(true)
                    const _res = await http.post(`UserQuizQuestion/RePostTest/${watch('UserCourseStudySubID')}`, { RePostTestType: 1 }, { headers: { 'Content-Type': 'multipart/form-data' } })
                    if (_res.status === 200 && _res.data.status) return Swal.fire('สำเร็จ', _res.data.msg, 'success').then(async () => await GetDataQuizQuestion())

                } catch (err) {
                    ErrorHandle(err)
                    setIsLoading(false)
                }
            })
        } else {
            try {

                setIsLoading(true)
                const _res = await http.post(`UserQuizQuestion/RePostTest/${watch('UserCourseStudySubID')}`, { RePostTestType: 2 }, { headers: { 'Content-Type': 'multipart/form-data' } })
                if (_res.status === 200 && _res.data.status) await GetDataQuizQuestion()

            } catch (err) {
                ErrorHandle(err)
                setIsLoading(false)
            }
        }
    }

    const getFiles = async (FileID) => {

        if (isEmpty(FileID)) return

        try {
            const _res = await http.get(`/File/View/${sessionStorage.getItem('sessionToken')}/${FileID}`, { responseType: 'blob' })
            if (_res.status === 200) return _res.data
        } catch (err) {
            console.log(err)
        }
    }

    const FinishCourseStudySub = async (CourseStudyID) => {
        if (isEmpty(CourseStudyID)) return
        try {
            const _res = await http.post(`UserCourseStudy/FinishCourse/${CourseStudyID}`, { Type: 'PostTestExamination' }, { headers: { 'Content-Type': 'multipart/form-data' } })
            if (_res.status === 200 && _res.data.status) {
                Swal.fire('เรียบร้อย', 'ยืนยันการจบหลักสูตรสำเร็จ', 'success').then((ok) => {
                    if (ok) {
                        return window.location.href = `/CourseProgress/${CourseStudyID}/2`
                        // RedirectTo(`/CourseProgress/${CourseStudyID}`)
                    }
                });
            }
        } catch (err) { ErrorHandle(err) }
    }

    const PDFViews = async (File = null) => {

        if (isEmpty(File)) return

        setFiles(File)
        setModalViewPDF(true)
    }

    // console.log('watch()', watch())
    // console.log('จบ', watch(`PostStudyTest`) === 1 && watch(`PostTestStatus`) === 2 && watch('UserCourseStudyActionStatus') < 2 && isEmpty(watch('UserCourseStudyCompleteDate')))

    return (
        <React.Fragment>
            <Loading isLoading={isLoading} />
            <DialogModal
                Header={""}
                style={{ lineHeight: '1.0' }}
                Open={ModalPDF}
                OnHide={() => setModalPDF(!ModalPDF)}
                Maximizable={true}
                Width={100}
                Height={100}
                Maximized={800}
                Content={
                    <React.Fragment>
                        <ViewLearningPDF
                            UserCourseStudySubID={UserCourseStudySubID}
                            setModal={setModalPDF}
                        />
                    </React.Fragment>
                }
            />
            <DialogModal
                Header={""}
                style={{ lineHeight: '1.0' }}
                Open={ModalVideo}
                OnHide={() => setModalVideo(!ModalVideo)}
                Maximizable={true}
                Width={100}
                Height={100}
                Maximized={800}
                Content={
                    <React.Fragment>
                        <ViewLearningVideo
                            UserCourseStudySubID={UserCourseStudySubID}
                            setModal={setModalVideo}
                        />
                    </React.Fragment>
                }
            />
            <DialogModal
                Header={""}
                style={{ lineHeight: '1.0' }}
                Open={ModalLearning}
                OnHide={() => setModalLearning(!ModalLearning)}
                Maximizable={true}
                Width={100}
                Height={100}
                Maximized={800}
                Content={
                    <React.Fragment>
                        <ViewLearning
                            UserCourseStudySubID={UserCourseStudySubID}
                            setModal={setModalLearning}
                        />
                    </React.Fragment>
                }
            />
            <DialogModal
                Header={""}
                style={{ lineHeight: '1.0' }}
                Open={ModalViewPDF}
                OnHide={() => {
                    setModalViewPDF(!ModalViewPDF)
                    setFiles(null)
                }}
                Maximizable={true}
                Width={100}
                Height={100}
                Maximized={800}
                Content={
                    <React.Fragment>
                        <PDFView
                            File={Files}
                            setModal={setModalViewPDF}
                        />
                    </React.Fragment>
                }
            />
            <div data-aos="fade-right">
                <Container>
                    <h5 className="mt-3 font-bold">คำชี้แจง (แบบทดสอบหลังเรียน)</h5>
                    <div className="mt-2" >
                        <div className="flex flex-wrap align-items-start justify-content-start mt-1">
                            <div className="field col-6 md:col-6 justify-content-start">
                                <label className="m-0 text-lg block mb-2 text-left"><p className="text-break" dangerouslySetInnerHTML={{ __html: !isEmpty(DataCourseStudySub) ? DataCourseStudySub.detail : null }} /></label>
                            </div>
                            <div className="field flex-column col-6 md:col-6 justify-content-end">
                                <div className="flex justify-content-end align-items-end">
                                    <label className="m-0 font-semibold text-lg block mb-2 text-right">จำนวนข้อที่ถูก <Badge value={DataUserQuizQuestion.postTestCorrectScore} severity="success"></Badge></label>
                                </div>
                                <div className="flex justify-content-end align-items-end">
                                    <label className="m-0 font-semibold text-lg block mb-2 text-right">จำนวนข้อที่ผิด <Badge value={DataUserQuizQuestion.postTestWrongScore} severity="danger"></Badge></label>
                                </div>
                                <div className="flex justify-content-end align-items-end">
                                    <label className="m-0 font-semibold text-lg block mb-2 text-right">รวมคะแนน <Badge value={DataUserQuizQuestion.totalScore}></Badge></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        DataUserQuizQuestion.postTestStatus > 0 &&
                        <div className="flex flex-wrap align-items-start justify-content-start mt-1">
                            <div className="field col-12 md:col-12">
                                <label className="m-0 font-semibold text-lg block mb-2">สถานะการทำแบบทดสอบ : <span className="text-green-500">{DataUserQuizQuestion.postTestStatus === 1 ? 'อยู่ระหว่างทำแบบทดสอบ' : 'ส่งคำตอบสำเร็จ'}</span></label>
                            </div>
                        </div>
                    }
                    <hr />
                    <FormProvider  {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {
                                fields.length > 0 ? fields.map((item, index) => {
                                    const _row = index + 1
                                    return (
                                        <div className="flex flex-column card-container m-2" key={item.id}>
                                            <Row>
                                                <Col md={10} className="text-left">
                                                    <h6 dangerouslySetInnerHTML={{ __html: `คำถามข้อที่ ${_row} : ${ReplaceNewLine(item.QuizQuestionTitle)}` }}></h6>
                                                </Col>
                                                <Col md={2} className="text-right">
                                                    <span>{item.QuizQuestionScore} คะแนน </span>
                                                </Col>
                                                <Col md={12} className="mt-1">
                                                    {
                                                        isEmpty(item.Fileupload) ? '' :
                                                            item.FileType !== 'application/pdf' ?
                                                                <Preview FilesType={item.FileType} Files={item.Fileupload} Width={600} Preview={true} />
                                                                :
                                                                <Button
                                                                    type="button"
                                                                    icon="pi pi-file-pdf"
                                                                    className="p-button-raised p-button-sm mt-3 mr-2 w-12rem"
                                                                    label="ดูไฟล์แนบPDF"
                                                                    title="ดูไฟล์แนบPDF"
                                                                    onClick={() => PDFViews(item.Fileupload)}
                                                                />
                                                    }
                                                </Col>
                                                <Col md={12} className="mt-1">
                                                    <QuizQuestionAnswer
                                                        control={control}
                                                        watch={watch}
                                                        register={register}
                                                        setValue={setValue}
                                                        errors={errors}
                                                        idx={index}
                                                        getValues={getValues}
                                                        DataQuestionAnswerList={DataQuestionAnswerList}
                                                    />
                                                    <hr />
                                                    {/* {
                                                        item.QuizQuestionType === 1 ?
                                                            <QuizQuestionAnswer
                                                                control={control}
                                                                watch={watch}
                                                                register={register}
                                                                setValue={setValue}
                                                                errors={errors}
                                                                idx={index}
                                                                getValues={getValues}
                                                                DataQuestionAnswerList={DataQuestionAnswerList}
                                                            />
                                                            :
                                                            <QuizQuestionAnswers
                                                                control={control}
                                                                watch={watch}
                                                                register={register}
                                                                setValue={setValue}
                                                                errors={errors}
                                                                idx={index}
                                                                getValues={getValues}
                                                                DataQuestionAnswerList={DataQuestionAnswerList}
                                                            />
                                                    } */}
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                })
                                    : !isLoading &&
                                    <div className="flex flex-column card-container m-2">
                                        <Row>
                                            <Col md={12} className="text-center">
                                                <h5 className="text-danger">ไม่พบข้อมูลคำถาม</h5>
                                            </Col>
                                        </Row>
                                    </div>
                            }
                            <div className="flex flex-column justify-content-center card-container">
                                <div className="flex align-items-center justify-content-center m-2">
                                    <div className="text-center">
                                        {
                                            // !isEmpty(DataCourseStudySub) && DataCourseStudySub.postTestStatus <= 1 &&
                                            !isEmpty(DataCourseStudySub) &&
                                            <Button
                                                type="button"
                                                icon="pi pi-angle-left"
                                                className="p-button-raised p-button-sm mt-3 mr-2 p-button-danger w-12rem"
                                                label="ย้อนกลับ"
                                                onClick={() => (watch(`UserCourseStudySubActionStatus`) === 1 && watch(`UserCourseStudyActionStatus`) === 2) ? RedirectTo(`/Learn/${watch('CourseStudyID')}/2`) : RedirectTo(`/Learn/${watch('CourseStudyID')}/1`)}
                                            />
                                        }
                                        <Button
                                            type="button"
                                            icon="pi pi-home"
                                            className="p-button-raised p-button-sm mt-3 mr-2 w-12rem"
                                            label="หน้าหลัก"
                                            onClick={() => RedirectTo('/')}
                                        />
                                        {
                                            !isEmpty(DataCourseStudySub) && DataCourseStudySub.postTestStatus < 2 &&
                                            <>
                                                <Button type="submit" icon="pi pi-save" label="บันทึกคำตอบ" title="บันทึกคำตอบ" disabled={BlockSubmit} className="p-button-raised p-button-info p-button-sm mt-3 mr-2 w-12rem" />
                                                <Button type="button" icon="pi pi pi-check-circle" label="ส่งคำตอบ" title="ส่งคำตอบ" disabled={BlockSubmit} onClick={submitQuizQuestion} className="p-button-raised p-button-success p-button-sm mt-3 mr-2 w-12rem" />
                                            </>
                                        }
                                        {
                                            !isEmpty(DataCourseStudySub) && DataCourseStudySub.postTestStatus > 1 && DataCourseStudySub.userCourseStudySubActionStatus >= 2 &&
                                            <>
                                                {
                                                    DataCourseStudySub.nextUserCourseStudySub === 1 && DataUserQuizQuestion.postTestStatus === 2 ?
                                                        <Button type="button" icon="pi pi-chevron-right" label="ไปบทเรียนถัดไป" title="ไปบทเรียนถัดไป" className="p-button-raised p-button-warning p-button-sm mt-3 mr-2 w-12rem" onClick={nextCourseStudySub} />
                                                        : (DataCourseStudySub.nextUserCourseStudySub === 1 || DataCourseStudySub.nextUserCourseStudySub === 0) && DataUserQuizQuestion.pass === false ?
                                                            <>
                                                                <Button type="button" icon="pi pi-replay" label="ทบทวนอีกครั้ง" title="ทบทวนอีกครั้ง" className="p-button-raised p-button-info p-button-sm mt-3 mr-2 w-12rem" onClick={CheckCourseStudySub} />
                                                                <Button type="button" icon="pi pi-book" label="ทำแบบทดสอบอีกครั้ง" title="ทำแบบทดสอบอีกครั้ง" className="p-button-raised p-button-warning p-button-sm mt-3 mr-2 w-12rem" onClick={() => RePostTest()} />
                                                            </>
                                                            : null
                                                }
                                            </>
                                        }
                                        {
                                            //จบหลักสูตร
                                            (watch(`UserCourseStudySubActionStatus`) === 2 && watch(`NextUserCourseStudySub`) === 0 ?
                                                <>
                                                    {
                                                        //มีสอบหลังเรียน
                                                        watch(`PostStudyTest`) === 1 && watch(`PostTestStatus`) === 2 && watch('UserCourseStudyActionStatus') < 2 && isEmpty(watch('UserCourseStudyCompleteDate')) ?
                                                            <Button
                                                                type='button'
                                                                label="จบหลักสูตร"
                                                                title="จบหลักสูตร"
                                                                className="p-button-raised p-button-success p-button-sm mt-3 mr-2 w-12rem"
                                                                onClick={() => {
                                                                    Swal.fire({
                                                                        title: 'ยืนยันการจบหลักสูตร',
                                                                        icon: 'warning',
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: '#22C55E',
                                                                        cancelButtonColor: '#EF4444',
                                                                        confirmButtonText: 'ตกลง',
                                                                        cancelButtonText: 'ยกเลิก'
                                                                    }).then(async (result) => {
                                                                        if (!result.isConfirmed) return
                                                                        await FinishCourseStudySub(watch(`CourseStudyID`))
                                                                    })
                                                                }}
                                                            /> : ""
                                                    }
                                                </>
                                                : "")
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                </Container>
            </div>
        </React.Fragment>
    )
}

const QuizQuestionAnswer = ({ control, watch, register, setValue, errors, DataQuestionAnswerList, idx }) => {

    const { fields } = useFieldArray({ control, name: `Answer.${idx}.QuizQuestionAnswer` })
    const [selectedKey, setSelectedKey] = useState(null)
    const handleChangeAnswerCorrect = (value, row) => {

        const _ListQuizQuestion = watch('Answer')
        if (_ListQuizQuestion.length === 0) return

        const _ListQuizQuestionAnswer = _ListQuizQuestion[row].QuizQuestionAnswer
        for (const _Item of _ListQuizQuestionAnswer) {
            if (_Item.AnswerName === value)
                _Item.AnswerCorrect = true
            else
                _Item.AnswerCorrect = false
        }

        setValue(`Answer.${idx}.QuizQuestionAnswer`, _ListQuizQuestionAnswer)
    }

    return (
        <React.Fragment>
            {
                fields.flatMap((_Item, _idx) => {
                    const _row = _idx + 1
                    return (
                        <React.Fragment key={_Item.id}>
                            <div className="col-12 md:col-12" style={{ display: 'flex' }}>
                                <Controller
                                    control={control}
                                    name={`Answer.${idx}.QuizQuestionAnswer.${_idx}.AnswerCorrect`}
                                    render={({ field }) => (
                                        <React.Fragment>
                                            <RadioButton
                                                // {...field}
                                                inputId={'AnswerCorrect-' + _Item.id}
                                                value={_Item.AnswerName}
                                                checked={_Item.AnswerCorrect ? _Item.AnswerCorrect : _Item.AnswerName === selectedKey}
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                    setSelectedKey(e.target.value)
                                                    handleChangeAnswerCorrect(e.target.value, idx)
                                                }}
                                                disabled={_Item.ActionStatus > 0 ? true : false}
                                            />
                                        </React.Fragment>
                                    )}
                                />
                                <p className="ml-2 font-light" dangerouslySetInnerHTML={{ __html: ReplaceNewLine(_Item.AnswerName) }}></p>
                            </div>
                        </React.Fragment>
                    )
                })
            }
        </React.Fragment>
    )
}

const QuizQuestionAnswers = ({ control, watch, register, setValue, errors, DataQuestionAnswerList, idx }) => {

    const { fields } = useFieldArray({ control, name: `Answer.${idx}.QuizQuestionAnswer` })
    const [selectedKey, setSelectedKey] = useState(null)
    const handleChangeAnswerCorrect = (value, row) => {

        const _ListQuizQuestion = watch('Answer')
        if (_ListQuizQuestion.length === 0) return

        const _ListQuizQuestionAnswer = _ListQuizQuestion[row].QuizQuestionAnswer
        for (const _Item of _ListQuizQuestionAnswer) {
            if (_Item.AnswerCorrect)
                _Item.AnswerCorrect = true
            else
                _Item.AnswerCorrect = false
        }

        setValue(`Answer.${idx}.QuizQuestionAnswer`, _ListQuizQuestionAnswer)
    }

    return (
        <React.Fragment>
            {
                fields.flatMap((_Item, _idx) => {
                    const _row = _idx + 1
                    return (
                        <div className="col-12 md:col-12 mt-2" key={_Item.id}>
                            <Controller
                                control={control}
                                name={`Answer.${idx}.QuizQuestionAnswer.${_idx}.AnswerCorrect`}
                                render={({ field }) => (
                                    <Checkbox
                                        title={_Item.AnswerName}
                                        inputId={'AnswerCorrect-' + _Item.id}
                                        value={true}
                                        // checked={_Item.AnswerCorrect ? _Item.AnswerCorrect : watch(`Answer.${idx}.QuizQuestionAnswer.${_idx}.AnswerCorrect`)}
                                        checked={watch(`Answer.${idx}.QuizQuestionAnswer.${_idx}.AnswerCorrect`)}
                                        onChange={(d) => {
                                            field.onChange(d)
                                            handleChangeAnswerCorrect(d.target.checked, idx)
                                            setValue(`Answer.${idx}.QuizQuestionAnswer.${_idx}.AnswerCorrect`, d.target.checked ? true : false)
                                        }}
                                    />
                                )}
                            />
                            <label htmlFor={uuidv4()} className="ml-2">{_Item.AnswerName}</label>
                        </div>
                    )
                })
            }
        </React.Fragment>
    )
}

export default React.memo(PostTestExamination)