import React from 'react'
import { Image } from 'primereact/image'
import { CheckVideoType, isEmpty } from '../../Utils'
import ReactPlayer from 'react-player/lazy'

import './style.css'

const Previews = (props) => {

    const { FilesType, Files, FilesName, setDuration, Width, Height, setProcess, Preview } = props

    // const handleContextMenu = (e) => e.preventDefault()

    // React.useEffect(() => {

    //     document.addEventListener("contextmenu", handleContextMenu)

    //     return () => document.removeEventListener("contextmenu", handleContextMenu)

    // }, [FilesType])

    return (
        <React.Fragment>
            {
                !isEmpty(FilesType) && CheckVideoType(FilesType) ?
                    <ReactPlayer
                        // url={URL.createObjectURL(Files)}
                        url={Files}
                        playing={false}
                        muted={false}
                        controls={true}
                        progressInterval={1000}
                        config={{
                            file: {
                                attributes: {
                                    controlsList: 'nodownload',
                                    disablePictureInPicture: true,
                                    disableRemotePlayback: true,
                                },
                                hlsOptions: {
                                    enableWorker: true,
                                    enableSoftwareAES: true,
                                    debug: false,
                                    xhrSetup: (xhr, url) => {
                                        xhr.withCredentials = true
                                    },
                                },
                            },
                        }}
                        onDuration={duration => setDuration(duration)}
                        onClickPreview={(e) => {
                            e.preventDefault()
                            return
                        }}
                        width={Width}
                        height={Height}
                        onProgress={(state) => setProcess(state)}
                        title={FilesName}
                        onContextMenu={(e) => e.preventDefault()}
                    />
                    : !isEmpty(FilesType) && FilesType === 'application/pdf' ?
                        <iframe
                            src={Files}
                            // ref={iframeRef}
                            width={Width}
                            height={Height}
                            title={FilesName}
                            without="true"
                            rel="noreferrer"
                            className="pdf-viewer context-menu"
                            onClick={(e) => {
                                e.preventDefault()
                                return
                            }}
                        />
                        // <embed
                        //     src={Files}
                        //     type={FilesType}
                        //     className="pdf-viewer context-menu"
                        //     width={Width}
                        //     height={Height}
                        //     title={FilesName}
                        //     onContextMenu={(e) => e.preventDefault()}
                        // />
                        :
                        <Image
                            // src={URL.createObjectURL(Files)}
                            src={Files}
                            alt="Image"
                            width={Width}
                            height={Height}
                            preview={Preview}
                            title={FilesName}
                            onContextMenu={(e) => e.preventDefault()}
                        />
            }
        </React.Fragment>
    )
}

export default React.memo(Previews)