
import React, { useRef, useState } from 'react'
import { Row, Container, Col } from 'react-bootstrap'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import { http, isEmpty, truncateText, stripHtml, SecondToHHMMSSFormat, ErrorHandle, CheckImageType, CheckVideoType } from '../../Utils'
import { useParams } from 'react-router-dom'

import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useForm, useFieldArray } from 'react-hook-form'
import { Document, Page, pdfjs } from 'react-pdf'

import DialogModal from '../../Components/Dialog'
import ViewLeanningPDF from '../../Components/ViewLearningPDF'
import ViewLeanningVideo from '../../Components/ViewLearningVideo'
import ViewLearning from '../../Components/ViewLearning'
import './style.css'
import '../../index.css'

const TextNowrap = {
    flexDirection: 'row',
    whiteSpace: 'pre-line',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // fontSize: '15px',
    height: '7rem',
    width: '100%',
}

const TextNowrapAdvice = {
    flexDirection: 'row',
    whiteSpace: 'pre-line',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // fontSize: '15px',
    height: '5rem',
    width: '100%',
}

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: '../../../public/fonts/SMARNFILM-Black.otf',
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const Learn = () => {

    const { courseStudyID, Type } = useParams()

    const RedirectTo = useNavigate()

    const { control, watch, setValue, reset } = useForm()
    const { fields } = useFieldArray({ control: control, name: 'ListCourseStudySub' })

    const [modalAdd_Lesson, setModalAdd_Lesson] = React.useState(false)
    const [modalAdd_DetailSub, setModalAdd_DetailSub] = React.useState(false)
    const [ModalAdviceDetail, setModalAdviceDetail] = React.useState(false)
    const [modalAdd_PDF, setModalAdd_PDF] = React.useState(false)
    const [modalAdd_Image, setModalAdd_Image] = React.useState(false)
    const [DataAdvice, setDataAdvice] = React.useState({})
    const [DataProfileTitle, setDataProfileTitle] = React.useState({})
    const [DataCourseStudyFile, setDataCourseStudyFile] = React.useState({})
    const [DataCourseDetailSub, setDataCourseDetailSub] = React.useState({})
    const [DataExpireDate, setDataExpireDate] = React.useState(false)
    const [DataCourseStudySub, setDataCourseStudySub] = React.useState([])
    const [fileViewURL, setFileViewURL] = React.useState()
    const [fileViewTpyeFile, setFileViewTpyeFile] = React.useState()
    const [StudyTypeTest, setStudyTypeTest] = React.useState({
        preStudyTest: 0,
        postStudyTest: 0,
        postTestStatus: 0,
        preTestStatus: 0,
    })
    const [CourseStudySubID, setCourseStudySubID] = React.useState('')
    const [UserCourseStudySubID, setUserCourseStudySubID] = React.useState('')
    const [ActionStatus, setActionStatus] = React.useState(0)
    const [Files, setFiles] = React.useState(null)
    const [ModalLearning, setModalLearning] = React.useState(false)
    const [isLoad, setisLoad] = React.useState(false)

    //PDF
    const [numPages, setNumPages] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)

    const ds = useRef(null)

    React.useEffect(() => {

        if (isEmpty(courseStudyID) || isEmpty(Type) || (!isEmpty(Type) && +Type !== 1 && +Type !== 2)) {
            Swal.fire('แจ้งเตือน', 'ไม่พบข้อมูลที่ต้องการ', 'warning').then((res) => RedirectTo('/'))
            return
        }

        (async () => await GetDataStudyProfile())()

    }, [courseStudyID])

    const GetDataStudyProfile = async () => {
        try {

            const _res = await http.post(`CourseStudy/FindByCourseStudySub/${courseStudyID}/${Type}`)
            if (_res.status === 200 && _res.data.status) {
                setDataAdvice(_res.data.data)
                setDataProfileTitle(_res.data.data)
                setDataCourseStudyFile(_res.data.data)
                setDataCourseDetailSub(_res.data.data)
                setDataExpireDate(_res.data.data)
                if (!isEmpty(_res.data.data.fileid)) await getFile(_res.data.data.fileUpload)
                if (_res.data._CheckStatus === 1) await SaveUserCourseStudy()

                await SaveUserCourseStudySub(courseStudyID)
            } else {
                return Swal.fire('แจ้งเตือน', _res.data.msg, 'warning').then((res) => RedirectTo('/'))
            }
        } catch (err) { ErrorHandle(err) }
    }

    const SaveUserCourseStudySub = async (CourseStudyID) => {
        try {

            const _resSaveSub = await http.post(`UserCourseStudySub/SaveUserCourseStudySub/${CourseStudyID}`)
            if (_resSaveSub.status === 200 && _resSaveSub.data.status) await GetCourseStudySub(CourseStudyID)

        } catch (err) { ErrorHandle(err) }
    }

    const SaveUserCourseStudy = async () => {
        try {

            await http.post(`UserCourseStudy/SaveUserCourseStudy/${courseStudyID}`)

        } catch (err) { ErrorHandle(err) }
    }

    const GetCourseStudySub = async (CourseStudyID) => {
        try {
            setisLoad(true)
            const res = await http.post(`UserCourseStudySub/FindByList/${CourseStudyID}`)
            if (res.status === 200 && res.data.status) {
                setDataCourseStudySub(res.data.data)
                const _ListCourseStudySub = res.data.data.flatMap((item) => {
                    return {
                        ActionStatus: item.actionStatus,
                        UserCourseStudySubID: item.userCourseStudySubID,
                        PreTestStatus: item.preTestStatus,
                        PostTestStatus: item.postTestStatus,
                        CourseStudySubID: item.courseStudySubID,
                        CourseStudySubFileUpload: {
                            FileID: item.courseStudySubFileUpload.fileid,
                            FileName: item.courseStudySubFileUpload.filename,
                            FileURL: item.courseStudySubFileUpload.fileurl,
                            FileType: item.courseStudySubFileUpload.filetype,
                        },
                        PostStudyTest: item.postStudyTest,
                        PreStudyTest: item.preStudyTest,
                        Sort: item.sort,
                        Title: item.title,
                        TypeFile: item.typeFile,
                    }
                })
                setValue('ListCourseStudySub', _ListCourseStudySub)
                setisLoad(false)
            }

        } catch (err) {
            setisLoad(false)
            ErrorHandle(err)
        }
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const nextPage = () => {
        if (pageNumber < numPages) setPageNumber(pageNumber + 1)
    }

    const UpdateUserCourseStudySubAction = async () => {
        if (watch('ActionStatus') > 0 || isEmpty(watch('UserCourseStudySubID'))) return
        try {
            const res = await http.post(`UserCourseStudySub/UpdateActionStatus/${watch('UserCourseStudySubID')}`, { ActionStatus: 2 }, { headers: { 'Content-Type': 'multipart/form-data' } })
            //if (res.status === 200 && res.data.status) await GetCourseStudySub(res.data.courseStudyID)
        } catch (err) { ErrorHandle(err) }
    }

    const prevPage = () => {
        if (pageNumber > 0) {
            setPageNumber(pageNumber - 1)
        }
    }

    const ShowCourseStudySubPreStudyTest = (CourseStudySubID, PreTestStatus = 0) => {
        Swal.fire({
            title: 'จะเข้าสู่บททดสอบก่อนเรียนละน้า',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก'
        }).then((result) => {
            if (!result.isConfirmed) return
            // RedirectTo(`/PreTestExamination/${CourseStudySubID}`)
            window.location.href = `/PreTestExamination/${CourseStudySubID}`
        })
    }

    const ShowCourseStudySubPostStudyTest = (CourseStudySubID, PostTestStatus = 0) => {
        Swal.fire({
            title: 'จะเข้าสู่บททดสอบหลังเรียนละน้า',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก'
        }).then(async (result) => {
            if (!result.isConfirmed) return
            await UpdateUserCourseStudySubAction()
            // RedirectTo(`/PostTestExamination/${CourseStudySubID}`)
            window.location.href = `/PostTestExamination/${CourseStudySubID}`
        })
    }

    const CheckPrevUserCourseStudySubSuccess = async (UserCourseStudySubID) => {
        const _res = await http.post(`UserCourseStudySub/CheckPrevUserCourseStudySubSuccess/${UserCourseStudySubID}`)
        if (_res.status === 200) {
            return _res.data.status;
        }
    }

    const CheckCourseStudy = (data, row) => {
        reset()
        if (isEmpty(data)) return
        let _LastCourse = data.sort - 1;
        if (_LastCourse < 1) _LastCourse = 1;
        if (data.learningStatus !== 1) return Swal.fire({ title: `กรุณาเรียนรู้บทเรียนลำดับที่ ${_LastCourse} ให้สำเร็จ`, icon: 'warning' })
        if (_LastCourse === 1) UserCourseStudyStartLearning(data.userCourseStudyID)
        setValue('ActionStatus', +data.actionStatus)
        setValue('UserCourseStudySubID', data.userCourseStudySubID)
        setValue('CheckFileCourseStudySubFileUpload', data.checkFileCourseStudySubFileUpload)

        setStudyTypeTest({ preStudyTest: +data.preStudyTest, postStudyTest: +data.postStudyTest, preTestStatus: +data.preTestStatus, postTestStatus: +data.postTestStatus })

        const _CheckPrevUserCourseStudySubSuccess = CheckPrevUserCourseStudySubSuccess(data.userCourseStudySubID)
        if (_CheckPrevUserCourseStudySubSuccess === false) {
            Swal.fire('แจ้งเตือน', 'โปรดตรวจสอบบทเรียนให้ถูกต้องตามลำดับ', 'warning').then((ok) => {
                if (ok.isConfirmed) {
                    window.location.reload();
                }
            })
        }

        UpdateUserCourseStudySubStartLearning(data)
        // ไม่มีสอบ
        if (+data.preStudyTest === 0 && +data.postStudyTest === 0) {
            return CheckModalCoruseStudySub(data)
        }
        // สอบก่อนเรียน
        if (+data.preStudyTest === 1 && +data.actionStatus === 0) {
            if (+data.preTestStatus <= 1)
                return ShowCourseStudySubPreStudyTest(data.courseStudySubID, +data.preTestStatus)
            else
                return CheckModalCoruseStudySub(data)
        }
        // สอบหลังเรียน
        if (+data.postStudyTest === 1 && +data.preTestStatus >= 0) {
            if (+data.postTestStatus !== 2 && +data.actionStatus >= 2)
                return ShowCourseStudySubPostStudyTest(data.courseStudySubID, +data.postTestStatus)
            else
                return CheckModalCoruseStudySub(data)
        }
        // ผ่านบทเรียน
        if (+data.actionStatus === 2 && (+data.preStudyTest === 0 && +data.postStudyTest === 0) || (+data.postStudyTest === 1 && +data.postTestStatus === 2)) {
            return CheckModalCoruseStudySub(data)
        }
    }

    const CheckModalCoruseStudySub = async (data) => {
        if (isEmpty(data)) return
        //if (!data.checkFileCourseStudySubFileUpload) return Swal.fire({ icon: 'warning', title: 'ไม่พบไฟล์' })
        let FileType = +data.typeFile === 99 ? 'video/mp4' : data.courseStudySubFileUpload.filetype
        let FileUrl = +data.typeFile === 99 ? '' : data.courseStudySubFileUpload.fileurl
        // setFileViewURL(FileUrl)
        setFileViewTpyeFile(FileType)
        setCourseStudySubID(data.courseStudySubID)
        if (+data.typeFile === 1 && !isEmpty(data.courseStudySubFileUpload.fileid)) {
            getFile(data.courseStudySubFileUpload.fileurl, 2)
        }

        switch (FileType) {
            case 'application/pdf':
                if (+data.preStudyTest === 0 && +data.postStudyTest === 0)
                    setModalLearning(!ModalLearning)
                else
                    setModalLearning(!ModalLearning)
                break
            default:
                if (isEmpty(watch('UserCourseStudySubID'))) return
                setModalLearning(!ModalLearning)
                break
        }
    }

    //File Upload
    const getFile = async (FileURL, Type = 1) => {

        if (isEmpty(FileURL)) return

        try {

            const _res = await http.get(FileURL, { responseType: 'blob' })
            if (_res.status === 200) {
                if (Type === 1)
                    setFiles(_res.data)
                else
                    setFileViewURL(_res.data)
            }

        } catch (err) { console.log(err) }
    }

    const UserCourseStudyStartLearning = async (UserCourseStudyID) => {
        if (isEmpty(UserCourseStudyID)) return
        try {
            await http.post(`UserCourseStudy/UserCourseStudyStartLearning/${UserCourseStudyID}`)
        } catch (err) { ErrorHandle(err) }
    }

    const UpdateUserCourseStudySubStartLearning = async (data) => {
        if (isEmpty(data)) return
        if (isEmpty(data.userCourseStudySubID)) return
        if (data.checkStartDateLearn === true) return
        try {
            const res = await http.post(`UserCourseStudySub/UserCourseStudySubStartLearning/${data.userCourseStudySubID}`)
        } catch (err) { ErrorHandle(err) }
    }

    // Swal.fire({
    //     title: 'กำลังเข้าสู่บททดสอบหลังเรียน',
    //     allowOutsideClick: false,
    //     didOpen: () => {
    //         Swal.showLoading()
    //     }
    // })

    return (
        <React.Fragment>
            <DialogModal
                Header={""}
                style={{ lineHeight: '1.0' }}
                Open={ModalLearning}
                PlayVdeio={<Button className='p-button-danger' onClick={() => setModalLearning(!ModalLearning)}>Close</Button>}
                OnHide={() => {
                    setDataCourseStudySub([])
                    GetDataStudyProfile()
                    setModalLearning(!ModalLearning)
                    setActionStatus(0)
                }}
                Maximizable={true}
                Width={50}
                Height={50}
                Maximized={20}
                Content={
                    <React.Fragment>
                        <ViewLearning
                            UserCourseStudySubID={watch('UserCourseStudySubID')}
                            setModel={setModalLearning}
                        />
                    </React.Fragment>
                }
            />
            <DialogModal
                Header={""}
                style={{ lineHeight: '1.0' }}
                Open={modalAdd_Image}
                PlayVdeio={<Button className='p-button-danger' onClick={() => setModalAdd_DetailSub(!modalAdd_Image)}>Close</Button>}
                OnHide={() => {
                    setModalAdd_Image(!modalAdd_Image)
                    setActionStatus(0)
                }}
                Maximizable={true}
                Width={50}
                Height={50}
                Maximized={20}
                Content={
                    <React.Fragment>
                        <Container>
                            <img src={fileViewURL} width="100%" height="600" ></img>
                        </Container>
                    </React.Fragment>
                }
            />
            <DialogModal
                Header={""}
                style={{ lineHeight: '1.0' }}
                Open={modalAdd_DetailSub}
                PlayVdeio={<Button className='p-button-danger' onClick={() => setModalAdd_DetailSub(!modalAdd_DetailSub)}>Close</Button>}
                OnHide={() => {
                    setModalAdd_DetailSub(!modalAdd_DetailSub)
                    setActionStatus(0)
                }}
                Maximizable={true}
                Width={50}
                Height={50}
                Maximized={20}
                Content={
                    <React.Fragment>
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <h6 className="font-bold">รายละเอียดเพิ่มเติม</h6>
                                    {<p className="mt-2" dangerouslySetInnerHTML={{ __html: !isEmpty(DataCourseDetailSub.detail) ? DataCourseDetailSub.detail : "ไม่มีข้อมูล" }} />}
                                </Col>
                            </Row>
                        </Container>
                    </React.Fragment>
                }
            />
            <DialogModal
                Header={""}
                style={{ lineHeight: '1.0' }}
                Open={ModalAdviceDetail}
                PlayVdeio={<Button className='p-button-danger' onClick={() => setModalAdviceDetail(!ModalAdviceDetail)}>Close</Button>}
                OnHide={() => {
                    setModalAdviceDetail(!ModalAdviceDetail)
                    setActionStatus(0)
                }}
                Maximizable={true}
                Width={50}
                Height={50}
                Maximized={20}
                Content={
                    <React.Fragment>
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <h6 className="font-bold">บทเรียนนี้สำหรับ</h6>
                                    {<p className="mt-2" dangerouslySetInnerHTML={{ __html: !isEmpty(DataCourseDetailSub.advice) ? DataCourseDetailSub.advice : "ไม่มีข้อมูล" }} />}
                                </Col>
                            </Row>
                        </Container>
                    </React.Fragment>
                }
            />
            <DialogModal
                Header={""}
                style={{ lineHeight: '1.0' }}
                Open={modalAdd_Lesson}
                PlayVdeio={<Button className='p-button-danger' onClick={() => setModalAdd_Lesson(!modalAdd_Lesson)}>Close</Button>}
                OnHide={() => {
                    setModalAdd_Lesson(!modalAdd_Lesson)
                    setActionStatus(0)
                }}
                Maximizable={true}
                Width={100}
                Height={100}
                Maximized={800}
                Content={
                    <React.Fragment>
                        {
                            //!isEmpty(fileViewTpyeFile) && fileViewTpyeFile === "image/jpeg" || fileViewTpyeFile === "image/png" ? <img src={fileViewURL} width="100%" height="600" ></img> : <ReactPlayer url={fileViewURL} progressInterval={1000} controls={true} muted={false} playing={true} width="100%" height="100%" ></ReactPlayer>
                            !isEmpty(fileViewTpyeFile) && fileViewTpyeFile === 'application/pdf' ?
                                <ViewLeanningPDF UserCourseStudySubID={watch('UserCourseStudySubID')} setModal={setModalAdd_Lesson} />
                                :
                                <ViewLeanningVideo UserCourseStudySubID={watch('UserCourseStudySubID')} setModal={setModalAdd_Lesson} />
                        }
                    </React.Fragment>
                }
            />
            <DialogModal
                Header={""}
                style={{ lineHeight: '1.0' }}
                Open={modalAdd_PDF}
                PlayVdeio={<Button className='p-button-danger' onClick={() => setModalAdd_PDF(!modalAdd_PDF)}>Close</Button>}
                OnHide={() => {
                    setModalAdd_PDF(!modalAdd_PDF)
                    setActionStatus(0)
                }}
                Maximizable={true}
                Width={100}
                Height={100}
                Maximized={800}
                Content={
                    <React.Fragment>
                        {
                            // !isEmpty(fileViewTpyeFile) && fileViewTpyeFile !== 'application/pdf' ?
                            !watch('CheckFileCourseStudySubFileUpload') ?
                                <div className="col-md-12 text-center">
                                    <img src="\showcase\icon\Icon_PDF.png" width="500" className="text-center" />
                                </div>
                                :
                                <>
                                    {
                                        fileViewURL &&
                                        <div className="flex flex-column card-container w-full">
                                            <div className="flex align-items-center justify-content-center m-2 pdf-container">
                                                <Document
                                                    file={fileViewURL}
                                                    // file={URL.createObjectURL(fileViewURL)}
                                                    onLoadSuccess={onDocumentLoadSuccess}
                                                    onContextMenu={(e) => e.preventDefault()}
                                                    options={options}
                                                >
                                                    <div className="mt-2">
                                                        <Page pageNumber={pageNumber} />
                                                    </div>
                                                </Document>
                                            </div>
                                            <div className="flex align-items-center justify-content-center m-2">
                                                <p>หน้า {pageNumber} จาก {numPages}</p>
                                            </div>
                                            <div className="flex align-items-center justify-content-center m-2">
                                                <Button
                                                    label="ก่อนหน้า"
                                                    className="p-button-raised p-button-danger p-button-sm mt-3 mr-2"
                                                    onClick={prevPage}
                                                    disabled={pageNumber === 1}
                                                />
                                                <Button
                                                    label="ถัดไป"
                                                    className="p-button-raised p-button-warning p-button-sm mt-3 mr-2"
                                                    onClick={nextPage}
                                                    disabled={pageNumber > numPages || pageNumber === numPages}
                                                />
                                                {
                                                    pageNumber === numPages && +StudyTypeTest.postStudyTest === 1 && +StudyTypeTest.postTestStatus <= 1 &&
                                                    <Button
                                                        label="สอบหลังเรียน"
                                                        className="p-button-raised p-button-success p-button-sm mt-3 mr-2"
                                                        onClick={() => ShowCourseStudySubPostStudyTest(CourseStudySubID)}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    }
                                </>
                        }
                    </React.Fragment>
                }
            />
            <div data-aos="fade-right">
                <Container className="mt-3">
                    <h4>
                        <p dangerouslySetInnerHTML={{ __html: !isEmpty(DataProfileTitle.title) ? DataProfileTitle.title : "ไม่มีข้อมูล" }} />
                    </h4>
                    <Row>
                        <Col md={6}>
                            <Card className="mr-3">
                                {Files && <img src={Files !== null || Files !== undefined ? URL.createObjectURL(Files) : "/showcase/images/SF_Cinema_logo.jpg"} width={"100%"} />}
                            </Card>
                        </Col>
                        <Col md={6} className="m-0">
                            <Card
                                title={<h6 className="font-bold">รายละเอียด</h6>}
                                className={`mt-1 h-16rem w-auto`}
                            >
                                {!isEmpty(DataCourseDetailSub.detail) ? <p style={TextNowrap} className="text-nowraps" dangerouslySetInnerHTML={{ __html: DataCourseDetailSub.detail.substring(0, 300) + " ..." }} /> : 'ไม่พบข้อมูล'}
                                <button className="btn btn-link float-left" onClick={() => setModalAdd_DetailSub(!modalAdd_DetailSub)}>อ่านเพิ่มเติม</button>
                            </Card>
                            <Card
                                title={<h6 className="font-bold">บทเรียนนี้สำหรับ</h6>}
                                // className="mt-1 h-13rem w-auto"
                                className={`mt-1 ${window.innerWidth <= 1366 ? 'h-9rem' : 'h-13rem'}  w-auto`}
                            >
                                {!isEmpty(DataCourseDetailSub.advice) ? <p style={TextNowrapAdvice} className="text-nowraps" dangerouslySetInnerHTML={{ __html: DataCourseDetailSub.advice }} /> : 'ไม่พบข้อมูล'}
                                {!isEmpty(DataCourseDetailSub.advice) && DataCourseDetailSub.advice.length >= 200 && <button className="btn btn-link float-left" onClick={() => setModalAdviceDetail(!ModalAdviceDetail)}>อ่านเพิ่มเติม</button>}
                            </Card>
                        </Col>
                        <div className="table-responsive mt-3">
                            <table className="table table-hover">
                                <thead style={{ backgroundColor: '#7B7B7B' }}>
                                    <tr>
                                        <th colSpan={20}>
                                            <h5 className="text-light mt-2">บทเรียนเรื่อง {DataProfileTitle.title}</h5>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        DataCourseStudySub.length > 0 ? DataCourseStudySub.flatMap((item, index) => {
                                            // item.checkExpireDate = false
                                            let _CheckSuccess = false
                                            let _CheckExpireDate = false
                                            switch (+item.actionStatus) {
                                                case 0:
                                                case 1:
                                                    break
                                                case 2:
                                                    if (item.preStudyTest === 0 && item.postStudyTest === 0) {
                                                        _CheckSuccess = true
                                                    } else {
                                                        if (item.postStudyTest === 1 && item.postTestStatus === 2) {
                                                            _CheckSuccess = true
                                                        }
                                                    }
                                                    break
                                            }

                                            return (
                                                <tr key={item.userCourseStudySubID} onClick={() => {
                                                    if (+item.learningStatus === 0) {
                                                        Swal.fire({ title: `กรุณาเรียนรู้บทเรียนลำดับที่ ${item.sort - 1} ให้สำเร็จ`, icon: 'warning' })
                                                    } else {
                                                        CheckCourseStudy(item, index)
                                                    }
                                                }}>
                                                    <th colSpan={100} className="m-0 p-3 cursor-pointer">
                                                        <div className="row col-md-12">
                                                            <div className="col-md-8 text-left">
                                                                {_CheckSuccess ? <img src="\showcase\accept.png" width={20} height={20} title="ผ่าน" /> : <img src="\showcase\icon\open-book.png" width={20} height={20} />}
                                                                {`  บทที่ ${item.episode} ชื่อบทเรียน ${item.title}`}
                                                            </div>
                                                            <div className="col-md-4 text-right">
                                                                {
                                                                    item.actionStatus === 2 && (item.preStudyTest === 0 || item.postStudyTest === 0) || (item.preStudyTest === 1 && item.postStudyTest === 1 && item.postTestStatus === 2) || (item.postStudyTest === 1 && item.postStudyTestStatus === 2) ?
                                                                        <React.Fragment>
                                                                            {
                                                                                !isEmpty(item.courseStudySubFileUpload) && item.courseStudySubFileUpload.filetype === 'application/pdf' && item.typeFile === 1 ?
                                                                                    <img src="\showcase\icon\upload.png" title="บทเรียนแบบ pdf" className="cursor-pointer" alt="Card" width={20} height={20} onClick={() => CheckCourseStudy(item, index)} />
                                                                                    :
                                                                                    !isEmpty(item.courseStudySubFile) && item.typeFile === 99 ?
                                                                                        <React.Fragment>
                                                                                            <img src="\showcase\icon\play-button.png" title="บทเรียนแบบ video" className="cursor-pointer" alt="Card" width={20} height={20} onClick={() => CheckCourseStudy(item, index)} />
                                                                                            {" "}{+item.duration > 0 && !isEmpty(item.courseStudySubFile) && item.typeFile === 99 ? <span className="ml-2">{SecondToHHMMSSFormat(Number(item.duration).toFixed(0), false)}</span> : null}
                                                                                        </React.Fragment> : null
                                                                            }
                                                                        </React.Fragment>
                                                                        :
                                                                        <React.Fragment>
                                                                            {
                                                                                !item.checkExpireDate ?
                                                                                    <h6 className="text-danger">หมดเวลาการเรียนรู้</h6>
                                                                                    :
                                                                                    !isEmpty(item.courseStudySubFileUpload) && item.courseStudySubFileUpload.filetype === 'application/pdf' && item.typeFile === 1 ?
                                                                                        <img src="\showcase\icon\upload.png" title="บทเรียนแบบ pdf" className="cursor-pointer" alt="Card" width={20} height={20} onClick={() => CheckCourseStudy(item, index)} /> :
                                                                                        !isEmpty(item.courseStudySubFile) && item.typeFile === 99 &&
                                                                                        <React.Fragment>
                                                                                            <img src="\showcase\icon\play-button.png" title="บทเรียนแบบ video" className="cursor-pointer" alt="Card" width={20} height={20} onClick={() => CheckCourseStudy(item, index)} />
                                                                                            {" "}{+item.duration > 0 && !isEmpty(item.courseStudySubFile) && item.typeFile === 99 ? <span className="ml-2">{SecondToHHMMSSFormat(Number(item.duration).toFixed(0), false)}</span> : null}
                                                                                        </React.Fragment>
                                                                            }
                                                                        </React.Fragment>
                                                                }
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            )
                                        }) : <tr><td colSpan={100} className="text-center text-danger">ไม่พบข้อมูล</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default React.memo(Learn)