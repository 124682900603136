import React from "react";
import { Card } from 'primereact/card'
import { Container } from 'react-bootstrap';
import { http ,DateThai} from "../../Utils";
import { Image } from 'primereact/image';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom'

const NewsDetail = () => {

    const [DataNews, setDataNews] = React.useState([])
    const { NewsID } = useParams()
    React.useEffect(() => {

        (async () => {
            await GetDetailSub()
        })()

    }, [])
    const GetDetailSub = async () => {
        try {
            const res = await http.post("/News/FindBy/" + NewsID)
            if (res.status === 200 && res.data.status) {
                // setDatabanner(res.data)
                setDataNews(res.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const DataNewsnewsTitle = () => {
        return (
            <React.Fragment>
                <div dangerouslySetInnerHTML={{ __html: DataNews.newsTitle !== undefined && DataNews.newsTitle !== null && DataNews.newsTitle !== "" ? DataNews.newsTitle : "ไม่มีข้อมูล" }} />
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <Container className="mt-3">
                <Card className="p-m-2">
                    <h3> < DataNewsnewsTitle /></h3>
                    <Row>
                        <Col md={2}>
                            <p className="m-0">
                                <Image src={DataNews.newsFile !== '' && DataNews.fileUpload !== null && DataNews.fileUpload !== undefined ? DataNews.fileUpload : "/showcase/images/SF_Cinema_logo.jpg"} width={"100%"} height={"150"} preview ></Image>
                                <p className="m-0">
                                    {DateThai(DataNews.createDate)}
                                </p>
                            </p>
                        </Col>
                        <Col md={10}>
                            <p className="m-0">
                                <div dangerouslySetInnerHTML={{ __html: DataNews.newsDetailSub !== undefined && DataNews.newsDetailSub !== null && DataNews.newsDetailSub !== "" ? DataNews.newsDetailSub : "ไม่มีข้อมูล" }} />
                            </p>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </React.Fragment >
    )
}
export default React.memo(NewsDetail)