import { format } from 'date-fns'
import axios from 'axios'
import Config from '../config.json'
import Swal from 'sweetalert2'
import React from 'react'
/**
 * @method isEmpty
 * @param {String | Number | Object} value
 * @returns {Boolean} true & false
 * @description this value is Empty Check
 */

export const isEmpty = (value) => {
    if (value === null)
        return true
    else if (typeof value !== 'number' && value === '')
        return true
    else if (typeof value === 'undefined' || value === undefined)
        return true
    else if (value !== null && typeof value === 'object' && !Object.keys(value).length)
        return true
    else
        return false
}

export const ConvertDateUTC = (date) => {
    if (!isEmpty(Date.parse(date)))
        return format(date, `yyyy-MM-dd'T'HH:mm:ss'Z'`)
    else
        return null
}

export const dataUrlToFile = async (dataUrl, fileName) => {

    const res = await fetch(dataUrl)

    const blob = await res.blob()

    return new File([blob], fileName, { type: 'image/png' })
}

export const PadString = (value, amount = 4) => {

    const result = value.toString().padStart(amount, 0)
    // console.log(data)
    return result
}

export const comas = (value = 0) => value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

export const http = axios.create({
    //baseURL: window.location.hostname === 'localhost' || window.location.host === '127.0.0.1' ? 'http://localhost:8886' : Config.API_URL,
    baseURL: window.location.hostname === 'localhost' || window.location.host === '127.0.0.1' ? 'http://localhost:8886' : window.location.protocol + '//' + window.location.hostname + '/api',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
})

export const DateThai = (date, formatHHMMSS = false) => {

    if (isEmpty(date)) return '-'

    const _date = new Date(date)
    if (formatHHMMSS) {
        return _date.toLocaleDateString('th-TH', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        })
    }
    return _date.toLocaleDateString('th-TH', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })
}

export const CheckVideoType = (FileType) => {
    const _VideoType = ['video/mp4', 'video/ogg', 'video/webm', 'video/avi', 'video/mpeg']
    return _VideoType.includes(FileType)
}

export const CheckImageType = (FileType) => {
    const _ImageType = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']
    return _ImageType.includes(FileType)
}

export const GetSessionData = (keys, type = 1) => {
    const _data = sessionStorage.getItem(keys)
    return type === 1 ? _data : JSON.parse(_data)
}

export const ErrorHandle = (err) => {

    if (err.code === 'ERR_NETWORK') return Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: err.code
    }).then((result) => {
        if (result.isConfirmed) {
            if (sessionStorage.length === 0) return
            sessionStorage.clear()
            window.location.href = '/'
        }
    })

    if (err.response) {
        if (err.response.status === 401) {
            Swal.fire({
                icon: 'error',
                title: 'Session หมดอายุ กรุณาเข้าสู่ระบบใหม่',
                confirmButtonText: 'ตกลง',
            }).then((result) => {
                if (result.isConfirmed) {
                    sessionStorage.clear()
                    window.location.href = '/'
                }
            })
            return
        }

        if (err.response.status === 403 || err.response.status === 500 || err.response.status === 504) {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
                text: err.response.data.msg || err.message,
                confirmButtonText: 'ตกลง'
            }).then((result) => {
                if (result.isConfirmed) {
                    sessionStorage.clear()
                    window.location.href = '/'
                }
            })
            return
        }

        if (err.response.status === 404) {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: err.response.data.msg || err.message,
                confirmButtonText: 'ตกลง'
            })
            return
        }

        Swal.fire({ icon: 'error', title: 'เกิดข้อผิดพลาด', text: err.response.data.msg || err.message, confirmButtonText: 'ตกลง' })
    }
}

export const ArrayObjectRandom = (DataArray = []) => {
    return DataArray.sort(() => Math.random() - 0.5)
}

export const truncateText = (text, maxLength = 90) => {
    if (text.length > maxLength)
        return text.slice(0, maxLength) + "..."
    else
        return text
}

export const NewLineText = (str, maxLength = 100) => {
    if (str.length <= maxLength) { return str; }
    let result = '';
    let i = 0;
    while (i < str.length) {
        result += str.substr(i, maxLength) + '<br>';
        i += maxLength;
    }
    return result;
}

export const stripHtml = (html) => {
    // Create a new DOMParser
    const parser = new DOMParser();

    // Parse the HTML string and extract the text content
    const stripped = parser.parseFromString(html, 'text/html').body.textContent;

    // Return the stripped string
    return stripped;
}

export const DateDiff = (date1, data2) => {
    const _date1 = new Date(date1)
    const _date2 = new Date(data2)
    const diffTime = Math.abs(_date2 - _date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays
}

export const ScrollTop = () => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })

export const SecondToHHMMSS = (seconds = 0) => {
    const date = new Date(null)
    date.setSeconds(seconds)
    const result = date.toISOString().substr(11, 8)
    return result
}

export const SecondToHHMMSSFormat = (seconds = 0, HH = true, MM = true, SS = true) => {

    let hours = Math.floor(seconds / 3600)
    let minutes = Math.floor((seconds - (hours * 3600)) / 60)
    let sec = seconds - (hours * 3600) - (minutes * 60)

    if (hours < 10) hours = "0" + hours
    if (minutes < 10) minutes = "0" + minutes
    if (sec < 10) sec = "0" + sec

    return `${HH ? hours + ':' : ''}${MM ? minutes : ''}${SS ? ':' + sec : ''}`
}

export const ReplaceNewLine = (text) => {
    if (isEmpty(text)) return ''
    return text.replace(/(?:\r\n|\r|\n)/g, '<br>')
}

export const ToHHMMSS = (sec_num = 0) => {
    let date = new Date(0)
    date.setSeconds(sec_num)
    return date.toISOString().substring(11, 19)
}
