import React from 'react'
import { Input, Button } from '@material-ui/core'

const SearhFilter = ({ onFilter }) => {

    const [_FilterText, setFilterText] = React.useState('')
    const onFilterTextChange = (value) => {
        setFilterText(value)
        onFilter(value)
    }

    const onClear = () => {
        setFilterText('')
        onFilter('')
    }

    return (
        <React.Fragment>
            <span className="p-input-icon-left">
                <Input
                    className="text-sm"
                    value={_FilterText}
                    onChange={(e) => onFilterTextChange(e.target.value)}
                    placeholder="ค้นหา"
                />
            </span>
            {
                _FilterText && <Button onClick={onClear} variant={"contained"} color={"secondary"} title={"เครียร์"}> <i className={"pi pi-times"} /></Button>
            }
        </React.Fragment>
    )
}
export default React.memo(SearhFilter)
