import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Row } from 'react-bootstrap'
import { Document, Page, pdfjs } from 'react-pdf'
import Swal from 'sweetalert2'
import { useForm } from 'react-hook-form'
import { Button } from 'primereact/button'
import { http, isEmpty, ErrorHandle, ToHHMMSS } from '../../Utils'
import ReactPlayer from 'react-player/lazy'

import ViewLeanningPDF from '../ViewLearningPDF'
import { set } from 'date-fns'

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: '../../../public/fonts/SMARNFILM-Black.otf',
}

const StylePDF = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const ViewLearning = (props) => {

    const { UserCourseStudySubID, setModal } = props

    const [_UserCourseStudySubID, setUserCourseStudySubID] = React.useState(null);
    const RedirectTo = useNavigate()

    const { register, handleSubmit, reset, setValue, watch, control, formState: { errors } } = useForm()

    const [CourseStudyID, setCourseStudyID] = React.useState();

    const [NextPostTest, setNextPostTest] = React.useState(false)
    const [NextPreTest, setNextPreTest] = React.useState(false)
    const [NextLearning, setNextLearning] = React.useState(false)
    const [NextActionStatus, setNextActionStatus] = React.useState(false)
    const [numPages, setNumPages] = React.useState(1)
    const [pageNumber, setPageNumber] = React.useState(1)
    const [Files, setFiles] = React.useState(null)
    const _RefVideo = React.useRef()
    const [PathVideo, setPathVideo] = React.useState(null)
    const [PlayerState, setPlayState] = React.useState(0)
    const [FileType, setFileType] = React.useState(0)
    const [EndLearning, setEndLearning] = React.useState(false)
    const [LearnState, setLearnState] = React.useState(false)
    const [isLoadingVideo, setIsLoadingVideo] = React.useState(false)

    React.useEffect(() => {

        if (isEmpty(UserCourseStudySubID)) return
        if (_UserCourseStudySubID !== null) return;
        (async () => {
            await getUserCourseStudySub(UserCourseStudySubID)
        })()

    }, [UserCourseStudySubID])

    const getUserCourseStudySub = async (RUserCourseStudySubID) => {
        // console.log(`getUserCourseStudySub ID =>`, RUserCourseStudySubID);
        try {

            const _res = await http.post(`/UserCourseStudySub/FindBy/${RUserCourseStudySubID}`)
            // console.log(`getUserCourseStudySub Get data`, _res)
            if (_res.status === 200 && _res.data.status) {
                // await UserCheckCourseSuccess(_res.data.data.userCourseStudySubID)
                reset()
                setLearnState(false)
                setNextActionStatus(false)
                setNextLearning(false)
                setNextPreTest(false)
                setNextPostTest(false)
                setEndLearning(false)

                setCourseStudyID(_res.data.data.courseStudyID)
                for (const Key in Object.keys(_res.data.data)) {
                    const _Key = Object.keys(_res.data.data)[Key]
                    const _Value = Object.values(_res.data.data)[Key]
                    setValue(_Key.charAt(0).toUpperCase() + _Key.slice(1), _Value)
                }

                setValue('_ActionStatus', _res.data.data.actionStatus)
                // console.log(_res.data.data);
                if (_res.data.data.nextCourseStudySubID != null && _res.data.data.preTest === 0 && _res.data.data.postTest === 0) {
                    setNextLearning(true)
                    setNextActionStatus(true)
                }
                if (_res.data.data.actionStatus === 2) {
                    setLearnState(true)
                }
                if (_res.data.data.nextCourseStudySubID === null) {
                    setEndLearning(true)
                    if (_res.data.data.NextUserCourseStudySub === 0) setEndLearning(false)
                }
                if (_res.data.data.postTest === 1 && _res.data.data.postTestStatus !== 2) {
                    setLearnState(false)
                    setNextPostTest(false)
                    setNextLearning(false)
                    setNextActionStatus(false)
                }

                if (_res.data.data.preTest === 1 && _res.data.data.preTestStatus !== 2) setNextPreTest(true)

                if (!isEmpty(_res.data.data.courseStudySubFileUpload) && _res.data.data.courseStudySubFileUpload.filetype.includes('pdf')) {

                    setFileType(1)
                    if (!isEmpty(_res.data.data.courseStudySubFileUpload)) {
                        await http.get(_res.data.data.courseStudySubFileUpload.fileurl, { responseType: 'blob' }).then(pdfres => {
                            if (pdfres.status === 200) {
                                setFiles(pdfres.data);
                                setFileType(1);
                            }
                        })
                    }

                    await UpdateViewCourseStudySub(_res.data.data.courseStudySubID)
                }

                //เช็คจบหลักสูตร
                await UserCheckCourseSuccess(_res.data.data.userCourseStudySubID)

                if ((!isEmpty(_res.data.data.courseStudySubFileUpload) && _res.data.data.courseStudySubFileUpload.filetype.includes('video')) || (!isEmpty(_res.data.data.courseStudySubFile) && _res.data.data.typeFile === 99)) {
                    // console.log(`fileurl =>`, _res.data.data.courseStudySubFileUpload.fileurl)
                    setFileType(2);
                    const _FileCND = await onSearchCDN(_res.data.data.courseStudySubFile)
                    if (_FileCND.status) {
                        // console.log(_FileCND)
                        setPathVideo(_FileCND.fileUrl)
                        if (_res.data.data.actionStatus !== 2 && _res.data.data.currentTime > 0) {
                            handlePlay(_res.data.data.currentTime)
                        } else {
                            _RefVideo.current.seekTo(0, 'seconds')
                        }
                    }
                }
            }
        } catch (err) { ErrorHandle(err) }
    }

    const UserCheckCourseSuccess = async (UserCourseStudySubID = '') => {
        if (!isEmpty(UserCourseStudySubID)) {
            try {
                const res = await http.post(`UserCourseStudy/UserCheckCourseSuccess/${UserCourseStudySubID}`)
                if (res.status === 200) {
                    if (res.data.status === true) setEndLearning(false)
                }
            } catch (err) { ErrorHandle(err) }
        }
    }

    const onSearchCDN = async (courseStudySubFile) => {
        try {
            setIsLoadingVideo(true)
            const _res = await http.post('/File/ServiceCDN', { CDN_ID: courseStudySubFile, Session: sessionStorage.getItem('sessionToken') }, { headers: { 'Content-Type': 'multipart/form-data' } })
            if (_res.status === 200 && _res.data.status) {
                setIsLoadingVideo(false)
                return _res.data.data
            }

        } catch (err) {
            ErrorHandle(err)
            setIsLoadingVideo(false)
        }
    }

    const onProgress = async (state) => {
        PlayeBackSpeed()
        if (ToHHMMSS(state.playedSeconds) === '00:00:30') await UpdateViewCourseStudySub(watch('CourseStudySubID'))
        setValue('PlayState', parseInt(state.playedSeconds))
        if (state.playedSeconds > PlayerState) setPlayState(state.playedSeconds)
        if (!isEmpty(watch('ActionStatus')) && +watch('ActionStatus') < 2) await UpdateCurrentTimeDuration(state.playedSeconds)
        if (state.playedSeconds === state.loadedSeconds) return
        // console.log(state);
    }

    const UpdateCurrentTimeDuration = async (duration) => {

        if (watch('ActionStatus') === 2 && watch('CurrentTime') > 0) return
        // setLearnState(false)
        // setNextActionStatus(false)
        // setNextLearning(false)
        try {
            // const _res = await http.post(`UserCourseStudySub/UpdateCurrentTimeDuration/${UserCourseStudySubID}`, { CurrentTimeDuration: duration }, { headers: { 'Content-Type': 'multipart/form-data' } })
            const _res = await http.post(`UserCourseStudySub/UpdateCurrentTimeDuration/${watch(`UserCourseStudySubID`)}`, { CurrentTimeDuration: parseInt(duration) }, { headers: { 'Content-Type': 'multipart/form-data' } })
            if (_res.status === 200 && _res.data.status) {
                // console.log(`Current Data =>`, _res.data)
                // console.log(`nextUserCourseStudySub =>`, _res.data.nextUserCourseStudySub)
                //เช็คเงื่อนไข
                setValue('_ActionStatus', _res.data.data.actionStatus)
                if (_res.data.data.actionStatus === 2 && !isEmpty(_res.data.nextUserCourseStudySub)) {
                    //มีสอบหลังเรียน
                    if (_res.data.data.postTest === 1 && _res.data.data.postTestStatus !== 2) {
                        setLearnState(true)
                        setNextPostTest(true)
                        // setModal(false);
                        // RedirectTo(`/PostTestExamination/${_res.data.data.courseStudySubId}`)
                    }
                    //บทถัดไปไม่มีสอบหลังเรียน
                    if (_res.data.data.postTest === 0) {
                        // setValue('ActionStatus', _res.data.data.actionStatus)
                        setLearnState(true)
                        setNextActionStatus(true)
                        setNextLearning(true)
                        // await CheckCourseStudySub(_res.data.nextUserCourseStudySub)
                    }
                }
                if (_res.data.data.actionStatus === 2 && isEmpty(_res.data.nextUserCourseStudySub)) {
                    //ยืนยันการเรียนสำเร็จ
                    setLearnState(true)
                    if (_res.data.data.postTest === 1 && _res.data.data.postTestStatus !== 2) {
                        setNextPostTest(true)
                        //มีสอบหลังเรียน
                        if (_res.data.data.postTest === 1 && _res.data.data.postTestStatus !== 2) {
                            setNextPostTest(true)
                        }
                    }
                }
            }
        } catch (err) { ErrorHandle(err) }
    }

    const onEnded = async (state) => {
        // await UpdateUserCourseStudySubAction();
        try {

            const _res = await http.post(`/UserCourseStudySub/FindBy/${watch('UserCourseStudySubID')}`)
            if (_res.status === 200 && _res.data.status) {
                UpdateCurrentTimeDuration(_res.data.duration)
                if (_res.data.data.actionStatus === 2 && _res.data.data.postTest === 1 && _res.data.data.postTestStatus === 0) {
                    // setLearnState(true)
                }
                // CheckCourseStudySub(_res.data.data)
            }
        } catch (err) { ErrorHandle(err) }

        // const _res = await http.post(`UserCourseStudySub/UpdateActionStatus/${UserCourseStudySubID}`, { ActionStatus: 2 }, { headers: { 'Content-Type': 'multipart/form-data' } })
        // if (_res.status === 200 && _res.data.status) {
        //     setPageNumber(1)
        //     if (!isEmpty(_res.data.nextUserCourseStudySub)) {
        //         await NextCourseStudySub();
        //     }
        // }
        // setLearnState(true);
    }

    const onSeek = async (state) => {
        if (PlayerState < state) {
            _RefVideo.current?.seekTo(PlayerState, 'seconds')
        }
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages)
        if (numPages === 1) {
            setLearnState(true);
            if (watch(`PostTest`) === 0) {
                setLearnState(true);
            } else {
                setNextPostTest(true);
            }
        }
    }

    const handleNextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
            if ((pageNumber + 1) === numPages) {
                setLearnState(true);
                if (watch(`PostTest`) === 0) {
                    setLearnState(true);
                } else {
                    setNextPostTest(true);
                }
            }
        }

    }

    const handlePrevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1)
        }
    }

    const CourseStudySubPreStudyTest = () => {
        if (isEmpty(watch('CourseStudySubID'))) return
        Swal.fire({
            title: 'จะเข้าสู่บททดสอบก่อนเรียนละน้า',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก'
        }).then(async (result) => {
            if (!result.isConfirmed) return
            // RedirectTo(`/PreTestExamination/${watch('NextCourseStudySubID')}`)
            window.location = '/PreTestExamination/' + watch('CourseStudySubID');
        })
    }

    const CourseStudySubPostStudyTest = () => {

        if (isEmpty(watch('CourseStudySubID'))) return
  
        Swal.fire({
            title: 'จะเข้าสู่บททดสอบหลังเรียนละน้า',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก'
        }).then(async (result) => {
            if (!result.isConfirmed) return

            await http.post(`UserCourseStudySub/UpdateActionStatus/${watch('UserCourseStudySubID')}`, { ActionStatus: 2 }, { headers: { 'Content-Type': 'multipart/form-data' } }).then(_res => {
                // console.log(_res);
                if (_res.status === 200) {
                    // console.log(watch('CourseStudySubID'));
                    window.location = '/PostTestExamination/' + watch('CourseStudySubID');
                    //RedirectTo(`/PostTestExamination/${watch('CourseStudySubID')}`)
                }
            });

        })
    }

    const UpdateUserCourseStudySubAction = async () => {
        // if (+watch('ActionStatus') === 0 || isEmpty(watch('UserCourseStudySubID'))) return
        try {

            const _res = await http.post(`UserCourseStudySub/UpdateActionStatus/${watch('UserCourseStudySubID')}`, { ActionStatus: 2 }, { headers: { 'Content-Type': 'multipart/form-data' } })
            if (_res.status === 200 && _res.data.status) {
                // console.log(`nextUserCourseStudySub`, _res.data.nextUserCourseStudySub)
                Swal.fire('เรียบร้อย', 'ยืนยันการเรียนสำเร็จ', 'success').then((ok) => {
                    setValue('ActionStatus', 2)
                    // if (ok) {
                    //     // return window.location.href = `/Learn/${_res.data.courseStudyID}`
                    // }
                });
                if (isEmpty(_res.data.nextUserCourseStudySub)) {
                    setNextActionStatus(true)
                } else {
                    // setNextActionStatus(true)
                    await CheckCourseStudySub(_res.data.nextUserCourseStudySub)
                }
                setValue('NextCourseStudyID', _res.data.nextUserCourseStudySub.courseStudyId)
                setValue('NextCourseStudySubID', _res.data.nextUserCourseStudySub.courseStudySubId)
                setValue('NextUserCourseStudyID', _res.data.nextUserCourseStudySub.userCourseStudyId)
                setValue('NextUserCourseStudySubID', _res.data.nextUserCourseStudySub.userCourseStudySubId)
                setValue('NextSort', _res.data.nextUserCourseStudySub.sort)
                //PostTest
                setValue('NextPostTest', _res.data.nextUserCourseStudySub.postTest)
                setValue('NextPostTestStatus', _res.data.nextUserCourseStudySub.postTestStatus)
                setValue('NextPostTestCorrectScore', _res.data.nextUserCourseStudySub.postTestCorrectScore)
                setValue('NextPostTestWrongScore', _res.data.nextUserCourseStudySub.postTestWrongScore)
                //PreTest
                setValue('NextPreTest', _res.data.nextUserCourseStudySub.preTest)
                setValue('NextPreTestStatus', _res.data.nextUserCourseStudySub.preTestStatus)
                setValue('NextPreTestCorrectScore', _res.data.nextUserCourseStudySub.preTestCorrectScore)
                setValue('NextPreTestWrongScore', _res.data.nextUserCourseStudySub.preTestWrongScore)
                setValue('NextActionStatus', _res.data.nextUserCourseStudySub.actionStatus)
            }
        } catch (err) { ErrorHandle(err) }
    }

    const FinishCourseStudySub = async () => {
        if (isEmpty(CourseStudyID)) return
        try {
            const _res = await http.post(`UserCourseStudy/FinishCourse/${CourseStudyID}`, { Type: 'ViewLearning' }, { headers: { 'Content-Type': 'multipart/form-data' } })
            if (_res.status === 200 && _res.data.status) {
                Swal.fire('เรียบร้อย', 'ยืนยันการจบหลักสูตรสำเร็จ', 'success').then((ok) => {
                    if (ok) {
                        // return RedirectTo(`/CourseProgress/${CourseStudyID}`)
                        return window.location.href = `/CourseProgress/${CourseStudyID}/2`
                    }
                });
            }
        } catch (err) { ErrorHandle(err) }
    }

    const handlePlay = React.useCallback((CurrentTime) => {
        setPlayState(0);
        Swal.fire({
            icon: 'info',
            title: 'ต้องการเล่นวิดีโอต่อจากจุดที่หยุดไว้หรือไม่',
            showCancelButton: true,
            confirmButtonColor: '#22C55E',
            cancelButtonColor: '#EF4444',
            confirmButtonText: 'เล่นต่อ',
            cancelButtonText: 'เริ่มต้น',
        }).then((result) => {

            if (!result.isConfirmed) return _RefVideo.current.seekTo(0, 'seconds')
            setPlayState(CurrentTime);
            _RefVideo.current?.seekTo(CurrentTime, 'seconds')
        })
    }, [UserCourseStudySubID])

    const NextCourseStudySub = async () => {

        if (watch('Sort') <= 0 || isEmpty(watch('CourseStudyID'))) return

        setLearnState(false)
        setNextActionStatus(false)
        setNextLearning(false)
        setNextPreTest(false)
        setNextPostTest(false)

        try {
            // setIsLoading(true)
            // console.log(watch('CourseStudyID') + '|' + watch('CourseStudySubID') + '|' + watch('Sort'));
            const _res = await http.post(`UserCourseStudySub/NextCourseStudySub/${watch('CourseStudyID')}/${+watch('Sort')}`)
            if (_res.status === 200 && _res.data.status) {
                if (isEmpty(_res.data.data)) {
                    return;
                }

                // console.log(_res.data);
                if (_res.data.data.actionStatus === 2 || (_res.data.data.preTest === 0 && _res.data.data.postTest === 0 && _res.data.data.actionStatus === 0)) {
                    // console.log(1);

                    await getUserCourseStudySub(_res.data.data.userCourseStudySubID);
                    return;
                } else {
                    // console.log(2);
                    // console.log(_res.data.data);
                    if (_res.data.data.preTest === 1 && _res.data.data.preTestStatus == 0) {
                        // console.log(21);
                        CheckCourseStudySub(_res.data.data)
                        return
                    }
                    if (_res.data.data.preTest === 0) {
                        // console.log(22);

                        await getUserCourseStudySub(_res.data.data.userCourseStudySubID)
                        return
                    }
                }
            } else {
                clearData()
                window.location.href = `/Learn/${watch('CourseStudyID')}`
                return
            }
        } catch (err) {
            ErrorHandle(err)
            // setIsLoading(false)
        }
    }

    const CheckCourseStudySub = async (data) => {
        console.log(`CheckCourseStudySub =>`,data)
        let _courseStudySubId = data.courseStudySubId;
        if (isEmpty(_courseStudySubId)) {
            _courseStudySubId = data.courseStudySubID;
        }

        let _userCourseStudySubId = data.userCourseStudySubId;
        if (isEmpty(_userCourseStudySubId)) {
            _userCourseStudySubId = data.userCourseStudySubID;
        }
        // ไม่มีสอบ
        setValue('NextCourseStudySubID', null)
        if (+data.preTest === 0 && +data.postTest === 0) {
            // await getUserCourseStudySub(data.userCourseStudySubId)
            return
        }
        // สอบก่อนเรียน
        if (+data.preTest === 1 && +data.actionStatus >= 0) {
            setValue('NextCourseStudySubID', _courseStudySubId)
            // console.log(32);
            if (+data.preTestStatus <= 1) {
                RedirectTo(`/PreTestExamination/${_courseStudySubId}`);
            }

        }
        // สอบหลังเรียน
        if (+data.postTest === 1 && +data.postTestStatus === 0) {
            setValue('NextCourseStudySubID', _courseStudySubId)
            if (+data.postTestStatus <= 1 && +data.actionStatus >= 1) {
                setNextLearning(true)
                RedirectTo(`/PostTestExamination/${_courseStudySubId}`);
                return;
            }
            else {
                // await getUserCourseStudySub(data.userCourseStudySubId)
                return setNextLearning(true)
            }
        }
        setNextLearning(true)
        await getUserCourseStudySub(_userCourseStudySubId)
        // console.log(data);
    }

    const getFile = async (FileURL) => {
        setFiles(null)
        if (isEmpty(FileURL)) return

        try {
            const _res = await http.get(FileURL, { responseType: 'blob' })
            // console.log(_res);
            if (_res.status === 200) setFiles(_res.data)
        } catch (err) {
            console.log(err)
        }
    }

    const clearData = () => {
        reset()
        setModal(false)
    }

    React.useEffect(() => {

        PlayeBackSpeed()

        window.addEventListener('keydown', preventArrowKeys)

        return () => window.removeEventListener('keydown', preventArrowKeys)

    }, [])

    const preventArrowKeys = (e) => {
        if (watch('PlayState') === 0 || isEmpty(watch('PlayState'))) return
        if (watch('ActionStatus') !== 2) {
            if (e.keyCode === 39) {
                e.preventDefault()
                _RefVideo.current?.seekTo(watch('PlayState'), 'seconds')
                setPlayState(watch('PlayState'))
                return
            }
        }


    }

    const UpdateViewCourseStudySub = async (CourseStudySubID) => {
        if (!isEmpty(CourseStudySubID)) {
            try {

                await http.post(`CourseStudy/UpdateViewCourseStudySub/${CourseStudySubID}`)

            } catch (err) { ErrorHandle(err) }
        }
    }

    const PlayeBackSpeed = () => {
        let _video = document.querySelector('video')
        if (isEmpty(_video)) return
        _video.playbackRate = 1
        _video.setAttribute('controlsList', 'noplaybackrate')
        _video.setAttribute('disablePictureInPicture', 'true')
        _video.setAttribute('disableRemotePlayback', 'true')
    }

    // console.log(`=========================================================================================================================`)
    // // console.log(`LearnState =>`, LearnState)
    // console.log(`LearnState ${LearnState} =>`, !LearnState && watch('PreTest') === 1 && watch('PreTestStatus') === 0)
    // // console.log(`EndLearning =>`, EndLearning)
    // // console.log(`PostTest =>`, watch('PostTest'))
    // // console.log(`PostTestStatus =>`, watch('PostTestStatus'))

    // console.log(`NextActionStatus =>`, NextActionStatus)
    // console.log(`NextLearning =>`, NextLearning)
    // console.log(`NextPostTest =>`, NextPostTest)
    // console.log(`NextPreTest =>`, NextPreTest)
    // console.log(`EndLearning =>`, EndLearning)

    // console.log(`จบหลักสูตร =>`, LearnState && EndLearning )

    // console.log(`ยืนยันการเรียนสำเร็จ =>`, LearnState && !NextActionStatus && !NextLearning && !NextPostTest && !NextPreTest)
    // console.log(`สอบหลังเรียน =>`, LearnState && watch('PostTest') === 1 && watch('PostTestStatus') === 0)
    // console.log(`เรียนบทเรียนถัดไป =>`, LearnState && NextActionStatus && NextLearning)
    // console.log(`=========================================================================================================================`)


    // console.log(`Sort => `, watch('Sort'))
    // console.log(`สอบหลังเรียน =>`, LearnState && watch('PostTest') === 1 && watch('PostTestStatus') === 0)
    // console.log(`watch => `, watch())

    return (
        <React.Fragment>
            <div className="flex flex-column w-full">
                {(FileType === 1 ?
                    <>
                        <Row>
                            <div className="flex align-items-center justify-content-center m-2 pdf-container">
                                <Document
                                    file={Files}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    onContextMenu={(e) => e.preventDefault()}
                                    options={options}
                                    StylePDF={StylePDF}
                                >
                                    <div className="mt-2"><Page pageNumber={pageNumber} /></div>
                                </Document>
                            </div>
                        </Row>
                        <Row>
                            <div className="flex align-items-center justify-content-center m-2">
                                <p>หน้า {pageNumber} จาก {numPages}</p>
                            </div>
                        </Row>
                    </>
                    : "")}

                {
                    (FileType === 2 ?
                        <div className="flex align-items-center justify-content-center m-2 pdf-container">
                            {
                                !isLoadingVideo &&
                                <ReactPlayer
                                    id="video"
                                    playsinline
                                    pip={false}
                                    url={PathVideo}
                                    ref={_RefVideo}
                                    width={window.innerWidth >= 1366 ? "70%" : "100%"}
                                    height={window.innerWidth >= 1366 ? "60%" : "100%"}
                                    onProgress={onProgress}
                                    onEnded={onEnded}
                                    onSeek={onSeek}
                                    controls={true}
                                    muted={false}
                                    playing={false}
                                    config={{
                                        file: {
                                            attributes: {
                                                controlsList: 'nodownload',
                                                disablePictureInPicture: true,
                                                disableRemotePlayback: true,
                                            },
                                            attributes: {
                                                controlsList: 'noplaybackrate',
                                                disablePictureInPicture: true,
                                                disableRemotePlayback: true,
                                            },
                                            // forceHLS: true,
                                            hlsOptions: {
                                                enableWorker: true,
                                                enableSoftwareAES: true,
                                                xhrSetup: (xhr, url) => {
                                                    xhr.withCredentials = true
                                                },
                                            }
                                        },
                                    }}
                                />
                            }
                        </div>
                        : "")
                }
                <Row>
                    <div className="flex align-items-center justify-content-center m-2">
                        {
                            //PDF
                            (FileType === 1 ?
                                <>
                                    <Button
                                        label="ก่อนหน้า"
                                        title="ก่อนหน้า"
                                        className="p-button-raised p-button-danger p-button-sm mt-3 mr-2 w-12rem"
                                        onClick={handlePrevPage}
                                        disabled={pageNumber === 1}
                                    />

                                    <Button
                                        label="ถัดไป"
                                        title="ถัดไป"
                                        className="p-button-raised p-button-warning p-button-sm mt-3 mr-2 w-12rem"
                                        onClick={handleNextPage}
                                        disabled={pageNumber >= numPages}
                                    />
                                </>
                                : "")
                        }
                        {
                            //Video
                            (FileType === 2 ?
                                <>
                                </>
                                : "")
                        }


                        {
                            //สอบก่อนเรียน
                            (!LearnState && watch('PreTest') === 1 && watch('PreTestStatus') === 0 ?
                                <>
                                    <Button
                                        label="สอบก่อนเรียน"
                                        title="สอบก่อนเรียน"
                                        className="p-button-raised p-button-success p-button-sm mt-3 mr-2 w-12rem"
                                        onClick={CourseStudySubPreStudyTest}
                                    />
                                </>
                                : "")
                        }

                        {
                            //สอบหลังเรียน
                            (LearnState && watch('PostTest') === 1 && watch('PostTestStatus') === 0 ?
                                <>
                                    <Button
                                        label="สอบหลังเรียน"
                                        title="สอบหลังเรียน"
                                        className="p-button-raised p-button-success p-button-sm mt-3 mr-2 w-12rem"
                                        onClick={CourseStudySubPostStudyTest}
                                    />
                                </>
                                : "")
                        }

                        {
                            //ยืนยันการเรียนสำเร็จ
                            (LearnState && !NextActionStatus && !NextLearning && !NextPostTest && !NextPreTest ?
                                <>
                                    {
                                        isEmpty(watch(`NextCourseStudySubID`)) && watch(`ActionStatus`) !== 2 ?
                                            <Button
                                                label="ยืนยันการเรียนสำเร็จ"
                                                className="p-button-raised p-button-success p-button-sm mt-3 mr-2 w-12rem"
                                                onClick={() => {
                                                    Swal.fire({
                                                        title: 'ยืนยันการเรียนสำเร็จ',
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        confirmButtonColor: '#22C55E',
                                                        cancelButtonColor: '#EF4444',
                                                        confirmButtonText: 'ตกลง',
                                                        cancelButtonText: 'ยกเลิก'
                                                    }).then(async (result) => {
                                                        if (!result.isConfirmed) return
                                                        await UpdateUserCourseStudySubAction()
                                                    })
                                                }}
                                            /> : ""
                                    }
                                </>
                                : "")}

                        {
                            //จบหลักสูตร
                            (
                                LearnState && EndLearning ?
                                    <>
                                        {
                                            isEmpty(watch(`NextCourseStudySubID`)) && watch(`ActionStatus`) === 2 && watch('UserCourseStudyActionStatus') < 2 && isEmpty(watch('UserCourseStudyCompleteDate')) ?
                                                <Button
                                                    label="จบหลักสูตร"
                                                    title="จบหลักสูตร"
                                                    className="p-button-raised p-button-success p-button-sm mt-3 mr-2 w-12rem"
                                                    onClick={() => {
                                                        Swal.fire({
                                                            title: 'ยืนยันการจบหลักสูตร',
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#22C55E',
                                                            cancelButtonColor: '#EF4444',
                                                            confirmButtonText: 'ตกลง',
                                                            cancelButtonText: 'ยกเลิก'
                                                        }).then(async (result) => {
                                                            if (!result.isConfirmed) return
                                                            await FinishCourseStudySub()
                                                        })
                                                    }}
                                                /> : ""
                                        }
                                    </>
                                    : "")
                        }
                        {
                            //เรียนบทเรียนถัดไป
                            (
                                // LearnState && NextActionStatus && NextLearning ?
                                LearnState && NextActionStatus && NextLearning && watch('ActionStatus') < 2 ?
                                    <>
                                        <Button
                                            type="button"
                                            icon="pi pi-chevron-right"
                                            label="เรียนบทเรียนถัดไป"
                                            title="เรียนบทเรียนถัดไป"
                                            className="p-button-raised p-button-warning p-button-sm mt-3 mr-2 w-12rem"
                                            onClick={() => {
                                                Swal.fire({
                                                    title: 'ต้องการเรียนบทเรียนถัดไปหรือไม่',
                                                    icon: 'warning',
                                                    showCancelButton: true,
                                                    confirmButtonText: 'ตกลง',
                                                    cancelButtonText: 'ยกเลิก',
                                                    confirmButtonColor: '#22C55E',
                                                    cancelButtonColor: '#EF4444',
                                                }).then(async (result) => {
                                                    if (!result.isConfirmed) return

                                                    const _res = await http.post(`UserCourseStudySub/UpdateActionStatus/${UserCourseStudySubID}`, { ActionStatus: 2 }, { headers: { 'Content-Type': 'multipart/form-data' } })
                                                    if (_res.status === 200 && _res.data.status) {
                                                        window.location.href = `/learn/${_res.data.courseStudyID}/${!isEmpty(_res.data.nextUserCourseStudySub) ? 1 : 2}`
                                                        return
                                                        setPageNumber(1)
                                                        if (!isEmpty(_res.data.nextUserCourseStudySub)) await NextCourseStudySub()
                                                    }
                                                    // await NextCourseStudySub()
                                                    // await UpdateUserCourseStudySubAction()
                                                    //clearData()
                                                })
                                            }}
                                        />
                                    </>
                                    : "")
                        }
                    </div>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default React.memo(ViewLearning)