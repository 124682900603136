
import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom"
import { isEmpty, http, ErrorHandle } from "./Utils"
import AOS from 'aos'
import Swal from 'sweetalert2'

import AllContent from './pages/Alllessons'
import Learn from './pages/Learn'
import PreTestExamination from './pages/Examination/PreTestExamination'
import PostTestExamination from './pages/Examination/PostTestExamination'
import News from './pages/News'
import History from './pages/History'
import CourseProgress from './pages/History/CourseProgress'
import Accomplishments from './pages/Accomplishments'

import Index from './pages/Index'
import ProfileUser from './pages/ProfileUser/index'
import NewsDetail from './pages/News/NewsDetail'
import NotFound from './pages/404'
import CourseStudyContinue from './pages/CourseStudyContinue'
import CourseStudyReview from './pages/CourseStudyReview'

import './App.css'

const Layout = React.lazy(() => import('./Components/Layout'))
const Login = React.lazy(() => import('./pages/Login'))

const App = () => {

    const [isLogin, setIsLogin] = React.useState(false)
    const _obj = { interval: 0, ms: 30000 }

    AOS.init({
        duration: 0,
        easing: "ease-in-out",
        once: true,
        mirror: false,
    })

    React.useEffect(() => {

        const sessionToken = sessionStorage.getItem('sessionToken')
        const data = sessionStorage.getItem('data')
        //   AutoLogin()
        if (!isEmpty(data) || !isEmpty(sessionToken)) {
            // http.defaults.headers.common['Authorization'] = `Bearer ${sessionToken}`
            http.defaults.headers.common['Authen-Token'] = sessionToken
            setIsLogin(true)
        } else {
            //   AutoLogin()
            setIsLogin(false)
            sessionStorage.clear()
            clearInterval(_obj.interval)
        }

        if (!isLogin && isEmpty(sessionToken)) return

        _obj.interval = setInterval(() => CheckSession(), _obj.ms)
        return () => clearInterval(_obj.interval)

    }, [isLogin])

    // Swal.fire({
    //     icon: 'error',
    //     text: 'Session Expire กรุณาเข้าระบบใหม่',
    //     backdrop: true,
    //     allowEscapeKey: true,
    //     allowOutsideClick: () => {
    //         sessionStorage.clear()
    //         setIsLogin(false)
    //     }
    // }).then(() => {
    //     sessionStorage.clear()
    //     setIsLogin(false)
    // })
    const CheckSession = React.useCallback(async () => {
        try {

            const _res = await http.post('/Session', undefined, { headers: { "Content-Type": "multipart/form-data" } })
            if (_res.status === 200 && !_res.data.status) {
                Swal.fire({
                    icon: 'error',
                    text: 'Session Expire กรุณาเข้าระบบใหม่',
                    backdrop: true,
                    allowEscapeKey: true,
                    allowOutsideClick: () => {
                        setTimeout(() => {
                            sessionStorage.clear()
                            setIsLogin(false)
                        }, 800)
                    }
                }).then(() => {
                    setTimeout(() => {
                        sessionStorage.clear()
                        setIsLogin(false)
                    }, 800)
                })
                clearInterval(_obj.interval)
                return
            }

        } catch (err) {
            clearInterval(_obj.interval)
            setIsLogin(false)
            ErrorHandle(err)
        }
    }, [_obj])

    const AutoLogin = async () => {

        if (sessionStorage.length > 0) return

        try {

            const _res = await http.post('/Login', { Username: 'junior', Password: 'Cinem@11' })
            if (_res.status === 200 && _res.data.status) {
                setIsLogin(true)
                for (const Key in Object.keys(_res.data.data)) {
                    sessionStorage.setItem(Object.keys(_res.data.data)[Key], Object.values(_res.data.data)[Key])
                }
            }

        } catch (err) { ErrorHandle(err) }
    }

    return (
        <React.Fragment>
            <React.Suspense fallback={<></>}>
                {
                    isLogin ?
                        <Layout>
                            <Routes>
                                <Route path='/' element={<Index isLogin={isLogin} />} />
                                <Route path="/Content" element={<AllContent />} />
                                <Route path="/Learn/:courseStudyID/:Type" element={<Learn />} />
                                <Route path="/News" element={<News />} />
                                <Route path="/PreTestExamination/:courseStudySubID" element={<PreTestExamination />} />
                                <Route path="/PostTestExamination/:courseStudySubID" element={<PostTestExamination />} />
                                <Route path="/History" element={<History title="ความสำเร็จของฉัน" />} />
                                <Route path="/CourseProgress/:courseStudyID/:Type" element={<CourseProgress />} />
                                <Route path="/Accomplishments" element={<Accomplishments />} />
                                <Route path="/ProfileUser" element={<ProfileUser />} />
                                <Route path="/NewsDetail/:NewsID" element={<NewsDetail />} />
                                <Route path="/CourseStudyContinue" element={<CourseStudyContinue />} />
                                <Route path="/CourseStudyReview" element={<CourseStudyReview />} />
                                <Route path='/404' element={<NotFound />} />
                                <Route path='*' element={<NotFound />} />
                            </Routes>
                        </Layout>
                        :
                        <Routes>
                            <Route path='*' element={<Login isLogin={isLogin} setIsLogin={setIsLogin} />} />
                        </Routes>
                }
            </React.Suspense>
        </React.Fragment>
    )
}

export default React.memo(App)