import React from 'react'
import DataTable from 'react-data-table-component'
import { LinearProgress, makeStyles } from '@material-ui/core'
import { ArrowDownward } from '@material-ui/icons'
import { Button, Input } from '@material-ui/core'

const TableBase = (props) => {

    const _SortIcon = <ArrowDownward />
    const _paginationRowsPerPageOptions = [10, 50, 100, 1000]
    const _paginationComponentOptions = {
        rowsPerPageText: <div style={{ fontSize: '1rem' }}>แสดง</div>,
        rangeSeparatorText: "ถึง",
        selectAllRowsItem: true,
        selectAllRowsItemText: "ทั้งหมด",
    }

    const [_FilterText, setFilterText] = React.useState('')
    const [_ResetFilterText, setResetFilterText] = React.useState(false)

    const useStyles = makeStyles(theme => ({
        root: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
    }))

    const LinearIndeterminate = () => {

        const classes = useStyles()
        return (
            <div className={classes.root}>
                <LinearProgress />
            </div>
        )
    }

    const subHeaderComponent = React.useMemo(() => {

        const handleFilterClear = () => {
            setResetFilterText(!_ResetFilterText)
            setFilterText('')
        }

        return <Filter onClear={handleFilterClear} filterText={_FilterText} onFilter={setFilterText} />

    }, [_FilterText, _ResetFilterText])

    const _FilteredItems = props.data.filter((x) => JSON.stringify(x).toLowerCase().indexOf(_FilterText.toLowerCase()) > -1)

    return (
        <div>
            <DataTable
                {...props}
                defaultSortAsc={false}
                sortIcon={_SortIcon}
                pagination
                paginationRowsPerPageOptions={_paginationRowsPerPageOptions}
                paginationComponentOptions={_paginationComponentOptions}
                pointerOnHover
                highlightOnHover
                progressComponent={<LinearIndeterminate />}
                fixedHeader
                responsive
            />
        </div>
    )
}

const Filter = ({ filterText, onFilter, onClear }) => {
    return (
        <>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <Input
                    className="text-sm"
                    value={filterText}
                    onChange={(e) => onFilter(e.target.value)}
                    placeholder="ค้นหา"
                />
            </span>
            {
                filterText &&
                <Button onClick={onClear} variant={"contained"} color={"secondary"} title={"เครียร์"}> <i className={"fas fa-times-circle"} /></Button>
            }
        </>
    )
}

export default React.memo(TableBase)