import React from "react";
import Banner from "../Banner";
import ContentNewsCourse from '../Content';

const Content = ({ deviceType, isLogin }) => {
    return (
        <React.Fragment>
            <Banner deviceType={deviceType} isLogin={isLogin} />
            <ContentNewsCourse isLogin={isLogin} />
        </React.Fragment>
    )
}
export default React.memo(Content);