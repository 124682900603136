import React from 'react'
import { Row } from 'react-bootstrap'
import { Document, Page, pdfjs } from 'react-pdf'
import { Button } from 'primereact/button'
import { isEmpty } from '../../Utils'

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: '../../../public/fonts/SMARNFILM-Black.otf',
}

const StylePDF = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PDFView = (props) => {

    const { File, setModal } = props

    const [numPages, setNumPages] = React.useState(1)
    const [pageNumber, setPageNumber] = React.useState(1)
    const [Files, setFiles] = React.useState(null)

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages)
    }

    const handleNextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1)
        }
    }

    const handlePrevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1)
        }
    }

    React.useEffect(() => {

        if (isEmpty(File)) return
        setFiles(File)

    }, [setModal])

    return (
        <React.Fragment>
            <div className="flex flex-column w-full">
                {
                    !isEmpty(Files) &&
                    <Row>
                        <div className="flex align-items-center justify-content-center m-2 pdf-container">
                            <Document
                                // file={watch('CourseStudySubFileUpload.fileurl')}
                                file={Files}
                                onLoadSuccess={onDocumentLoadSuccess}
                                onContextMenu={(e) => e.preventDefault()}
                                options={options}
                                StylePDF={StylePDF}
                            >
                                <div className="mt-2">
                                    <Page pageNumber={pageNumber} />
                                </div>
                            </Document>
                        </div>
                    </Row>
                }
                <Row>
                    <div className="flex align-items-center justify-content-center m-2">
                        <p>หน้า {pageNumber} จาก {numPages}</p>
                    </div>
                </Row>
                <Row>
                    <div className="flex align-items-center justify-content-center m-2">
                        <Button
                            label="ก่อนหน้า"
                            className="p-button-raised p-button-danger p-button-sm mt-3 mr-2"
                            onClick={handlePrevPage}
                            disabled={pageNumber === 1}
                        />
                        {(pageNumber < numPages)}
                        <Button
                            label="ถัดไป"
                            className="p-button-raised p-button-warning p-button-sm mt-3 mr-2"
                            onClick={handleNextPage}
                            disabled={pageNumber >= numPages}
                        />
                    </div>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default React.memo(PDFView)